import React from "react";
import styles from "./_css/homepageStoreSection.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { URLS } from "_configs/URLS";
import boutique_noaio from "./assets/boutique_noaio.jpeg";

const HomepageStoreSection = () => {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "page-container")}>
            <div className={styles.imageContainer}>
                <img src={boutique_noaio} />
            </div>
            <div className={clsx(styles.innerContainer, "flex_center_center flex_column")}>
                <h2>{t("store.title")}</h2>
                <p>{t("store.description")}</p>
                <a href={"https://goo.gl/maps/EKZByQjHJPjtsLNC6"} target={"_blank"} rel={"noreferrer"}>
                    {t("store.address")}
                </a>
                <div>
                    <p className={"fontWeight_bold"}>{t("store.schedule.title")}</p>
                    <p className={"mt_10"}>{t("store.schedule.description")}</p>
                </div>
                <div>
                    <p className={"fontWeight_bold"}>{t("initiation.homepage.text")}</p>
                    <UiButton variant={"contained"} color={"primary"} className={"mt_20"} href={URLS.initiation()}>
                        {t("initiation.homepage.cta")}
                    </UiButton>
                </div>
            </div>
        </div>
    );
};

export default HomepageStoreSection;
