import React from "react";
import { observer } from "mobx-react";
import { useProductStore } from "products/ProductContext";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";
import { productsStore } from "products/_stores/productsStore";

export const ProductPreorder = observer(() => {
    const productStore = useProductStore();
    const { t } = useTranslation();
    if (!productStore.selectedVariant?.isPreorder) return null;
    return (
        <div className="mt_10 text_discreet">
            *
            {t("product.preorderText", {
                price: formatPrice(productsStore.getPreorderPrice(productStore.selectedVariant.price)),
            })}
        </div>
    );
});
