import { useEffect } from "react";
import { useLocation } from "react-router";
import { useScrollPosition } from "_common/_utils/hookUtils";
import { PAGE_REF } from "pages/PageRegular";

export const scrollTop = (scrollPosition?: number) => {
    if (PAGE_REF.current && scrollPosition && scrollPosition > 0) {
        PAGE_REF.current.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
};

export function ScrollTop() {
    const location = useLocation();
    const scrollPosition = useScrollPosition(PAGE_REF);
    useEffect(() => {
        scrollTop(scrollPosition);
    }, [location.pathname]);
    return null;
}
