import React from "react";
import styles from "components/pages/slider/_css/genericSlider.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { SingleSlide } from "components/pages/slider/SingleSlide";
import { TSlideShowMdl } from "slideShows/_models/SlideShowMdl";
import { observer } from "mobx-react";

type Props = {
    slider: TSlideShowMdl;
    navigation: boolean;
    loop: boolean;
    autoplay?: boolean;
};

SwiperCore.use([Autoplay]);

export const Slider = observer((props: Props) => {
    const hasOneSlide = props.slider.slides.length === 1;

    return (
        <div className={styles.container} id={hasOneSlide ? "hasOneSlide" : ""}>
            <Swiper
                navigation={props.navigation}
                loop={props.loop}
                autoplay={props.autoplay ? { delay: 5000, disableOnInteraction: false } : false}
            >
                {props.slider.slides.map((slide, idx) => (
                    <SwiperSlide key={idx}>
                        <SingleSlide slide={slide} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
});
