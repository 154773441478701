import React from "react";
import styles from "./_css/menu.module.css";
import clsx from "clsx";
import { MobileMenuBtn } from "main/menu/mobile/MobileMenuBtn";
import { HeaderLogo } from "main/menu/HeaderLogo";
import { SearchBar } from "main/menu/search/SearchBar";
import { NavLink } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import { cartStore } from "cart/_stores/cartStore";
import { observer } from "mobx-react";
import { NoSsr } from "@material-ui/core";
import { useScrollPosition } from "_common/_utils/hookUtils";
import { PAGE_REF } from "pages/PageRegular";
import i18next from "i18next";
import { MenuUser } from "main/menu/MenuUser";
import { LangSelector } from "main/menu/LangSelector";
import MobileMenuSearchButton from "main/menu/mobile/MobileMenuSearchButton";

export const Menu = observer(() => {
    const { t } = useTranslation();
    const scrollPosition = useScrollPosition(PAGE_REF);
    return (
        <>
            <div className={clsx(styles.mobileContainer, "flex_row_center flex-1")}>
                <MobileMenuBtn />
                <div className="mt_5">
                    <HeaderLogo />
                </div>
                <div className={clsx("flex_row justifyContent_spaceBetween mr_40 ml_20", styles.subContainer)}>
                    <NavLink to={`/${i18next.language}/${t("routes.electricVehicle")}`}>
                        <div className={clsx(styles.iconCartMobile, "flex_center_center")}>
                            <i className="icon-shopping-bag1 mr_5" />{" "}
                            <div className={clsx(styles.menuTextItem, "mt_3")}>
                                {t("routes.shop")[0].toUpperCase()}
                                {t("routes.shop").slice(1)}
                            </div>
                        </div>
                    </NavLink>
                    <div className={styles.menuIcons}>
                        <MobileMenuSearchButton />
                        <NavLink to={URLS.cart()}>
                            <div
                                style={{ marginRight: scrollPosition > 40 ? 24 : 14 }}
                                className={clsx(styles.iconCartMobile, "flex_center_center")}
                            >
                                <i className="icon-cart" />
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <nav className={clsx(styles.container, "flex_row_center flex-1")}>
                <div className="mt_5 mr_20">
                    <HeaderLogo />
                </div>
                <SearchBar />
                <div className={styles.partRightMenu}>
                    <MenuUser />
                    <LangSelector />
                    <NoSsr>
                        <div className={"ml_10"}>
                            <NavLink to={URLS.cart()}>
                                <div className={clsx(styles.iconCart, "flex_row_center")}>
                                    <i className="icon-cart mr_10" /> {t("routes.cart")[0].toUpperCase()}
                                    {t("routes.cart").slice(1)}{" "}
                                    {!cartStore.isEmpty && `(${cartStore.cart.lines.length})`}
                                </div>
                            </NavLink>
                        </div>
                    </NoSsr>
                </div>
            </nav>
        </>
    );
});
