import React from "react";
import styles from "./_css/homepageBenefits.module.css";
import HomepageBenefitCard from "homepage/HomepageBenefitCard";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const BENEFITS = {
    delivery: {
        icon: "local_shipping",
    },
    warranty: {
        icon: "verified_user",
    },
    payment: {
        icon: "payment",
    },
    price: {
        icon: "reply",
    },
    initiations: {
        icon: "accessibility",
    },
    repair: {
        icon: "plumbing",
    },
    spareParts: {
        icon: "extension",
    },
    community: {
        icon: "people",
    },
};
const HomepageBenefits = () => {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex_column flex_center_center")}>
            <h2>{t("homepage.benefits.title")}</h2>
            <Grid container spacing={2}>
                {Object.entries(BENEFITS).map(([key, benefit]) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                        <HomepageBenefitCard
                            icon={benefit.icon}
                            titleKey={`homepage.benefits.${key}.title`}
                            descriptionKey={`homepage.benefits.${key}.description`}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default HomepageBenefits;
