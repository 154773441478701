import React from "react";
import { searchStore } from "main/menu/search/_stores/searchStore";
import { useQuery } from "_common/_utils/hookUtils";
import { NotFound } from "pages/NotFound";
import { ProductFound } from "main/menu/search/ProductFound";
import clsx from "clsx";
import styles from "./_css/searchPage.module.css";
import { SearchHeader } from "main/menu/search/SearchHeader";
import { useTranslation } from "react-i18next";
import { ArticleFound } from "main/menu/search/ArticleFound";

const SearchPage = () => {
    const { t } = useTranslation();
    const query = useQuery();
    const search = query.get("search");
    const results = searchStore.searchResults(search || "");
    if (!search) {
        return (
            <div>
                <div id={"SSR_page404NotFound"} />
                <NotFound />
            </div>
        );
    }

    return (
        <div className={clsx(styles.container, "page-container-small flex_center_center flex_column mb_40")}>
            <h1>{t("words.resultsFor", { query: search })}</h1>
            {results.products.length > 0 && (
                <>
                    <SearchHeader label={t("words.products")} />
                    {results?.products.map((product) => {
                        return <ProductFound product={product} key={product._id} />;
                    })}
                </>
            )}
            {results.articles.length > 0 && (
                <>
                    <SearchHeader label={t("words.pages")} />
                    {results?.articles.map((article) => {
                        return <ArticleFound article={article} key={article._id} />;
                    })}
                </>
            )}
        </div>
    );
};

export default SearchPage;
