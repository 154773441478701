import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useProductStore } from "products/ProductContext";
import { cartStore } from "cart/_stores/cartStore";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styles from "./_css/cartAddBtn.module.css";
import clsx from "clsx";
import { CartAddToast } from "cart/CartAddToast";
import { productsStore } from "products/_stores/productsStore";
import i18next from "i18next";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { panelStore } from "main/menu/panel/_stores/panelStore";
import CartAddPanelContent from "cart/CartAddPanelContent";

export function CartAddBtn() {
    const productStore = useProductStore();
    const { t } = useTranslation();
    if (!productStore.selectedVariant) return null;
    return (
        <UiButton
            onClick={() => {
                const variant = productStore.selectedVariant;
                if (variant) {
                    cartStore.addVariantItem(variant);
                    if (!variant._id) return null;
                    const { product } = productsStore.getProductByVariantId(variant._id);
                    if (!product) return null;
                    panelStore.open({
                        opened: true,
                        children: <CartAddPanelContent product={product} />,
                        size: "medium",
                    });

                    analyticsUtils.trackEcommerceEventDeferred(
                        {
                            name: "addProduct",
                        },
                        {
                            id: variant.localized[i18next.language].name,
                            name: product.localized.name,
                            category: product.type,
                            price: variant.price / 100,
                            quantity: 1,
                        },
                        window.location.pathname,
                    );

                    if (productStore.warranty) {
                        cartStore.addItem({
                            text: t("product.2yearsWarranty") + " " + product.localized.name,
                            quantity: 1,
                            price: productsStore.getWarrantyPrice(variant.price),
                        });

                        analyticsUtils.trackEcommerceEventDeferred(
                            {
                                name: "addProduct",
                            },
                            {
                                id: "warranty",
                                name: t("product.2yearsWarranty") + " " + product.localized.name,
                                category: "warranty",
                                price: productsStore.getWarrantyPrice(variant.price) / 100,
                                quantity: 1,
                            },
                            window.location.pathname,
                        );
                    }
                }

                productStore.linkedVariants.forEach((variant) => {
                    if (variant) {
                        cartStore.addVariantItem(variant);
                        if (!variant._id) return null;
                        const { product } = productsStore.getProductByVariantId(variant._id);
                        if (!product) return null;

                        analyticsUtils.trackEcommerceEventDeferred(
                            {
                                name: "addProduct",
                            },
                            {
                                id: variant.localized[i18next.language].name,
                                name: product.localized.name,
                                category: product.type,
                                price: variant.price / 100,
                                quantity: 1,
                            },
                            window.location.pathname,
                        );

                        toast.info(
                            <CartAddToast
                                text={product.localized.name + " " + variant.localized[i18next.language].name}
                            />,
                            {
                                type: "default",
                                hideProgressBar: true,
                                position: "top-center",
                                pauseOnFocusLoss: false,
                            },
                        );
                    }
                });

                analyticsUtils.trackEcommerceEventDeferred(
                    {
                        name: "setAction",
                        type: "checkout",
                    },
                    {
                        step: 1,
                    },
                    window.location.pathname,
                    true,
                );
            }}
            variant={"contained"}
            color={"primary"}
            size={"large"}
        >
            <i className={clsx(styles.icon, "icon-shopping-cart mr_10")} /> {t("cart.addToCart")}
        </UiButton>
    );
}
