import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TAffiliationMdl } from "admin/affiliations/_model/AffiliationMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";

class AffiliationsStore extends BaseResourceStore<TAffiliationMdl> {
    constructor() {
        super("affiliations");
    }

    checkAffiliate = async (affiliateName: string) => {
        const url = `${this.apiPath}/checkAffiliate/${affiliateName}`;
        return fetchUtils.get(url).then(({ data }) => data);
    };
}
export const affiliationsStore = new AffiliationsStore();
