import _ from "lodash";

export function formatPrice(price: number, alreadyDivided = false, displayAfterComma = true) {
    const _price = alreadyDivided ? price : parseInt(price.toString()) / 100;
    const thousands = Math.floor(_price / 1000);
    const left: number = _price % 1000;
    let _left: string;
    if (thousands) {
        if (left === 0) _left = "000";
        else if (left < 10) _left = "00" + (displayAfterComma ? left.toFixed(2) : left.toString());
        else if (left < 100) _left = "0" + (displayAfterComma ? left.toFixed(2) : left.toString());
        else _left = displayAfterComma ? left.toFixed(2) : left.toString();
    } else _left = displayAfterComma ? left.toFixed(2) : left.toString();
    return (thousands ? thousands + " " : "") + _left + " CA$";
}

export function isNumber(value?: any, min?: number, max?: number) {
    return (
        _.isNumber(value) &&
        !_.isNaN(value) &&
        (min === undefined || value >= min) &&
        (max === undefined || value <= max)
    );
}
