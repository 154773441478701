import React from "react";
import { userStore } from "users/_stores/userStore";
import { Card, CardContent, Grid, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TUserForOrderMdl } from "users/_models/UserMdl";

type Props = {
    user?: TUserForOrderMdl;
};

export function UserInfoSummary(props: Props) {
    const { t } = useTranslation();
    if (!props.user && !userStore.isLogged) return null;
    const user = props.user ?? userStore.user;
    return (
        <Card elevation={0}>
            <CardContent>
                <Grid container spacing={1}>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={4}>
                            <span className="fontWeight_bold">{t("words.name")}</span>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8}>
                        {user?.firstName} {user?.lastName}
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={4}>
                            <span className="fontWeight_bold">{t("words.phone")}</span>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8}>
                        {user?.phone}
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={4}>
                            <span className="fontWeight_bold">{t("words.email")}</span>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8}>
                        {user?.email}
                    </Grid>
                    {user?.address && user?.address?.city && user?.address?.postalCode && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <span className="fontWeight_bold">{t("user.invoiceAddress")}</span>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {user.address?.street} {user.address.extra ?? user.address.extra} <br />
                                {user.address?.city}, {user?.address?.postalCode}, <br />
                                {user.address?.province}, {user?.address?.country}
                            </Grid>
                        </>
                    )}
                    {user?.useShippingAddress && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <span className="fontWeight_bold">{t("user.shippingAddress")}</span>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                {user?.shippingAddress?.street}
                                {user?.shippingAddress?.extra && ", " + user?.shippingAddress?.extra} <br />
                                {user?.shippingAddress?.city}, {user?.shippingAddress?.postalCode}, <br />
                                {user?.shippingAddress?.province}, {user?.shippingAddress?.country}
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
}
