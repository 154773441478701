import { TFilter } from "admin/_common/filters/TFilter";
import { TDiscount, TVariantBaseMdl, TVariantMdl, VARIANT_STATUS } from "products/_models/VariantMdl";
import dayjs from "dayjs";
import { PRODUCT_TYPE, SORTABLE_TYPES, TProductLocalizedMdl } from "products/_models/ProductMdl";
import { searchStore } from "main/menu/search/_stores/searchStore";
import { productsStore } from "products/_stores/productsStore";

export function filtersByProductOption(filters: TFilter[]) {
    const filtersByOption: { [key: string]: TFilter[] } = {};

    filters.forEach((filter) => {
        if (!filtersByOption[filter.id]) filtersByOption[filter.id] = [];
        filtersByOption[filter.id].push(filter);
    });
    return filtersByOption;
}

export function isActiveDiscountVariant(discount: TDiscount | undefined) {
    if (!discount) return false;
    return (
        discount &&
        discount.status === VARIANT_STATUS.ACTIVE &&
        dayjs(discount.startDate).isBefore(dayjs()) &&
        dayjs(discount.endDate).isAfter(dayjs())
    );
}

export function finalePrice(price: number | undefined, discount: TDiscount, alreadyDivided = true) {
    if (!price) return 0;
    if (discount.kind === "$") {
        return price - (discount.value ?? 0);
    } else {
        return price * (1 - (discount.value ?? 0) / (!alreadyDivided ? 100 : 10000));
    }
}

export function computeFinalePrice(variant: TVariantMdl | TVariantBaseMdl, alreadyDivided = true): typeof variant {
    const updatedVariant: typeof variant = JSON.parse(JSON.stringify(variant));
    updatedVariant.finalePrice = variant.price;
    if (updatedVariant.discount && isActiveDiscountVariant(updatedVariant.discount)) {
        updatedVariant.finalePrice = finalePrice(variant.price, updatedVariant.discount, alreadyDivided);
    }
    return updatedVariant;
}

export const getMinPrice = (product: TProductLocalizedMdl) => {
    if (!product.variants) return undefined;
    let minPrice = Number.MAX_SAFE_INTEGER;
    product.variants?.map((variant) => {
        if (variant.finalePrice < minPrice) minPrice = variant.finalePrice;
    });
    return minPrice === Number.MAX_SAFE_INTEGER ? undefined : minPrice;
};

export function getAverageMark(product: TProductLocalizedMdl) {
    if (!product.reviews || product.reviews.length === 0) return undefined;
    const total = product.reviews?.reduce((sum, review) => {
        return sum + review.mark;
    }, 0);
    return Math.round((total * 100) / product.reviews.length) / 100;
}

export function isSortableType(type?: PRODUCT_TYPE) {
    if (!type) return false;
    return SORTABLE_TYPES.includes(type);
}

export const getTopProducts = (type: PRODUCT_TYPE, numberOfProducts = 3) => {
    const { products } = searchStore;
    if (!products) return [];
    return products.filter((product) => product.type === type).slice(0, numberOfProducts);
};

export const fillRelatedProductsIds = (product: TProductLocalizedMdl, nbOfRelatedProducts: number) => {
    if (product.linkedProducts && product.linkedProducts.length >= nbOfRelatedProducts) {
        return product.linkedProducts.slice(0, nbOfRelatedProducts);
    }
    const productsIds = productsStore
        .getListStore(product.type, nbOfRelatedProducts + 1)
        .items.filter((_product) => _product?._id !== product._id)
        .map((p) => p?._id) as string[];
    if (!product.linkedProducts) return productsIds ? productsIds.slice(0, nbOfRelatedProducts) : [];
    return product.linkedProducts?.concat(productsIds ?? []).slice(0, nbOfRelatedProducts);
};
