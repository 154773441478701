import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Crisp } from "crisp-sdk-web";

const useCrisp = () => {
    const { t } = useTranslation();
    const sendHelpMessage = useCallback(() => {
        const alreadySent = document.cookie.includes("crispPopup=true");
        if (!alreadySent) {
            Crisp.message.show("text", t("chat.helpMessage"));
            document.cookie = "crispPopup=true";
        }
    }, []);

    return { sendHelpMessage };
};

export default useCrisp;
