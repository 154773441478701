import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { PRODUCT_TYPE, TProductLocalizedMdl } from "products/_models/ProductMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { fetchUtils } from "_common/_utils/fetchUtils";
import {
    putPromiseListResultInInitialState,
    putPromiseResourceResultInInitialState,
} from "_common/_utils/initialStateUtils";
import { ListStore } from "_common/list/ListStore";
import { URLS } from "_configs/URLS";
import { UseTranslationResponse } from "react-i18next";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { ProductTypeListStoreProvider } from "products/_stores/ProductTypeListStoreProvider";

export class ProductsStore extends BaseResourceStore<TProductLocalizedMdl> {
    constructor() {
        super("products");
    }

    list(offset = 0, limit?: number, listId?: string, sort?: { [p: string]: number }, filters?: TFilter[]) {
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        let filtersParam = "";
        if (filters) filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/listing?offset=${offset}&limit=${limit}${sortParam}${filtersParam}`;
        const promise = fetchUtils
            .get<{ count: number; items: TProductLocalizedMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map((item) => {
                    const reformattedItem = this.reformatItem(item);
                    this.putItemInCache(reformattedItem);
                    return reformattedItem;
                }),
            }));
        if (listId) {
            putPromiseListResultInInitialState(this.name, listId, offset, promise);
        } else {
            putPromiseResourceResultInInitialState(
                this.name,
                promise.then(({ items }) => items),
            );
        }
        return promise;
    }

    getListStore(listId = "default", pageSize = Number.MAX_SAFE_INTEGER, initialFilters?: TFilter[]) {
        if (!this.listsStores[listId]) {
            this.listsStores[listId] = new ListStore(
                listId,
                Object.keys(PRODUCT_TYPE).includes(listId as PRODUCT_TYPE)
                    ? new ProductTypeListStoreProvider(listId as PRODUCT_TYPE)
                    : this,
                undefined,
                pageSize,
                undefined,
                initialFilters,
            );
        }
        return this.listsStores[listId];
    }

    getByAliasUrl(urlAlias: string) {
        return Object.values(this.cache).find((product) => product?.localized.urlAlias === urlAlias);
    }

    getByLabelName(labelName: string) {
        const products = Object.values(this.cache);
        const filteredProducts = products.filter((product) => {
            if (!product?.labels || product.labels.length === 0) return false;
            let isSelected = false;
            product.labels.map((label) => {
                if (label.localized.wordUrl === labelName) {
                    isSelected = true;
                }
            });
            return isSelected;
        });
        return filteredProducts;
    }

    getProductByVariantId(variantId?: string) {
        const noResult = { product: undefined, variant: undefined };
        if (!variantId) return noResult;
        for (const productType of Object.values(PRODUCT_TYPE)) {
            for (const product of this.listsStores[productType].items) {
                if (!product?.variants) break;
                for (const variant of product?.variants) {
                    if (variant._id === variantId) return { product, variant };
                }
            }
        }
        return noResult;
    }

    getBreadcrumbItemsFromProduct(product: TProductLocalizedMdl, translation: UseTranslationResponse) {
        const items = [
            {
                itemLabelKey: "product.types." + product.type,
                url: URLS.shop(translation.t("routes.productTypes." + product.type)),
            },
        ];
        if (product.type === "EUC" || product.type === "SCOOTER") {
            items.unshift({
                itemLabelKey: "shop.electricVehicles",
                url: URLS.home() + "/" + translation.t("routes.electricVehicle"),
            });
        }
        return items;
    }

    getByUrlSync(path: string, lang: TLang) {
        const productPath = path.substr(
            (sharedConfig.languages[lang].basePath + "/" + i18nextInstance.t("routes.product")).length + 1,
        );
        return this.findOneSync(`localized.urlAlias`, productPath);
    }

    getWarrantyPrice(price: number) {
        if (price) {
            if (price < 150000) return 15000;
            else if (price < 200000) return 20000;
            else if (price < 250000) return 25000;
            else if (price < 300000) return 30000;
            else if (price < 350000) return 35000;
            else if (price < 500000) return 40000;
        }
        return 0;
    }

    getPreorderPrice(price: number) {
        return price > 200000 ? 75000 : 0.3 * price;
    }

    protected onInit(fromRootCtor?: boolean) {
        super.onInit(fromRootCtor);
        const products = Object.values(this.cache) as TProductLocalizedMdl[];
        if (products.length === 0) return;
        for (const productType of Object.values(PRODUCT_TYPE)) {
            const productsByType = products.filter((product) => product?.type === productType);
            this.listsStores[productType] = new ListStore<TProductLocalizedMdl>(
                productType,
                this,
                {
                    count: productsByType.length,
                    pages: [productsByType],
                },
                Number.MAX_SAFE_INTEGER,
            );
        }
    }

    protected reformatItem(item: TProductLocalizedMdl) {
        const reformatedItem = super.reformatItem(item);
        return { ...reformatedItem, minPrice: reformatedItem.minPrice / 100 };
    }
}

const productsStore = new ProductsStore();
export { productsStore };
