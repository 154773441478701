import React from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { useTranslation } from "react-i18next";
import { TextArea } from "_common/ui/forms/TextArea";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";

export function CommentInModalForm() {
    const form = useFormContext();
    const { t } = useTranslation();

    return (
        <div>
            <div className="flex_row pv_10">
                <InputBlock label={t("comment.title")} required>
                    <Controller
                        name={"title"}
                        as={Input}
                        control={form.control}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                    />
                </InputBlock>
            </div>
            <div className="pv_10">
                <InputBlock label={t("comment.comment")}>
                    <Controller name={"description"} as={TextArea} control={form.control} />
                </InputBlock>
            </div>
        </div>
    );
}
