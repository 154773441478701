import React from "react";
import { Slider } from "@material-ui/core";
import { cartStore } from "cart/_stores/cartStore";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "cart/_css/cartSlidebar.module.css";
import { formatPrice } from "_common/_utils/currencyUtils";
import { DELIVERY_COSTS_CONFIG } from "_configs/deliveryCostsConfig";

export function CartSlidebar() {
    const { t } = useTranslation();
    const totalCart = cartStore.subtotal / 100;
    const maxPrice = DELIVERY_COSTS_CONFIG.limit / 100;
    const minPrice = 0;

    return (
        <div className={clsx(styles.containerShipping, "p_20 flex-1")}>
            <div className="textAlign_center text_discreet">
                {t("cart.slideTotal", { count: +(maxPrice - totalCart).toFixed(2) })}
            </div>
            <div className={"flex_row_center mt_5"}>
                <div>{formatPrice(minPrice, true, false)}</div>
                <div className={"flex-1 mt_5 ph_10"}>
                    <Slider max={maxPrice} value={totalCart} aria-labelledby="enabled-slider" />
                </div>
                <div>{formatPrice(maxPrice, true, false)}</div>
            </div>
        </div>
    );
}
