import React from "react";
import styles from "./_css/phoneLogo.module.css";
import clsx from "clsx";

type Props = {
    phone: string;
};

export function PhoneLogo(props: Props) {
    return (
        <a className={clsx("flex_center_center", styles.container)} href={"tel:" + props.phone}>
            <div className={clsx("flex_center_center", styles.logo)}>
                <i className={clsx("icon-phone1", styles.icon)} />
            </div>
        </a>
    );
}
