import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TProductEditionMdl } from "products/_models/ProductMdl";
import { s4 } from "_common/_utils/coreUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { TVariantMdl, VARIANT_STATUS } from "products/_models/VariantMdl";
import { action } from "mobx";
import { fetchUtils } from "_common/_utils/fetchUtils";

export class ProductsAdminStore extends BaseResourceStore<TProductEditionMdl> {
    constructor() {
        super("products");
    }

    getProductByVariantId(variantId: string) {
        if (!variantId) return undefined;
        for (const product of Object.values(this.cache)) {
            if (!product) continue;
            const variant = product?.variants?.find((variant) => variant._id === variantId);
            if (variant) return product;
        }
        return undefined;
    }

    getVariantByVariantId(variantId: string) {
        if (!variantId) return;
        for (const product of Object.values(this.cache)) {
            if (!product) continue;
            const variant = product?.variants?.find((variant) => variant._id === variantId);
            if (variant) return variant;
        }
        return undefined;
    }

    @action
    swapProducts(productId: string, direction: "up" | "down") {
        return fetchUtils
            .post<{ productUpdated: TProductEditionMdl; targetProductUpdated: TProductEditionMdl }>(
                `${this.apiPath}/${productId}/swap`,
                { direction },
            )
            .then(({ data }) => {
                const listStore = this.getListStore("admin");
                const product = listStore.items.find((item) => item?._id === productId);
                const targetProduct = listStore.items.find((item) => item?._id === data.targetProductUpdated._id);
                const productPosition = product?.position;
                const targetProductPosition = targetProduct?.position;
                if (product && targetProduct) {
                    listStore.swapItems(product._id, targetProduct._id);
                    listStore.onUpdate({ ...product, position: targetProductPosition });
                    listStore.onUpdate({ ...targetProduct, position: productPosition });
                }
            });
    }

    protected reformatItem(item: TProductEditionMdl) {
        const reformatedItem = super.reformatItem(item);
        return {
            ...reformatedItem,
            variants: reformatedItem.variants?.map((variant) => ({
                ...variant,
                price: variant.price / 100,
                discount: variant.discount
                    ? { ...variant.discount, value: variant.discount ? variant.discount.value / 100 : 0 }
                    : undefined,
            })),
        };
    }

    protected duplicateItem(item: Omit<TProductEditionMdl, "_id">) {
        const localized = JSON.parse(JSON.stringify(item.localized));
        const variants = JSON.parse(JSON.stringify(item.variants));
        Object.keys(sharedConfig.languages).forEach(
            (lang) => (localized[lang as TLang].urlAlias = localized[lang as TLang].urlAlias + s4()),
        );
        variants.forEach((variant: TVariantMdl) => {
            if (!variant.status) variant.status = VARIANT_STATUS.INACTIVE;
            variant.price = variant.price * 100;
            variant.discount = undefined;
            variant._id = undefined;
        });
        return this.create({ ...item, localized, variants, reviews: [] });
    }
}

const productsAdminStore = new ProductsAdminStore();
export { productsAdminStore };
