import React from "react";
import styles from "./_css/panel.module.css";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { panelStore, TPanelOption } from "main/menu/panel/_stores/panelStore";

type Props = {
    option?: TPanelOption;
};

export function Panel(props: Props) {
    return (
        <>
            {props.option?.opened && <div className={styles.overlay} onClick={() => panelStore.close()} />}
            <div
                {...props}
                className={clsx(styles.container, {
                    [styles.opened]: props.option?.opened,
                    [styles.containerSmall]: props.option?.size === "small",
                    [styles.containerMedium]: props.option?.size === "medium",
                    [styles.containerLarge]: props.option?.size === "large",
                })}
            >
                <IconButton
                    size={"small"}
                    className={styles.closeIcon}
                    onClick={() => panelStore.close()}
                    name="Close button"
                    aria-label="Close button"
                >
                    <CloseIcon style={{ color: "#000" }} fontSize="large" name="Close button" />
                </IconButton>
                {props.option?.children}
            </div>
        </>
    );
}
