import React, { useState } from "react";
import dayjs from "dayjs";
import { useProductStore } from "products/ProductContext";
import { TReviewEditionMdl } from "products/_models/ProductMdl";
import { observer } from "mobx-react";
import { URLS } from "_configs/URLS";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { SignInModal } from "users/auth/blocks/SignInModal";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { ReviewInModal } from "products/reviews/ReviewInModal";
import { useWindowSize } from "_common/_utils/hookUtils";
import i18next from "i18next";

export const ProductReviewsAdd = observer(() => {
    const productStore = useProductStore();
    const [isOpenedUserModal, setIsOpenedUserModal] = useState(false);
    const [isOpenedReviewModal, setIsOpenedReviewModal] = useState(false);
    const windowSize = useWindowSize();
    const [review, setReview] = useState<TReviewEditionMdl>({
        user: { firstName: "", lastName: "" },
        mark: 5,
        title: "",
        comment: "",
        dateCreation: dayjs(),
        createdAt: dayjs(),
        updatedAt: dayjs(),
    });
    return (
        <div>
            <UiButton color="primary" onClick={() => setIsOpenedReviewModal(true)} variant={"outlined"}>
                {i18next.t("product.review.addReviewProductPage")}
            </UiButton>
            <Dialog
                fullScreen={windowSize.width < 750}
                closeCross={true}
                closeSize={"large"}
                open={isOpenedReviewModal}
                onClose={() => setIsOpenedReviewModal(false)}
            >
                <ReviewInModal
                    review={review}
                    setIsOpening={() => setIsOpenedReviewModal(true)}
                    setReview={(updatedReview) => setReview(updatedReview)}
                />
            </Dialog>
            <Dialog
                closeCross={true}
                closeSize={"large"}
                maxWidth={"md"}
                open={isOpenedUserModal}
                onClose={() => setIsOpenedUserModal(false)}
            >
                <SignInModal
                    backUrl={URLS.product(productStore.product.localized.urlAlias)}
                    onSuccess={(success) => setIsOpenedUserModal(!success)}
                />
            </Dialog>
        </div>
    );
});
