import React, { useContext } from "react";
import { ProductStore } from "./_stores/ProductStore";

const ProductCtxt = React.createContext<ProductStore>({} as ProductStore);

type Props = {
    productStore: ProductStore;
};

export function useProductStore() {
    return useContext(ProductCtxt);
}

export function ProductContext(props: React.PropsWithChildren<Props>) {
    return <ProductCtxt.Provider value={props.productStore}>{props.children}</ProductCtxt.Provider>;
}
