import React from "react";
import { Card, CardContent, CardHeader, Grid, NoSsr } from "@material-ui/core";
import { CartEmpty } from "cart/CartEmpty";
import { cartStore } from "cart/_stores/cartStore";
import { CartLines } from "cart/CartLines";
import { CartTotal } from "cart/CartTotal";
import { CartShippingEstimation } from "cart/CartShippingEstimation";
import { useTranslation } from "react-i18next";
import { CHECKOUT_STEP } from "cart/CartPage";

type Props = {
    onProcessing: () => void;
};

export function CartView(props: Props) {
    const { t } = useTranslation();

    return (
        <Card elevation={0} style={{ backgroundColor: "transparent" }}>
            <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("cart.title")} />
            <CardContent className="p_0 mt_10">
                <NoSsr fallback={<CartEmpty />}>
                    {cartStore.isEmpty ? (
                        <CartEmpty />
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={7}>
                                <CartLines />
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <CartTotal
                                    currentStep={CHECKOUT_STEP.CART}
                                    giftCardInput={true}
                                    onProcessing={props.onProcessing}
                                />
                            </Grid>
                        </Grid>
                    )}
                </NoSsr>
            </CardContent>
            <CardContent>
                <CartShippingEstimation />
            </CardContent>
        </Card>
    );
}
