import React, { useEffect, useState } from "react";
import { IconButton, MenuItem } from "@material-ui/core";
import { PRODUCT_SORTING, PRODUCT_TYPE, TProductLocalizedMdl } from "products/_models/ProductMdl";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { PRODUCT_FILTER } from "_configs/productConfig";
import { ListStore } from "_common/list/ListStore";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "../_css/sortingShop.module.css";
import { useLocation } from "react-router";
import { isSortableType } from "_common/_utils/productUtils";
import { upperFirst } from "lodash";

type Props = {
    listStore: ListStore<TProductLocalizedMdl>;
    productType?: PRODUCT_TYPE;
};

const SEO_FOR_PRODUCTS = [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER];
export function SortingShop(props: Props) {
    const DEFAULT_SORT = isSortableType(props.productType)
        ? { key: PRODUCT_FILTER.POSITION, value: PRODUCT_SORTING.DESC }
        : { key: PRODUCT_FILTER.TITLE, value: PRODUCT_SORTING.ASC };

    const [showText, setShowText] = useState(false);
    const location = useLocation();
    const brand = location.pathname.split("/")[4];
    const { t } = useTranslation();
    const [sort, setSort] = useState<typeof DEFAULT_SORT>(DEFAULT_SORT);

    function updateSort(updatedSort: { key: PRODUCT_FILTER; value: PRODUCT_SORTING }) {
        setSort(updatedSort);
        props.listStore.updateSort({ [updatedSort.key]: updatedSort.value });
    }

    function getKeyForSeoContent() {
        if (props.productType && brand) {
            return `shopPage.${props.productType}.${brand}`;
        } else if (props.productType) {
            return `shopPage.${props.productType}.generic`;
        } else {
            return `shopPage.generic`;
        }
    }

    useEffect(() => props.listStore.updateSort({ [sort.key]: sort.value }), []);
    const title = t("product.typesPlurial." + props.productType, { brand: upperFirst(brand) });
    const seoContentKey = getKeyForSeoContent();
    return (
        <>
            <div className={clsx("mv_10", styles.container)}>
                <h1 className={styles.title} onClick={() => setShowText(!showText)}>
                    {title.trim()}
                </h1>
                <div className={clsx("flex_row_center", styles.sort)}>
                    <div className={styles.label}>{t("words.sortingBy")}</div>
                    <div className="flex_row_center">
                        <UiSelect
                            defaultValue={DEFAULT_SORT.key}
                            onChange={(e) => {
                                updateSort({ ...sort, key: e.target.value as PRODUCT_FILTER });
                            }}
                        >
                            <MenuItem value={PRODUCT_FILTER.PRICE}>{t("words.price")}</MenuItem>
                            <MenuItem value={PRODUCT_FILTER.TITLE}>{t("product.options.alphabetic")}</MenuItem>
                            {isSortableType(props.productType) && (
                                <MenuItem value={PRODUCT_FILTER.POSITION}>{t("words.popularity")}</MenuItem>
                            )}
                        </UiSelect>
                        <IconButton
                            onClick={() => updateSort({ ...sort, value: PRODUCT_SORTING.ASC as number })}
                            disabled={sort.value === PRODUCT_SORTING.ASC}
                            name="Button ascendant"
                            aria-label="Button ascendant"
                        >
                            <i
                                className={clsx("icon-sort-amount-asc", styles.button, {
                                    [styles.disabled]: sort.value === PRODUCT_SORTING.ASC,
                                })}
                            />
                        </IconButton>
                        <IconButton
                            onClick={() => updateSort({ ...sort, value: PRODUCT_SORTING.DESC as number })}
                            disabled={sort.value === PRODUCT_SORTING.DESC}
                            name="Button descendant"
                            aria-label="Button descendant"
                        >
                            <i
                                className={clsx("icon-sort-amount-desc", styles.button, {
                                    [styles.disabled]: sort.value === PRODUCT_SORTING.DESC,
                                })}
                            />
                        </IconButton>
                    </div>
                </div>
            </div>
            {props.productType && SEO_FOR_PRODUCTS.includes(props.productType) && (
                <div style={{ height: (showText && title && !brand) || (brand && title && showText) ? "auto" : 0 }}>
                    <div
                        style={{ visibility: showText ? "visible" : "hidden" }}
                        dangerouslySetInnerHTML={{ __html: t(seoContentKey) }}
                    />
                </div>
            )}
        </>
    );
}
