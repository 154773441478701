import React from "react";
import { TReviewListingMdl } from "products/_models/ProductMdl";
import { UiMarkStars } from "_common/ui/UiMarkStars";
import i18next from "i18next";
import styles from "./_css/productReview.module.css";
import dayjs from "dayjs";
import { Grid } from "@material-ui/core";

type Props = {
    review: TReviewListingMdl;
};

export function ProductReview(props: Props) {
    return (
        <Grid item xs={12} className={styles.container}>
            <div className={styles.nameContainer}>
                <div className={styles.nameIcon}>{props.review.user.firstName?.charAt(0)}</div>
                <h3 className={styles.name}>
                    {props.review.user.firstName + " " + props.review.user.lastName?.substr(0, 1)}
                </h3>
            </div>
            <div className={styles.titleContainer}>
                <UiMarkStars mark={props.review.mark} />
                <h4 className={styles.title}>{props.review.title}</h4>
            </div>
            <div className={styles.date}>
                {i18next.t("words.publishedAt") +
                    " " +
                    dayjs(props.review.dateCreation).format(i18next.t("dates.formats.full"))}
            </div>
            <div className={styles.comment}>{props.review.comment}</div>
        </Grid>
    );
}
