import React from "react";
import { useProductStore } from "products/ProductContext";
import styles from "./_css/productSumUp.module.css";
import { observer } from "mobx-react";
import { formatPrice } from "_common/_utils/currencyUtils";
import clsx from "clsx";

export const ProductPrice = observer(() => {
    const productStore = useProductStore();
    if (!productStore.selectedVariant) return null;

    return (
        <div className="flex_row alignItems_center mt_30">
            <h3 className={styles.price}>{formatPrice(productStore.selectedVariant.finalePrice)}</h3>
            {productStore.isDiscount && (
                <h4 className={clsx(styles.regularPrice, "ml_10")}>
                    {formatPrice(productStore.selectedVariant.price)}
                </h4>
            )}
        </div>
    );
});
