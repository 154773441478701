import React from "react";
import styles from "./_css/mobileMenuSearch.module.css";
import { Input } from "_common/ui/forms/Input";
import { searchStore } from "main/menu/search/_stores/searchStore";
import clsx from "clsx";
import { SearchHeader } from "main/menu/search/SearchHeader";
import { ArticleFound } from "main/menu/search/ArticleFound";
import { ProductFound } from "main/menu/search/ProductFound";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Logo } from "_common/icons/Logo";

type Props = {
    opened: boolean;
    onClose: () => void;
};

const MobileMenuSearchModal = observer(({ opened, onClose }: Props) => {
    const { t } = useTranslation();
    if (!opened) return null;
    return (
        <div className={styles.modalContainer}>
            <div className={styles.cross}>
                <i className={"icon-cross"} onClick={() => onClose()} />
            </div>
            <div className={styles.logo}>
                <Logo width={100} />
            </div>
            <Input
                onChange={(e) => searchStore.setSearch(e.target.value)}
                onClick={() => searchStore.open(true)}
                className={styles.searchInput}
                placeholder={t("header.searchBarPlaceholder")}
                endIcon={<i className={clsx("icon-search1", styles.icon)} />}
            />
            {searchStore.results.products.length > 0 && (
                <div>
                    <SearchHeader label={t("words.products")} />
                    {searchStore.results.products.map((product) => (
                        <ProductFound product={product} key={product._id} onClick={onClose} />
                    ))}
                </div>
            )}
            {searchStore.results.articles.length > 0 && (
                <div>
                    <SearchHeader label={t("words.pages")} />
                    {searchStore.results.articles.map((article) => (
                        <ArticleFound article={article} key={article._id} onClick={onClose} />
                    ))}
                </div>
            )}
        </div>
    );
});

export default MobileMenuSearchModal;
