import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { CommentForm } from "comments/CommentForm";
import { TCommentMdl } from "comments/_models/CommentMdl";
import { Card, CardContent, Grid } from "@material-ui/core";
import { commentsStore } from "comments/_stores/commentsStore";
import { toast } from "react-toastify";
import i18next from "i18next";
import { getErrorMessage } from "_common/errors/errorUtils";

type Props = {
    comment: TCommentMdl;
    isAdminOrOwner: boolean;
};

export function Comment(props: Props) {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = React.useState(false);
    return (
        <Grid item xs={12}>
            <Card elevation={0}>
                <CardContent>
                    <div className="flex_row_center">
                        <h3 className="flex-1">
                            {props.comment.user.firstName + " " + props.comment.user.lastName?.substr(0, 1)}
                        </h3>
                        {props.isAdminOrOwner && (
                            <div>
                                <UiButton
                                    style={isEditing ? { visibility: "hidden" } : undefined}
                                    onClick={() => !isEditing && setIsEditing(!isEditing)}
                                    variant={"outlined"}
                                    color={"primary"}
                                >
                                    {t("words.edit")}
                                </UiButton>
                                <UiButton
                                    className={"ml_10"}
                                    onClick={() => {
                                        commentsStore
                                            .delete(props.comment._id)
                                            .then(() => {
                                                toast.success(i18next.t("comment.successSuppressing"));
                                            })
                                            .catch((e) => toast.error(getErrorMessage(e)));
                                    }}
                                    variant={"outlined"}
                                    color={"primary"}
                                >
                                    {t("words.delete")}
                                </UiButton>
                            </div>
                        )}
                    </div>
                    <CommentForm
                        isEditing={isEditing}
                        onStopEditing={() => setIsEditing(false)}
                        comment={props.comment}
                    />
                </CardContent>
            </Card>
        </Grid>
    );
}
