import React from "react";
import styles from "./_css/homepageEucPresentation.module.css";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import euc_presentation from "./assets/euc_presentation.png";
import clsx from "clsx";
import { URLS } from "_configs/URLS";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";

const HomepageEucPresentation = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <div className={clsx(styles.content, "flex_column flex-1")}>
                <h3>{t("homepage.euc.title")}</h3>
                <p>{t("homepage.euc.description")}</p>
                <UiButton
                    color={"secondary"}
                    variant={"contained"}
                    href={URLS.shop(t("routes.productTypes." + PRODUCT_TYPE.EUC), "")}
                    className={styles.button}
                >
                    {t("homepage.euc.button")}
                </UiButton>
            </div>
            <img src={euc_presentation} />
        </div>
    );
};

export default HomepageEucPresentation;
