import React from "react";

type Props = {
    fill?: string;
    width?: number;
    className?: string;
};

export function Logo(props: Props) {
    const fill1 = props.fill ?? "#014c9a";
    const fill2 = props.fill ?? "#177cc0";
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1203.78 272.36"
            style={props.width !== undefined ? { width: props.width } : undefined}
        >
            <title>Logo Noaio</title>
            <g id="Calque_2" data-name="Calque 2">
                <g id="Calque_1-2" data-name="Calque 1">
                    <path
                        fill={fill1}
                        d="M179.72,11.35a20.59,20.59,0,0,0-20.43,20.43V187.63L37.1,18.54C30.68,9.46,17.81,7.57,8.35,14A20.46,20.46,0,0,0,0,31.4V251.56a20.74,20.74,0,0,0,20.81,20.8c11,0,20.42-9.45,20.42-20.8v-157l121.05,168a10.26,10.26,0,0,0,1.9,2.27,20.49,20.49,0,0,0,15.51,7.56,21,21,0,0,0,20.8-20.8V31.78C200.52,20.81,191.06,11.35,179.72,11.35Z"
                    />
                    <path
                        fill={fill1}
                        d="M390.78,0A126.23,126.23,0,0,0,298.1,40.48c-23.45,25-37.45,58.25-37.45,95.7s14,71.12,37.45,95.71a127.67,127.67,0,0,0,92.68,40.47c34.8,0,68.09-15.88,90.41-40.47a138.26,138.26,0,0,0,38.2-95.71c0-37.45-14.75-70.74-38.2-95.7A122.5,122.5,0,0,0,390.78,0Zm59.77,202c-15.89,17-37.08,27.24-59.77,27.24-24.21,0-45.4-10.22-61.28-27.24-15.89-16.26-25.73-40.09-25.73-65.82S313.61,86.63,329.5,70c15.88-17,37.07-26.85,61.28-26.85,22.69,0,43.88,9.83,59.77,26.85a98,98,0,0,1,25.72,66.2C476.27,161.91,466.06,185.74,450.55,202Z"
                    />
                    <path
                        fill={fill1}
                        d="M687.7,21.18A20.81,20.81,0,0,0,667.65,8c-8.32-.38-16.64,4.91-20,13.23L564,244a21.47,21.47,0,0,0,12.49,27.24,22.15,22.15,0,0,0,7.18,1.13,21.23,21.23,0,0,0,19.67-13.24L621.88,210H713.8l18.54,49.17a20.35,20.35,0,0,0,19.29,13.24,20.67,20.67,0,0,0,7.19-1.13A21.47,21.47,0,0,0,771.3,244ZM637,168.34,667.65,87l30.64,81.33Z"
                    />
                    <path
                        fill={fill1}
                        d="M846.56,7.57a20.51,20.51,0,0,0-20.81,20.8V251.56a20.51,20.51,0,0,0,20.81,20.8c11.34,0,21.18-9.08,21.18-20.8V28.37C867.74,16.65,857.9,7.57,846.56,7.57Z"
                    />
                    <path
                        fill={fill1}
                        d="M1165.6,40.48A122.48,122.48,0,0,0,1075.2,0a126,126,0,0,0-25.42,2.62c-22.9,4.71-42.32,14.43-61.36,27.71A189.14,189.14,0,0,0,967.16,47.4c-12.73,12-38.48,40.86-64.86,74.41l39.76-9.14,5.12-1.16,47.65-11.21A91.2,91.2,0,0,1,1013.89,70c15.91-17,37.09-26.84,61.31-26.84,22.68,0,43.86,9.85,59.74,26.84a97.89,97.89,0,0,1,20.33,98.25L1198,176a139.24,139.24,0,0,0,5.76-39.79C1203.78,98.73,1189,65.46,1165.6,40.48Z"
                    />
                    <path
                        fill={fill2}
                        d="M1134.94,202c-15.88,17.05-37.06,27.23-59.74,27.23-24.22,0-45.4-10.18-61.31-27.23-11.58-11.83-19.94-27.72-23.63-45.41l-44.69-8.13c2.67,32.49,16.06,61.56,36.94,83.43a127.54,127.54,0,0,0,92.69,40.49c34.8,0,68.08-15.87,90.4-40.49a138.18,138.18,0,0,0,26-38.7l-44.11-8A88.56,88.56,0,0,1,1134.94,202Z"
                    />
                </g>
            </g>
        </svg>
    );
}
