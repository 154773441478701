import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import clsx from "clsx";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { TextArea } from "_common/ui/forms/TextArea";
import { Controller, FormProvider, useForm } from "react-hook-form";
import styles from "./_css/contactFormComponent.module.css";
import { ContactFormBtn } from "components/misc/contactUs/ContactFormBtn";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import i18next from "i18next";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { messageOBJECTS } from "components/misc/contactUs/messageObject";
import { userStore } from "users/_stores/userStore";

type Props = {
    style?: CSSProperties;
    theme?: "white" | "gray";
    $component?: TComponent;
};

export function ContactFormComponent(props: Props) {
    const form = useForm({
        mode: "onBlur",
        defaultValues: {
            name: "",
            email: userStore.user?.email ?? "",
            phone: "",
            subject: messageOBJECTS[i18next.language][0],
            text: "",
        },
    });
    const { t } = useTranslation();
    const emailRequired = form.watch("phone") === "";
    const phoneRequired = form.watch("email") === "";
    return (
        <FormProvider {...form}>
            <div style={{ margin: "0 -10px", ...props.style }}>
                <div className={clsx(styles.line, "flex_row flex-1 pb_20")}>
                    <div className="mh_10 flex-1">
                        <InputBlock label={t("words.name")} required>
                            <Controller
                                as={Input}
                                name="name"
                                control={form.control}
                                theme={props.theme}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                                error={form.errors.name}
                            />
                        </InputBlock>
                    </div>
                    <div className="mh_10 flex-1">
                        <InputBlock label={t("words.email")} required={emailRequired}>
                            <Controller
                                as={Input}
                                name="email"
                                control={form.control}
                                theme={props.theme}
                                rules={{
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t("errors.forms.emailInvalid"),
                                    },
                                    required: {
                                        value: emailRequired,
                                        message: t<string>("errors.forms.requiredMailPhone"),
                                    },
                                }}
                                error={form.errors.email}
                            />
                        </InputBlock>
                    </div>
                    <div className="mh_10 flex-1">
                        <InputBlock label={t("words.phone")} required={phoneRequired}>
                            <Controller
                                as={Input}
                                name="phone"
                                control={form.control}
                                theme={props.theme}
                                placeholder="(111) 222-3333"
                                rules={{
                                    minLength: {
                                        value: 10,
                                        message: t<string>("errors.forms.phoneInvalid"),
                                    },
                                    maxLength: {
                                        value: 14,
                                        message: t<string>("errors.forms.phoneInvalid"),
                                    },
                                    required: {
                                        value: phoneRequired,
                                        message: t<string>("errors.forms.requiredMailPhone"),
                                    },
                                }}
                                error={form.errors.phone}
                            />
                        </InputBlock>
                    </div>
                </div>
                <div className={clsx(styles.line, "flex_row flex-1 pb_20")}>
                    <div className="mh_10 flex-1">
                        <InputBlock label={t("contact.messageObject")} required>
                            <Controller
                                as={
                                    <UiSelect>
                                        {messageOBJECTS[i18next.language].map((object, key) => (
                                            <MenuItem value={object} key={key}>
                                                {object}
                                            </MenuItem>
                                        ))}
                                    </UiSelect>
                                }
                                defaultValue={messageOBJECTS[i18next.language][0]}
                                name="subject"
                                control={form.control}
                                theme={props.theme}
                            />
                        </InputBlock>
                    </div>
                </div>
                <div className={clsx(styles.line, "flex_row flex-1 pb_20")}>
                    <div className="mh_10 flex-1">
                        <InputBlock label={t("contact.yourMessage")} required>
                            <Controller
                                as={TextArea}
                                name="text"
                                control={form.control}
                                theme={props.theme}
                                rows={5}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                                placeholder={t("contact.writeYourMessage")}
                                error={form.errors.text}
                            />
                        </InputBlock>
                    </div>
                </div>
                <div className="mh_10">
                    <ContactFormBtn />
                </div>
            </div>
        </FormProvider>
    );
}
