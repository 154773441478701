import React, { useContext } from "react";
import { OrderStore } from "./_stores/OrderStore";

const OrderCtxt = React.createContext<OrderStore>({} as OrderStore);

type Props = {
    orderStore: OrderStore;
};

export function useOrderStore() {
    return useContext(OrderCtxt);
}

export function OrderContext(props: React.PropsWithChildren<Props>) {
    return <OrderCtxt.Provider value={props.orderStore}>{props.children}</OrderCtxt.Provider>;
}
