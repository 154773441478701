import React from "react";
import { useProductStore } from "products/ProductContext";
import { observer } from "mobx-react";

export const EucDescription = observer(() => {
    const productStore = useProductStore();
    if (!productStore.selectedVariant) return null;
    return (
        <div className="flex_row_center mt_10">
            {/*<Warranty />*/}
            {/*<h4>+{formatPrice(productStore.warrantyPrice)}</h4>*/}
        </div>
    );
});
