import React from "react";
import { TOrderLine } from "orders/_models/OrderMdl";
import { IconButton, InputBase, Paper } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { observer } from "mobx-react";
import { cartStore } from "cart/_stores/cartStore";
import { productsStore } from "products/_stores/productsStore";
import styles from "./_css/cartLine.module.css";
import clsx from "clsx";
import { isActiveDiscountVariant } from "_common/_utils/productUtils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import { formatPrice } from "_common/_utils/currencyUtils";

type Props = {
    cartLine: TOrderLine;
    isNotEditable?: boolean;
};

export const CartLine = observer((props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    let photoUrl = undefined;
    if (!props.cartLine.variant && !props.cartLine.text) return null;
    const { product, variant: currentVariant } = productsStore.getProductByVariantId(props.cartLine.variant);
    if (!product && !props.cartLine.text) return null;
    if (product) {
        if (!currentVariant) return null;
        photoUrl = product?.photos?.[0]?.url;
    }
    const isVariant = product && currentVariant;
    const isNotEditableQuantity = !product || !currentVariant || props.isNotEditable;

    return (
        <Paper elevation={0} className={clsx(styles.regularLine, "p_10 pr_20 pl_20 flex_row")}>
            <div className={clsx(styles.productQuantity, "flex_row")}>
                <div className={clsx(styles.product, "flex_row")}>
                    <div
                        className={clsx(styles.photo, "mr_20 cursor_pointer")}
                        onClick={() => (product ? history.push(URLS.product(product.localized.urlAlias)) : undefined)}
                    >
                        {product && (
                            <img
                                alt={product?.localized.name}
                                src={photoUrl ? photoUrl : ""}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "https://via.placeholder.com/200?text=noaio.com";
                                }}
                            />
                        )}
                    </div>
                    <div className="flex_column justifyContent_center">
                        <div
                            className="mb_5 fontWeight_bold cursor_pointer"
                            onClick={() =>
                                product ? history.push(URLS.product(product.localized.urlAlias)) : undefined
                            }
                        >
                            {isVariant && currentVariant ? (
                                <>
                                    {currentVariant.isPreorder && t("product.preorder")} {product?.localized.name}
                                </>
                            ) : (
                                props.cartLine.text
                            )}
                        </div>
                        {isVariant && <div className="mb_5">{props.cartLine.text}</div>}
                        <div className="flex_row alignItems_flexEnd">
                            <h3 className="mr_10">{formatPrice(props.cartLine.price)}</h3>
                            {product &&
                                currentVariant &&
                                currentVariant.discount &&
                                isActiveDiscountVariant(currentVariant.discount) &&
                                !currentVariant.isPreorder && (
                                    <div className={clsx(styles.isDiscountPrice, "mb_1 fontWeight_bold")}>
                                        {formatPrice(currentVariant.price)}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className={clsx(styles.quantity, "flex_row alignItems_center")}>
                    <div className="flex_column alignItems_center">
                        <Paper
                            elevation={0}
                            className={clsx(styles.quantityInput, "flex_center_center justifyContent_spaceBetween")}
                        >
                            <IconButton
                                className="mr_3"
                                size={"small"}
                                disabled={isNotEditableQuantity}
                                onClick={() =>
                                    product && currentVariant ? cartStore.removeItem(currentVariant) : undefined
                                }
                            >
                                <Remove fontSize={"small"} />
                            </IconButton>
                            <InputBase
                                style={{ fontSize: 12 }}
                                value={props.cartLine.quantity}
                                disabled={isNotEditableQuantity}
                            />
                            <IconButton
                                size={"small"}
                                onClick={() =>
                                    product && currentVariant ? cartStore.addVariantItem(currentVariant) : undefined
                                }
                                disabled={isNotEditableQuantity}
                            >
                                <Add fontSize={"small"} />
                            </IconButton>
                        </Paper>
                        {!props.isNotEditable && (
                            <div
                                onClick={() =>
                                    product && currentVariant
                                        ? cartStore.deleteVariantItem(currentVariant)
                                        : cartStore.deleteItem(
                                              props.cartLine.text,
                                              props.cartLine.codeGiftCard ? props.cartLine.codeGiftCard : undefined,
                                          )
                                }
                                className={clsx(styles.removeBtn, "mt_5 flex_row_center alignItems_center ph_5")}
                            >
                                <i style={{ fontSize: 10 }} className="icon-cross mr_5 mt_1" />
                                <div style={{ fontSize: 12 }}>{t("words.remove")}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={clsx(styles.total, "flex_row alignItems_center justifyContent_flexEnd")}>
                <h3>{formatPrice(props.cartLine.quantity * props.cartLine.price)}</h3>
            </div>
        </Paper>
    );
});
