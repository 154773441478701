import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";

class EmailsStore {
    subscribeNewsletter(email: string) {
        return fetchUtils.post(sharedConfig.apiUrl + "/emails/newsletter", { email });
    }

    sendEmailTracking(email: object) {
        return fetchUtils.post(sharedConfig.apiUrl + "/emails/sendTracking", email);
    }
}

const emailsStore = new EmailsStore();
export { emailsStore };
