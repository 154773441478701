import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/fundingModal.module.css";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import LendCareLogo from "../../assets/images/LendCareLogo.png";
import IfExpressLogo from "../../assets/images/IfExpressLogo.png";
import icon_calendar from "../../assets/images/icon_calendar.png";
import icon_time from "../../assets/images/icon_time.png";
import { DialogContent } from "@material-ui/core";
import { formatPrice } from "_common/_utils/currencyUtils";

type Props = {
    funding?: {
        financialLink: string;
        minPrice: number;
        numberMonth: number;
    };
    setIsOpening: (opened: boolean) => void;
    fundingType?: "LENDCARE" | "IFEXPRESS";
};

export function FundingModal(props: Props) {
    const { t } = useTranslation();
    const [fundingSelected, setFundingSelected] = useState(props.fundingType ?? "LENDCARE");
    return (
        <DialogContent className={clsx("mh_20 mb_20", styles.dialog)}>
            <div className={clsx(styles.logoContainer, "flex_row_center justifyContent_spaceBetween mb_20")}>
                <div
                    onClick={() => setFundingSelected("LENDCARE")}
                    className={clsx(styles.logo, "flex_center_center", {
                        [styles.selected]: fundingSelected === "LENDCARE",
                    })}
                >
                    <img src={LendCareLogo} alt={"lend Care"} />
                </div>
                <div
                    onClick={() => setFundingSelected("IFEXPRESS")}
                    className={clsx(styles.logo, "flex_center_center", {
                        [styles.selected]: fundingSelected === "IFEXPRESS",
                    })}
                >
                    <img src={IfExpressLogo} alt={"lend Care"} />
                </div>
            </div>
            <div className={clsx(styles.headerDialog, "flex_center_center")}>
                <h3 className={styles.title}>{t("funding.title")}</h3>
            </div>
            <hr />
            <div>
                {props.funding && fundingSelected === "LENDCARE" && (
                    <div className={clsx(styles.headerDialog, "flex_center_center")}>
                        <h4>
                            {t("funding.prequalify", {
                                minPrice: formatPrice(props.funding.minPrice),
                                numberMonth: props.funding.numberMonth ? props.funding.numberMonth : 60,
                            })}
                        </h4>
                    </div>
                )}
                <div className={clsx(styles.section, "flex_row_center")}>
                    <img
                        className={clsx(styles.icon, styles.img)}
                        src={icon_calendar}
                        alt={"calendar"}
                        height={"20%"}
                        width={"20%"}
                    />
                    <div className={clsx(styles.card, "flex_column")}>
                        <h3>{t("funding.flexible")}</h3>
                        <p>
                            {t(
                                fundingSelected === "LENDCARE"
                                    ? "funding.flexible_text"
                                    : "funding.flexible_textIfExpress",
                            )}
                        </p>
                    </div>
                </div>
                <div className={clsx(styles.section, "flex_row_center")}>
                    <img
                        className={clsx(styles.icon, styles.img)}
                        src={icon_time}
                        alt={"time"}
                        height={"20%"}
                        width={"20%"}
                    />
                    <div className={clsx(styles.card, "flex_column")}>
                        <h3>{t("funding.easy")}</h3>
                        <p>{t("funding.easy_text")}</p>
                    </div>
                </div>
            </div>
            {props.funding && (
                <div className={clsx(styles.bottom, "flex_center_center")}>
                    <a
                        href={
                            fundingSelected === "LENDCARE"
                                ? props.funding.financialLink
                                : "https://demande.icebergfinance.ca/groupe_noaio_inc/2185"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <UiButton className={styles.btn} color={"primary"} variant={"contained"}>
                            <strong>{t("funding.eligibility")}</strong>
                        </UiButton>
                    </a>
                </div>
            )}
        </DialogContent>
    );
}
