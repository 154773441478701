import React from "react";
import { PRODUCT_TYPE, TProductLocalizedMdl } from "products/_models/ProductMdl";
import escooter from "../assets/images/escooter.jpg";
import escooter_folded from "../assets/images/escooter-folded.jpg";
import euc_front from "../assets/images/euc-front.png";
import euc from "../assets/images/euc.jpg";
import styles from "./_css/productDimensions.module.css";
import clsx from "clsx";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import i18next from "i18next";

type Props = {
    product: TProductLocalizedMdl;
};

export function ProductDimensions(props: Props) {
    const isScooter = props.product.type === PRODUCT_TYPE.SCOOTER;
    const inchHeight = convert(props.product.options.height, UNIT_CONVERSION.CM_TO_IN);
    const inchLength = convert(props.product.options.length, UNIT_CONVERSION.CM_TO_IN);
    const inchWidth = convert(props.product.options.width, UNIT_CONVERSION.CM_TO_IN);
    const inchFoldedHeight = convert(props.product.options.foldedHeight, UNIT_CONVERSION.CM_TO_IN);
    const inchFoldedLength = convert(props.product.options.foldedLength, UNIT_CONVERSION.CM_TO_IN);
    const translateInch = i18next.t("units.INCH");
    const isFoldedNull = !props.product.options.foldedHeight || !props.product.options.foldedLength;

    return (
        <div className={clsx(styles.container, "p_20")}>
            <h4>Dimensions</h4>
            <div className={clsx("mt_40", styles.imgContent)}>
                {isScooter ? (
                    <div className={clsx("flex_row", styles.images)}>
                        <div className={clsx(styles.imgContainer, "position_relative")}>
                            <div className={clsx(styles.height, "position_absolute")}>
                                {props.product.options.height && (
                                    <>
                                        <div className={"flex_column_center"}>{props.product.options.height} cm</div>
                                        <div className={"flex_column_center"}>
                                            {inchHeight} {translateInch}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={clsx(styles.length, "position_absolute")}>
                                {props.product.options.length && (
                                    <>
                                        <div className={"flex_column_center"}>{props.product.options.length} cm</div>
                                        <div className={"flex_column_center"}>
                                            {inchLength} {translateInch}
                                        </div>
                                    </>
                                )}
                            </div>
                            <img src={escooter} alt={"escooter"} className={styles.img} />
                        </div>
                        {!isFoldedNull && (
                            <div className={clsx(styles.imgContainer, "position_relative")}>
                                <div className={clsx(styles.foldedHeight, "position_absolute")}>
                                    {props.product.options.height && (
                                        <>
                                            <div className={"flex_column_center"}>
                                                {props.product.options.foldedHeight} cm
                                            </div>
                                            <div className={"flex_column_center"}>
                                                {inchFoldedHeight} {translateInch}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={clsx(styles.length, "position_absolute")}>
                                    {props.product.options.length && (
                                        <>
                                            <div className={"flex_column_center"}>
                                                {props.product.options.foldedLength} cm
                                            </div>
                                            <div className={"flex_column_center"}>
                                                {inchFoldedLength} {translateInch}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <img src={escooter_folded} alt={"escooter folded"} className={styles.img} />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={styles.eucContainer}>
                        {props.product.options.height && props.product.options.length && (
                            <div className={clsx(styles.imgContainer, "position_relative")}>
                                <div className={clsx(styles.height, "position_absolute mr_10")}>
                                    {props.product.options.height && (
                                        <>
                                            <div className={"flex_column_center"}>
                                                {props.product.options.height} cm
                                            </div>
                                            <div className={"flex_column_center"}>
                                                {inchHeight} {translateInch}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={clsx(styles.length, "position_absolute")}>
                                    {props.product.options.length && (
                                        <>
                                            <div className={"flex_column_center"}>
                                                {props.product.options.length} cm
                                            </div>
                                            <div className={"flex_column_center"}>
                                                {inchLength} {translateInch}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <img src={euc} alt={"euc"} className={styles.img} />
                            </div>
                        )}
                        {props.product.options.width && (
                            <div className={styles.widthContainer}>
                                <img src={euc_front} alt={"euc front"} className={styles.img} />
                                <div className={"textAlign_center"}>
                                    <p>{props.product.options.width} cm</p>
                                    <p>
                                        {inchWidth} {translateInch}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
