import React from "react";
import { TOrderListingMdl } from "orders/_models/OrderMdl";
import { OrderDocumentCustomerInfo } from "orders/document/OrderDocumentCustomerInfo";
import { OrderDocumentNoaioInfo } from "orders/document/OrderDocumentNoaioInfo";
import { OrderDocumentLines } from "orders/document/OrderDocumentLinesInfo";
import { OrderDocumentTotal } from "orders/document/OrderDocumentTotal";
import { Container, Grid } from "@material-ui/core";
import styles from "./_css/orderViewContent.module.css";
import clsx from "clsx";
import { OrderStore } from "orders/_stores/OrderStore";
import { observer } from "mobx-react";
import { OrderContext } from "orders/OrderContext";
import { OrderDocumentHeader } from "orders/document/OrderDocumentHeader";
import { OrderDocumentActions } from "./document/OrderDocumentActions";

type Props = {
    order: TOrderListingMdl;
    isNotOnDocumentPage?: boolean;
};

export const OrderViewContent = observer((props: Props) => {
    return (
        <OrderContext orderStore={new OrderStore(props.order)}>
            <Container className={clsx(styles.container)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={clsx(styles.header, "flex_row_center")}>
                        <OrderDocumentHeader />
                    </Grid>
                    <Grid item xs={6} className={styles.header}>
                        <OrderDocumentCustomerInfo order={props.order} />
                    </Grid>
                    <Grid item xs={6} className={styles.header}>
                        <OrderDocumentNoaioInfo />
                    </Grid>
                    <Grid item xs={12}>
                        <OrderDocumentLines order={props.order} />
                    </Grid>
                    <Grid item xs={3} md={7} />
                    <Grid item xs={9} md={5}>
                        <OrderDocumentTotal order={props.order} />
                    </Grid>
                    <OrderDocumentActions />
                </Grid>
            </Container>
        </OrderContext>
    );
});
