import React from "react";
import { OrderTypeLabel } from "orders/OrderTypeLabel";
import { ORDER_TYPE } from "orders/_models/OrderMdl";
import { IconButton } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import { Logo } from "_common/icons/Logo";
import styles from "orders/_css/orderViewContent.module.css";
import { useOrderStore } from "orders/OrderContext";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { URLS } from "_configs/URLS";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

type Props = {
    isNotOnDocumentPage?: boolean;
};

export const OrderDocumentHeader = observer((props: Props) => {
    const { t } = useTranslation();
    const { order } = useOrderStore();
    const history = useHistory();
    if (!order._id) return null;
    return (
        <>
            <OrderTypeLabel type={order.type ?? ORDER_TYPE.QUOTATION} />
            <div className="mr_5" />
            <h2>{order.no}</h2>
            <p className={"ml_10 text_normal"}>{dayjs(order.orderDate).format(t("dates.formats.full"))}</p>
            <div className="no-print">
                <IconButton
                    onClick={() =>
                        props.isNotOnDocumentPage
                            ? history.push(order._id ? URLS.order(order._id) : "")
                            : window.print()
                    }
                >
                    <Print />
                </IconButton>
            </div>
            <div className="flex-1" />
            {!props.isNotOnDocumentPage && <Logo className={styles.headerLogo} width={120} />}
        </>
    );
});
