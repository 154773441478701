import React from "react";
import { TReviewEditionMdl } from "products/_models/ProductMdl";
import { toast } from "react-toastify";
import i18next from "i18next";
import { getErrorMessage } from "_common/errors/errorUtils";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useProductStore } from "products/ProductContext";

type Props = {
    review: TReviewEditionMdl;
    onReviewChange: (review: TReviewEditionMdl) => void;
    setIsOpening?: (boolean: boolean) => void;
};

export function ProductReviewAddBtn(props: Props) {
    const productStore = useProductStore();
    const review = props.review;
    return (
        <>
            <UiButton
                variant="contained"
                color="primary"
                onClick={() => {
                    productStore
                        .addReview(review)
                        .then(() => {
                            toast.success(i18next.t("product.review.successCommenting"));
                            props.onReviewChange({
                                ...review,
                                mark: 5,
                                comment: "",
                                title: "",
                                user: { firstName: "", lastName: "" },
                            });
                        })
                        .catch((e) => toast.error(getErrorMessage(e)));
                }}
            >
                {i18next.t("words.send")}
            </UiButton>
        </>
    );
}
