import React from "react";
import { Paper } from "@material-ui/core";
import { observer } from "mobx-react";
import { UserForm } from "users/profile/info/userInfo/UserForm";

export const CheckoutUserInfo = observer(() => {
    return (
        <Paper elevation={0} style={{ overflow: "visible" }}>
            <div className="p_20">
                <UserForm cannotCancel isEditing />
            </div>
        </Paper>
    );
});
