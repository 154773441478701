import React from "react";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import styles from "./_css/item.module.css";
import clsx from "clsx";
import { useHistory } from "react-router";
import { searchStore } from "main/menu/search/_stores/searchStore";
import { URLS } from "_configs/URLS";

type Props = {
    product: TProductLocalizedMdl;
    selected?: boolean;
    onClick?: () => void;
};

export function ProductFound(props: Props) {
    const history = useHistory();
    return (
        <div
            onClick={() => {
                history.push(URLS.product(props.product.localized.urlAlias));
                searchStore.open(false);
                if (props.onClick) props.onClick();
            }}
            style={{ position: "relative" }}
            className={clsx(styles.container, "paper-small flex_row", {
                [styles.selected]: props.selected,
            })}
        >
            <div className={clsx(styles.imageContainer, "mr_20 flex_row_center")}>
                <img
                    className={styles.image}
                    alt={props.product.localized.name}
                    src={props.product.photos?.[0]?.url}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "https://via.placeholder.com/400?text=noaio.com";
                    }}
                />
            </div>
            <div className={"flex_column pr_10"}>
                <div className={clsx(styles.title)}>{props.product.localized.name}</div>
                <div className={clsx(styles.description)}>{props.product.localized.description}</div>
            </div>
        </div>
    );
}
