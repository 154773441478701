import dayjs, { Dayjs } from "dayjs";
import { TVariantBaseMdl, TVariantMdl, VARIANT_STATUS } from "products/_models/VariantMdl";
import { s6 } from "_common/_utils/coreUtils";
import { TUserSummaryMdl } from "users/_models/UserMdl";
import { TRootComponent } from "pages/_models/PageMdl";
import { TLabelLocalizedMdl } from "admin/labels/_models/LabelMdl";
import { TLang } from "_configs/sharedConfig";

type TPageMeta = {
    title?: string;
    description?: string;
    image?: string;
};

export type TProductLocalized = {
    name: string;
    description: string;
    urlAlias: string;
    meta: TPageMeta;
    component: TRootComponent;
};

export enum PRODUCT_TYPE {
    EUC = "EUC",
    SCOOTER = "SCOOTER",
    SPP = "SPP",
    ACCESSORY = "ACCESSORY",
    NONE = "NONE",
    REFURBISHED = "REFURBISHED",
}

export const SORTABLE_TYPES = [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.ACCESSORY];
export enum PRODUCT_STATUS {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum PRODUCT_SORTING {
    ASC = 1,
    DESC = -1,
}

export enum FILTER_TYPE {
    CHECKBOX = "CHECKBOX",
    SLIDER = "SLIDER",
    BOOLEAN = "BOOLEAN",
}

export type TOption = { [key: string]: any };
export type TProductPhoto = { url: string };

export type TKeyFeatureBaseMdl = {
    icon: string;
    localized: {
        [lang in TLang]: {
            title: string;
            description: string;
        };
    };
};

export type TKeyFeatureLocalizedMdl = TKeyFeatureBaseMdl & {
    localized: {
        title: string;
        description: string;
    };
};

export type TReviewBaseMdl = {
    _id?: string;
    user: TUserSummaryMdl | { _id?: string; firstName: string; lastName?: string };
    title: string;
    mark: number;
    comment: string;
    dateCreation: Dayjs;
    createdAt: Dayjs;
    updatedAt: Dayjs;
};

export type TReviewMdl = TReviewBaseMdl & {
    checked: boolean;
    isDisplay: boolean;
};

export type TReviewListingMdl = TReviewBaseMdl & {
    user: TUserSummaryMdl | { firstName: string; lastName: string };
    checked: boolean;
    isDisplay: boolean;
};

export type TReviewEditionMdl = TReviewBaseMdl;

export type TProductBaseMdl = {
    _id: string;
    status: PRODUCT_STATUS;
    type: PRODUCT_TYPE;
    photos?: TProductPhoto[];
    variants?: TVariantBaseMdl[];
    keyFeatures?: TKeyFeatureBaseMdl[];

    linkedProducts?: string[];
    subType?: PRODUCT_TYPE;
    options: TOption;
    position?: number;
    sku?: string;
    createdAt: Dayjs;
    updatedAt: Dayjs;
};

export type TProductMdl = TProductBaseMdl & {
    localized: {
        fr: TProductLocalized;
        en: TProductLocalized;
    };
    brand?: {
        _id: string;
        label: string;
        logo: string;
    };
    labels?: string[];
    funding?: {
        financialLink: string;
        minPrice: number;
        numberMonth: number;
    };
};

export type TProductLocalizedMdl = TProductBaseMdl & {
    localized: TProductLocalized;
    minPrice: number;
    brand?: {
        _id: string;
        label: string;
        logo: string;
    };
    reviews: TReviewListingMdl[];
    labels: TLabelLocalizedMdl[];
    funding?: {
        financialLink: string;
        minPrice: number;
        numberMonth: number;
    };
    urlAlias: { [key in TLang]: string };
    variants?: TVariantMdl[];
    keyFeatures?: TKeyFeatureLocalizedMdl[];
};

export type TProductEditionMdl = TProductBaseMdl & {
    localized: {
        fr: TProductLocalized;
        en: TProductLocalized;
    };
    brand?: string;
    reviews: TReviewMdl[];
    labels: string[];
    funding?: {
        financialLink: string;
        minPrice: number;
        numberMonth: number;
    };
};

export function createDefaultProduct() {
    return {
        status: PRODUCT_STATUS.INACTIVE,
        type: PRODUCT_TYPE.EUC,
        localized: {
            fr: {
                name: "Nom FR",
                description: "Description FR",
                urlAlias: "new-unique-url-fr-" + s6(),
                meta: {
                    title: "Titre FR",
                    description: "Description FR",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
            en: {
                name: "Name EN",
                description: "Description EN",
                urlAlias: "new-unique-url-en-" + s6(),
                meta: {
                    title: "Title EN",
                    description: "Description EN",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
        },
        options: [],
        linkedProducts: undefined,
        brand: undefined,
        labels: undefined,
        funding: undefined,
        reviews: [
            {
                title: "",
                mark: 5,
                comment: "",
                checked: false,
                isDisplay: false,
                user: {
                    firstName: "",
                    lastName: "",
                },
                dateCreation: dayjs(),
                createdAt: dayjs(),
                updatedAt: dayjs(),
            },
        ],
        variants: [
            {
                status: VARIANT_STATUS.INACTIVE,
                discount: { status: VARIANT_STATUS.INACTIVE, value: 0 },
                localized: {},
                price: 0,
                finalePrice: 0,
                stock: 0,
                options: [],
                isPreorder: false,
                sellingLink: "",
            },
        ],
    };
}
