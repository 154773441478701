import { slideShowsStore } from "slideShows/_stores/slideShowsStore";
import React, { useState } from "react";
import styles from "./_css/popupBanner.module.css";
import clsx from "clsx";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import CloseIcon from "@material-ui/icons/Close";
import { UiButton } from "_common/ui/mui/buttons/UiButton";

const popup = {
    isOpen: true,
};

export const PopupBanner = () => {
    const [open, setOpen] = useState(popup.isOpen);
    const _banner = slideShowsStore.getListStore("slideShows").items;
    const banner = _banner.find((item) => item?.isPopup);

    if (!banner?.slides.length) return null;
    const slide = banner.slides.pop();
    if (!slide) return null;

    const localizedSlide = slide[i18nextInstance.language as TLang];

    if (!open) return null;

    return (
        <div className={clsx(styles.container, "flex_row position_fixed")}>
            <div className={clsx(styles.text, "p_10 flex_column justifyContent_spaceBetween")}>
                <div
                    onClick={() => {
                        setOpen(false);
                        popup.isOpen = false;
                    }}
                    className={"flex_row cursor_pointer mb_5"}
                >
                    <CloseIcon fontSize={"default"} />
                </div>
                <ExternalOrNavLink url={localizedSlide.url} className={clsx(styles.content, "flex_column flex-1")}>
                    <div className={"flex_column flex-1 justifyContent_spaceBetween"}>
                        <h4>{localizedSlide.title}</h4>
                        <div className={"flex-1"} />
                        <div className="text_normal">{localizedSlide.text}</div>
                        <div className={"flex-1"} />
                        <UiButton variant={"contained"} color={"primary"}>
                            {localizedSlide.cta}
                        </UiButton>
                    </div>
                </ExternalOrNavLink>
            </div>
            <ExternalOrNavLink url={localizedSlide.url} className={styles.image}>
                <img src={localizedSlide.image} alt={localizedSlide.title} />
            </ExternalOrNavLink>
        </div>
    );
};
