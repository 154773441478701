import React, { useState } from "react";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "shared/_common/i18n/IntlProvider";
import { pagesStore } from "pages/_stores/pagesStore";
import styles from "./_css/langSelector.module.css";
import clsx from "clsx";
import { productsStore } from "products/_stores/productsStore";

function getTranslationObjectForParam(param: string, lang: TLang) {
    const translatedParam = Object.entries(i18nextInstance.getDataByLanguage(lang)!.translation.routes).find(
        ([_key, value]) => value === param,
    );
    if (translatedParam) {
        return {
            key: translatedParam[0],
            value: translatedParam[1],
        };
    } else return undefined;
}

function handleParamTranslation(param: string, currentLang: TLang, newLang: TLang) {
    const product = productsStore.getByAliasUrl(param);
    if (product) {
        return product.urlAlias[newLang];
    } else {
        const translationKey = getTranslationObjectForParam(param, currentLang)?.key;
        const translatedParam = Object.entries(i18nextInstance.getDataByLanguage(newLang)!.translation.routes).find(
            ([key, _value]) => key === translationKey,
        );
        if (translatedParam) return translatedParam[1];
    }
    return undefined;
}

function createNewPathname(params: string[], currentLang: TLang, newLang: TLang) {
    const arrNewPathname = [...params];

    params.forEach((param, i) => {
        const translatedParam = handleParamTranslation(param, currentLang, newLang);
        if (!translatedParam) throw new Error("No translation found for param");
        arrNewPathname[i] = translatedParam;
    });

    return arrNewPathname.length > 1 ? arrNewPathname.join("/") : arrNewPathname.toString();
}

function redirectWithNewLang(currentLang: TLang, newLang: TLang) {
    const langConfig = sharedConfig.languages[newLang];
    const { pathname, hash, search } = window.location;
    const params = pathname.split("/").filter((param) => param && param !== currentLang);

    if (!params.length) window.location.href = langConfig.baseUrl;

    try {
        const page = pagesStore.getByUrlSync(pathname, currentLang);
        const translatedPageUrl = page?.localized?.[newLang]?.url;
        if (translatedPageUrl && !page?.localized?.[newLang]?.published) {
            throw new Error("Page is not published in this language");
        }
        if (translatedPageUrl) window.location.href = langConfig.baseUrl + translatedPageUrl + search + hash;
        else {
            const newPathname = createNewPathname(params, currentLang, newLang);
            window.location.href = langConfig.baseUrl + "/" + newPathname + search + hash;
        }
    } catch (e) {
        window.location.href = langConfig.baseUrl;
    }
}

async function changeLang(newLang: TLang) {
    const currentLang = i18nextInstance.language as TLang;
    if (currentLang === newLang) return;
    redirectWithNewLang(currentLang, newLang);
}

export function LangSelector() {
    const [opened, setOpened] = useState(false);
    const toggleOpened = () => setOpened(!opened);
    return (
        <div onMouseEnter={toggleOpened} onMouseLeave={toggleOpened} className={styles.container}>
            <div
                className={clsx("flex_center_center", styles.flag, {
                    [styles.flagHover]: opened,
                })}
            >
                <h4
                    className={clsx(styles.lang, {
                        [styles.langHover]: opened,
                    })}
                >
                    {i18nextInstance.language.toUpperCase()}
                </h4>
            </div>
            {opened && (
                <div className={styles.languages}>
                    {Object.entries(sharedConfig.languages).map(([lang, { title }]) => (
                        <div
                            className={styles.language}
                            key={lang}
                            onClick={() => {
                                setOpened(false);
                                void changeLang(lang as TLang);
                            }}
                        >
                            {title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
