import React from "react";
import { TOrderLine } from "orders/_models/OrderMdl";
import Grid from "@material-ui/core/Grid";
import { formatPrice } from "_common/_utils/currencyUtils";
import { productsStore } from "products/_stores/productsStore";
import { useTranslation } from "react-i18next";

type Props = {
    line: TOrderLine;
};

export function OrderDocumentLine(props: Props) {
    const { t } = useTranslation();
    const { product, variant: currentVariant } = productsStore.getProductByVariantId(props.line.variant);
    return (
        <Grid container spacing={1} className="flex_row_center">
            <Grid item xs={3}>
                {product && (
                    <h4 className="mb_3">
                        {product.localized.name} <br />
                    </h4>
                )}
                {currentVariant && currentVariant.isPreorder && <div className="mb_3">{t("product.preorder")}</div>}
                {props.line.text}
            </Grid>
            {props.line.quantity <= 0 ? (
                <>
                    <Grid item xs={9} className="textAlign_right" />
                </>
            ) : (
                <>
                    <Grid item xs={3} className="textAlign_right">
                        {props.line.quantity}
                    </Grid>
                    <Grid item xs={3} className="textAlign_right">
                        {formatPrice(props.line.price)}
                    </Grid>
                    <Grid item xs={3} className="textAlign_right">
                        <strong>{formatPrice(props.line.price * props.line.quantity)}</strong>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
