import React, { useEffect } from "react";
import styles from "./_css/initiationPage.module.css";
// @ts-ignore
import initiation_gyroroue from "./assets/initiation_gyroroue.mp4";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const InitiationPage = () => {
    const { t } = useTranslation();
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        script.type = "text/javascript";
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <div className={styles.container}>
            <h1>{t("initiation.title")}</h1>
            <p>{t("initiation.description")}</p>
            <div className={styles.innerContainer}>
                <div
                    className={"calendly-inline-widget"}
                    data-url="https://calendly.com/initiation-gyroroue-noaio/initiation-gyroroue"
                    style={{ minWidth: "320px", height: "780px" }}
                ></div>
                <div className={styles.headband}>
                    <p>{t("initiation.video")}</p>
                </div>
                <div className={clsx(styles.videoContainer, "mt_20")}>
                    <video width={"350px"} height={"auto"} className={styles.video} controls>
                        <source src={initiation_gyroroue} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    );
};

export default InitiationPage;
