import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper } from "@material-ui/core";
import clsx from "clsx";
import styles from "cart/_css/cartTotal.module.css";
import { Input } from "_common/ui/forms/Input";
import { cartStore } from "cart/_stores/cartStore";
import { observer } from "mobx-react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { CartGiftCard } from "cart/giftCard/CartGiftCard";
import { CHECKOUT_STEP } from "cart/CartPage";
import { formatPrice } from "_common/_utils/currencyUtils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { CartSlidebar } from "cart/CartSlidebar";
import { CartPaymentMethod } from "cart/CartPaymentMethod";
import { DELIVERY_COSTS_CONFIG } from "_configs/deliveryCostsConfig";
import { SHIPPING_METHOD } from "orders/_models/OrderMdl";

type Props = {
    currentStep: CHECKOUT_STEP;
    onProcessing?: () => void;
    giftCardInput?: boolean;
    collectInStore?: boolean;
};

export const CartTotal = observer((props: Props) => {
    const { t } = useTranslation();
    const [isCGUChecked, setIsCGUChecked] = useState(false);
    const isNotFreeShipping = cartStore.subtotal / 100 < DELIVERY_COSTS_CONFIG.limit / 100 && !cartStore.isOnlyGiftCard;
    return (
        <>
            <Paper elevation={0} className={clsx(styles.regularLine, "p_20")}>
                <Grid container justify={"flex-end"} spacing={2}>
                    {isNotFreeShipping && <CartSlidebar />}
                    <Grid item xs={12}>
                        <span className={"fontWeight_bold"}>{t("cart.summary")}</span>
                    </Grid>

                    <Grid item xs={7}>
                        <span className="fontWeight_bold">{t("cart.subtotal")}</span>
                    </Grid>
                    <Grid item xs={5} className={"textAlign_right"}>
                        <span className={styles.fontPrice}>{formatPrice(cartStore.subtotal)}</span>
                    </Grid>
                    {props.giftCardInput && (
                        <>
                            <Grid item xs={7}>
                                <Input
                                    onChange={(e) => {
                                        cartStore.setGiftCard(e.target.value);
                                    }}
                                    placeholder={t("cart.discountOrGiftCard")}
                                    defaultValue={cartStore.giftCard?.code}
                                />
                            </Grid>
                            <Grid item xs={5} className="textAlign_right">
                                <UiButton
                                    variant={"outlined"}
                                    onClick={() => cartStore.fetchGiftCard()}
                                    color={"primary"}
                                    title={"search"}
                                >
                                    <i className="icon-check mr_5" /> {t("cart.searchGiftCard")}
                                </UiButton>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <CartGiftCard />
                    </Grid>
                    {props.currentStep === CHECKOUT_STEP.CHECK_OUT && (
                        <>
                            <Grid item xs={7}>
                                <span className={"fontWeight_bold"}>{t("cart.shippingMethod")}</span>
                            </Grid>
                            <Grid item xs={5} className={"textAlign_right"}>
                                <span className={styles.fontPrice}>
                                    {t(
                                        `shippingMethod.${
                                            props.collectInStore
                                                ? SHIPPING_METHOD.COLLECT_IN_STORE
                                                : SHIPPING_METHOD.DELIVERY
                                        }`,
                                    )}
                                </span>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={7}>
                        <span
                            className={clsx("fontWeight_bold", {
                                text_lineThrough: props.collectInStore,
                            })}
                        >
                            {t("cart.deliveryCost")}
                        </span>
                    </Grid>
                    <Grid item xs={5} className={"textAlign_right"}>
                        <span
                            className={clsx(styles.fontPrice, {
                                text_lineThrough: props.collectInStore,
                            })}
                        >
                            {cartStore.deliveryCost ? formatPrice(cartStore.deliveryCost) : t("cart.freeShipping")}
                        </span>
                    </Grid>

                    {props.currentStep != "CART" ? (
                        <>
                            <Grid item xs={7}>
                                <div className={"fontWeight_bold flex_row"}> {cartStore.nameGst} </div>
                            </Grid>
                            <Grid item xs={5} className={"textAlign_right"}>
                                <span className={styles.fontPrice}>{formatPrice(cartStore.gst)}</span>
                            </Grid>
                            {cartStore.localeTax != 0 && (
                                <>
                                    <Grid item xs={7}>
                                        <div className={"fontWeight_bold flex_row"}>{cartStore.nameLocaleTax} </div>
                                    </Grid>
                                    <Grid item xs={5} className={"textAlign_right"}>
                                        <span className={styles.fontPrice}>{formatPrice(cartStore.localeTax)}</span>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} className={"fontWeight_bold"}>
                                <span className="fontWeight_bold">{t("cart.total")}</span>
                            </Grid>
                            <Grid item xs={5} className="textAlign_right">
                                <h3>{formatPrice(cartStore.total)}</h3>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} className={"textAlign_center"}>
                            <span> {t("payments.calculedTax")}</span>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <CartPaymentMethod />
                    </Grid>
                    <Grid item xs={12} className={styles.sizeText}>
                        <span>{t("cart.payment")}</span>
                    </Grid>
                    <Grid item xs={12} className={"flex_row_center"}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isCGUChecked}
                                    onChange={() => setIsCGUChecked(!isCGUChecked)}
                                    name="gcu"
                                    color="primary"
                                />
                            }
                            label={
                                <>
                                    {t("cart.tou")} <Link to={t<string>("termsOfUse.url")}>{t("cart.cgu")}</Link>
                                </>
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className="textAlign_center"
                        onClick={() => {
                            return;
                            props.onProcessing && isCGUChecked ? props.onProcessing() : undefined;
                        }}
                    >
                        <UiButton variant={"contained"} color={"primary"} disabled={!isCGUChecked}>
                            {t(`checkout.steps.${props.currentStep}`)}
                        </UiButton>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
});
