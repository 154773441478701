import React from "react";
import styles from "./_css/cartAddPanelContentProductCard.module.css";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { formatPrice } from "_common/_utils/currencyUtils";
import clsx from "clsx";

type Props = {
    product: TProductLocalizedMdl;
};

const CartAddPanelContentProductCard = ({ product }: Props) => {
    const price = formatPrice(product.minPrice, true);
    const productPhoto = product.photos?.[0]?.url;
    return (
        <div className={clsx(styles.container, "flex_row justifyContent_spaceBetween alignItems_baseline")}>
            <div className={"flex_row alignItems_center"}>
                {productPhoto ? (
                    <img className={styles.image} src={productPhoto} alt={product.localized.name} />
                ) : (
                    <div className={styles.imagePlaceholder} />
                )}
                <p className={clsx(styles.name, "ml_10")}>{product.localized.name}</p>
            </div>
            <p className={styles.price}>{price}</p>
        </div>
    );
};

export default CartAddPanelContentProductCard;
