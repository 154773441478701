import React from "react";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SOURCES = {
    socialMedia: "social media",
    wordOfMouth: "word of mouth",
    flyers: "flyers",
    email: "email",
    other: "other",
};

type Props = {
    onChange?: (source: string) => void;
    value?: string;
};

export function HeardAboutUsInput(props: Props) {
    const { t } = useTranslation();
    return (
        <UiSelect value={props.value} onChange={(e) => props.onChange?.(e.target.value as string)}>
            {Object.keys(SOURCES).map((source) => {
                return (
                    <MenuItem key={source} value={source}>
                        {t(`auth.signUp.heardAboutUsSources.${source}`)}
                    </MenuItem>
                );
            })}
        </UiSelect>
    );
}
