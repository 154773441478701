import React from "react";
import styles from "./_css/inputBlock.module.css";
import clsx from "clsx";
import { TLang } from "_configs/sharedConfig";
import frImage from "../../../assets/images/fr.png";
import enImage from "../../../assets/images/ca.png";

type Props = {
    label: string;
    extralabel?: string;
    text?: string;
    required?: boolean;
    className?: string;
    inputClassName?: string;
    lang?: TLang;
};

export function InputBlock(props: React.PropsWithChildren<Props>) {
    return (
        <div className={props.className}>
            <div className={"flex_row_center"}>
                {props.lang && (
                    <div className="mr_10">
                        <img height={16} src={props.lang === "fr" ? frImage : enImage} alt={props.lang} />
                    </div>
                )}
                <div className={clsx(styles.label, "mr_10")}>
                    {props.label}
                    {props.required && <span className={styles.required}>*</span>}
                </div>
                <div className={styles.extraLabel}>{props.extralabel}</div>
            </div>
            {props.text && <div className={styles.text}>{props.text}</div>}
            <div className={props.inputClassName ?? "mb_10"}>{props.children}</div>
        </div>
    );
}
