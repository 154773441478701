import { action, autorun, computed, observable } from "mobx";
import { tokenStore } from "users/_stores/tokenStore";
import { TUserMdl, USER_ROLE } from "users/_models/UserMdl";
import { authStore } from "users/auth/_stores/authStore";
import { fetchUtils, TFilesData } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import dayjs from "dayjs";

export const TEMPORARY_USER = "TEMPORARY_USER";

class UserStore {
    @observable user: TUserMdl | undefined = undefined;
    private readonly _setDataFromToken = action((_token: string | undefined) => {
        const tokenData = tokenStore.getTokenData<TUserMdl>();
        if (!tokenData) {
            if (this.isLogged) authStore.signOut();
        } else {
            this.user = tokenData;
            analyticsUtils.setUserInfo(this.user.email);
        }
    });

    constructor() {
        autorun(() => {
            const token = tokenStore.token;
            this._setDataFromToken(token);
        });
    }

    @computed get isLogged() {
        return this.user !== undefined;
    }

    @computed get isAdmin() {
        return this.user?.roles.includes(USER_ROLE.ADMIN);
    }

    @computed get isProfileCompleted() {
        return (
            !!this.user?.firstName &&
            !!this.user?.lastName &&
            !!this.user?.phone &&
            !!this.user?.email &&
            !!this.user?.address?.province &&
            !!this.user?.address?.country &&
            !!this.user?.address?.city &&
            !!this.user?.address?.street &&
            !!this.user?.address?.postalCode
        );
    }

    deleteUser() {
        return fetchUtils.delete(sharedConfig.apiUrl + "/users/" + this.user?._id).then(() => authStore.signOut());
    }

    save(user: TUserMdl, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(user, files) : user;
        const isLoggedIn = !!this?.user && this?.user?._id;
        return fetchUtils[user._id ? "patch" : "post"](
            sharedConfig.apiUrl + "/users/" + (this?.user && this?.user?._id ? this.user._id : "customer/new"),
            body,
            !!files,
        ).then(({ data }) => {
            const user = data as TUserMdl;
            if (isLoggedIn) {
                tokenStore.refreshToken();
                this.user = user;
            } else {
                tokenStore.setToken(data as string);
            }
        });
    }

    setLastVisitDate() {
        if (__BROWSER__) {
            window.localStorage.setItem("lastVisitDate", JSON.stringify({ lastVisitDate: dayjs() }));
        }
    }

    setIsSubscribed(isSubscribed: boolean) {
        if (__BROWSER__) {
            window.localStorage.setItem("isSubscribed", JSON.stringify({ isSubscribed }));
        }
    }

    displayNewsletterDialog() {
        const lastVisitDate = this.getLastVisitDate();
        this.setLastVisitDate();
        return !lastVisitDate || (dayjs().subtract(24, "hour").isAfter(lastVisitDate) && !this.getIsSubscribed());
    }

    displayNewsletterButton() {
        return !this.getIsSubscribed();
    }

    private getLastVisitDate() {
        if (__BROWSER__) {
            const isLastVisitDate = window.localStorage.getItem("lastVisitDate");
            if (!isLastVisitDate) return null;
            return JSON.parse(isLastVisitDate).lastVisitDate;
        }
    }

    private getIsSubscribed() {
        if (__BROWSER__) {
            const isSubscribed = window.localStorage.getItem("isSubscribed");
            if (!isSubscribed) return false;
            return JSON.parse(isSubscribed).isSubscribed;
        }
    }
}

export const userStore = new UserStore();
