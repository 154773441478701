import { PRODUCT_TYPE } from "products/_models/ProductMdl";

export enum WARRANTY_DURATIONS {
    DEFAULT = "DEFAULT",
    NONE = "NONE",
    SIX_MONTHS = "SIX_MONTHS",
    ONE_YEAR = "ONE_YEAR",
    TWO_YEARS = "TWO_YEARS",
}

type TWarrantyConfig = {
    [key in PRODUCT_TYPE]: {
        default: WARRANTY_DURATIONS;
    };
};

export const warrantyConfig: TWarrantyConfig = {
    [PRODUCT_TYPE.EUC]: {
        default: WARRANTY_DURATIONS.ONE_YEAR,
    },
    [PRODUCT_TYPE.SCOOTER]: {
        default: WARRANTY_DURATIONS.ONE_YEAR,
    },
    [PRODUCT_TYPE.SPP]: {
        default: WARRANTY_DURATIONS.NONE,
    },
    [PRODUCT_TYPE.ACCESSORY]: {
        default: WARRANTY_DURATIONS.NONE,
    },
    [PRODUCT_TYPE.NONE]: {
        default: WARRANTY_DURATIONS.NONE,
    },
    [PRODUCT_TYPE.REFURBISHED]: {
        default: WARRANTY_DURATIONS.ONE_YEAR,
    },
};
