import React from "react";
import { IPageForSearchMdl } from "pages/_models/PageMdl";
import styles from "./_css/item.module.css";
import clsx from "clsx";
import { useHistory } from "react-router";
import i18next from "i18next";
import { searchStore } from "main/menu/search/_stores/searchStore";

type Props = {
    article: IPageForSearchMdl;
    selected?: boolean;
    onClick?: () => void;
};

export function ArticleFound(props: Props) {
    const history = useHistory();
    return (
        <div
            onClick={() => {
                history.push("/" + i18next.language + props.article.localized.url);
                searchStore.open(false);
                if (props.onClick) props.onClick();
            }}
            style={{ position: "relative" }}
            className={clsx(styles.container, "paper-small flex_row", {
                [styles.selected]: props.selected,
            })}
        >
            <div className={clsx(styles.imageContainer, "mr_20")}>
                <img
                    className={styles.image}
                    alt={props.article.localized.title}
                    src={props.article.localized.image}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "https://via.placeholder.com/400?text=noaio.com";
                    }}
                />
            </div>
            <div className={"flex_column pr_10"}>
                <div className={clsx(styles.title)}>{props.article.localized.title}</div>
                <div className={clsx(styles.description)}>{props.article.localized.description}</div>
            </div>
        </div>
    );
}
