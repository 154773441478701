import React, { useEffect } from "react";
import { TPaymentBaseMdl } from "admin/payments/_models/CrudPaymentMdl";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styles from "./_css/checkoutThanksPage.module.css";
import clsx from "clsx";
import { OrderView } from "orders/OrderView";
import { analyticsUtils } from "_common/_utils/analyticsUtils";

type Props = {
    payment?: TPaymentBaseMdl;
};

export function CheckoutThanksPage(props: Props) {
    const { t } = useTranslation();

    useEffect(() => {
        analyticsUtils.sendEvent({ checkout: true }, window.location.pathname);

        analyticsUtils.trackEcommerceEventDeferred(
            {
                name: "setAction",
                type: "checkout",
            },
            {
                step: 3,
            },
            window.location.pathname,
            true,
        );

        analyticsUtils.trackEcommerceEventDeferred(
            {
                name: "addProduct",
            },
            {
                price: props.payment?.amount ?? 0 / 100,
                quantity: 1,
            },
            window.location.pathname,
        );

        analyticsUtils.trackEcommerceEventDeferred(
            {
                name: "setAction",
                type: "purchase",
            },
            {
                revenue: props.payment?.amount ?? 0 / 100,
            },
            window.location.pathname,
        );
    }, []);

    return (
        <Card elevation={0} className="no-print" style={{ backgroundColor: "transparent" }}>
            <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("checkout.thanksForPayment")} />
            <CardContent className="p_0 mt_10">
                {props.payment && (
                    <div className={clsx(styles.container, "p_20")}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} className="fontWeight_bold">
                                {t("checkout.transactionId")}
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {props.payment.providerPaymentId}
                            </Grid>
                            <Grid item xs={12} sm={4} className="fontWeight_bold">
                                {t("checkout.orderId")}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {props.payment.order}
                            </Grid>
                        </Grid>
                        <div className="mt_20 print">
                            <OrderView isNotOnDocumentPage={true} orderId={props.payment.order} />
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
}
