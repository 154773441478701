import HomepageBenefits from "homepage/HomepageBenefits";
import React from "react";
import styles from "./_css/homepage.module.css";
import HomepageBanner from "homepage/banner/HomepageBanner";
import HomepageProductTypeBlock from "homepage/HomepageProductTypeBlock";
import clsx from "clsx";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import BestSeller from "products/BestSeller";
import { NewsLetterComponent } from "components/misc/newsLetter/NewsLetterComponent";
import HomepageEucPresentation from "homepage/HomepageEucPresentation";
import HomepagePartners from "homepage/HomepagePartners";
import { useTranslation } from "react-i18next";
import HomepageMarketingBanner from "homepage/HomepageMarketingBanner";
import HomepageStoreSection from "homepage/HomepageStoreSection";
import HomepageExternalArticlesSection from "homepage/HomepageExternalArticlesSection";

const Homepage = () => {
    const { t } = useTranslation();
    return (
        <div style={{ marginTop: -30 }}>
            <h1 className={"hidden"}>{t("homepage.h1")}</h1>
            <HomepageBanner />
            <div className={clsx(styles.container, "page-container")}>
                <section className={clsx(styles.productCategories, "flex_row")}>
                    <HomepageProductTypeBlock productType={PRODUCT_TYPE.SCOOTER} />
                    <HomepageProductTypeBlock productType={PRODUCT_TYPE.EUC} />
                </section>
            </div>
            <div className={clsx(styles.storeSectionContainer, "mt_40")}>
                <HomepageStoreSection />
            </div>
            <div>
                <HomepageMarketingBanner />
            </div>
            <section className={clsx(styles.bestSellers, styles.spaceY, "page-container flex_column")}>
                <BestSeller productType={PRODUCT_TYPE.EUC} />
                <BestSeller productType={PRODUCT_TYPE.SCOOTER} />
            </section>
            <section className={styles.spaceY}>
                <HomepageExternalArticlesSection />
            </section>
            <div className={styles.spaceY}>
                <section className={styles.whiteContainer}>
                    <div className={"page-container"}>
                        <HomepageBenefits />
                    </div>
                </section>
                <NewsLetterComponent />
                <section className={styles.whiteContainer}>
                    <div className={"page-container"}>
                        <HomepageEucPresentation />
                    </div>
                </section>
                <section>
                    <div className={"page-container"}>
                        <HomepagePartners />
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Homepage;
