import React, { useEffect } from "react";
import { NotFound } from "pages/NotFound";
import { useHistory } from "react-router";

type Props = {
    redirect?: {
        link: string;
        timer: number;
    };
};

export function PageNotFound({ redirect }: Props) {
    const history = useHistory();

    useEffect(() => {
        if (redirect) {
            const timeoutId = setTimeout(() => {
                if (redirect.link?.startsWith("/")) {
                    history.push(redirect.link);
                } else if (redirect.link) {
                    window.location.href = redirect.link;
                }
            }, redirect.timer);
            return () => clearTimeout(timeoutId);
        }
    }, []);

    return (
        <div>
            <div id={"SSR_page404NotFound"} />
            <NotFound redirect={redirect} />
        </div>
    );
}
