import clsx from "clsx";
import styles from "main/actionLogo/_css/newsletterLogo.module.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import newsletter from "../../assets/images/newsletter.svg";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { emailsStore } from "emails/emailsStore";
import { userStore } from "users/_stores/userStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { isEmail } from "_common/_utils/coreUtils";

type Props = {
    openWithDelay?: boolean;
    onClose?: () => void;
};

export function NewsletterDialog(props: Props) {
    const [open, setOpen] = useState(!props.openWithDelay);
    const [email, setEmail] = useState("");
    const { t } = useTranslation();
    useEffect(() => {
        if (props.openWithDelay) {
            const timeoutId = setTimeout(() => {
                setOpen(userStore.displayNewsletterDialog());
            }, 15000);
            return () => clearTimeout(timeoutId);
        }
    }, []);

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (props.onClose) props.onClose();
                else setOpen(false);
            }}
            className={"flex_center_center"}
            closeCross
        >
            <div className={clsx(styles.innerContent, "p_20 flex_column alignItems_center textAlign_center")}>
                <img src={newsletter} alt={"newsletter"} className={clsx(styles.img, "p_20")} />
                <h2>{t("newsletter.title")}</h2>
                <div className={"mh_20"}>{t("newsletter.desc")}</div>
                <div className={clsx(styles.frame, "mv_20 flex_row_center position_relative")}>
                    <input
                        className={clsx("p_20", styles.emailFrame)}
                        type={"email"}
                        id={"email"}
                        name={"email"}
                        placeholder={t("newsletter.email")}
                        onChange={(event) => setEmail(event.target.value)}
                        autoFocus
                    />
                    <button
                        type="submit"
                        className={clsx(styles.button, "mr_5 position_absolute", {
                            [styles.disabledButton]: !isEmail(email),
                        })}
                        onClick={() => {
                            emailsStore.subscribeNewsletter(email).then(
                                () => {
                                    toast.success(t("newsletter.success"));
                                    userStore.setIsSubscribed(true);
                                    if (props.onClose) props.onClose();
                                    else setOpen((prevState) => !prevState);
                                },
                                (e) => {
                                    toast.error(getErrorMessage(e));
                                },
                            );
                        }}
                        disabled={!isEmail(email)}
                    >
                        <i className={clsx("icon-send", styles.emailLogo)} />
                    </button>
                </div>
            </div>
        </Dialog>
    );
}
