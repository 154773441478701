import { TComponentsConfigs } from "components/ComponentRenderer";
import { SectionTitleComponent } from "components/sections/sectionTitle/SectionTitleComponent";
import { SectionComponent } from "components/sections/section/SectionComponent";
import { TwoColsComponent } from "components/sections/twoColumnsSection/TwoColsComponent";
import { ProductsComponent } from "components/sections/productList/productsComponent";
import { ProductHighlighted } from "components/sections/productHighligthed/ProductHighlighted";

export const SECTIONS_COMPONENTS_CONFIGS: TComponentsConfigs = {
    section: { component: SectionComponent },
    sectionTitle: { component: SectionTitleComponent },
    twoCols: { component: TwoColsComponent },
    productList: { component: ProductsComponent },
    productHighlighted: { component: ProductHighlighted },
};
