import { action, computed, extendObservable, observable } from "mobx";
import { IListProvider } from "shared/_common/list/IListProvider";
import { LoadingStateMdl } from "shared/_common/loaders/_models/LoadingStateMdl";
import { createLoadingStateFromPromise } from "shared/_common/loaders/loadingStateUtils";
import { ObservableArray } from "mobx/lib/types/observablearray";
import { TFilter } from "admin/_common/filters/TFilter";
import _ from "lodash";
import { TObjWithId } from "_common/types/GenericTypes";
import { filtersByProductOption } from "_common/_utils/productUtils";
import { valueToLowerCase } from "_common/_utils/filterUtils";

export type TListStoreFilter = TFilter & {
    hidden?: boolean;
};
export class ListStore<T extends TObjWithId> {
    @observable items: (T | undefined)[] = [];
    @observable count: number | undefined;
    @observable selectedPage: number;
    @observable pageSize: number;
    @observable pageSizeList: number[] = [2, 5, 10, 20, 50];
    @observable sort: { [key: string]: number } | undefined;
    @observable filters: TListStoreFilter[] = [];

    readonly listId: string;
    @observable private loadingStates: {
        [page: number]: LoadingStateMdl<{ count: number; items: T[] }>;
    } = {};
    private readonly listProvider: IListProvider<T>;
    private resetAndReload = _.debounce(
        action(() => {
            this.reset();
            this.setSelectedPage(1);
        }),
        1000,
    );

    constructor(
        listId: string,
        listProvider: IListProvider<T>,
        initialState?: { count: number; pages: { [offset: number]: T[] } },
        pageSize?: number,
        noInitialLoading?: boolean,
        initialFilters?: TListStoreFilter[],
        sort?: { [key: string]: number },
    ) {
        this.listId = listId;
        this.listProvider = listProvider;
        this.selectedPage = 1;
        this.pageSize = pageSize ?? 20;
        if (initialState) this.init(initialState);
        if (initialFilters && initialFilters.length > 0) this.filters = initialFilters;
        if (sort) this.sort = sort;
        if (!noInitialLoading) this.load();
    }

    @computed get paginatedItems() {
        const offset = (this.selectedPage - 1) * this.pageSize;
        return this.items.slice(offset, offset + this.pageSize);
    }

    @computed get filteredItems() {
        const filtersByOptions = Object.values(filtersByProductOption(this.filters));
        let filteredItems = [...this.items];
        filtersByOptions.forEach((optionFilters) => {
            filteredItems = filteredItems.filter((item) => {
                return !!optionFilters.find((filter) => {
                    const itemValue = _.get(item, filter.id);
                    if (!itemValue || !filter.value) return undefined;
                    switch (filter.op) {
                        case "eq":
                            return valueToLowerCase(itemValue) === valueToLowerCase(filter.value);
                        case "gte":
                            return itemValue >= filter.value;
                        case "lte":
                            return itemValue <= filter.value;
                        case "minMax":
                            return itemValue >= filter.value[0] && itemValue <= filter.value[1];
                        case "in":
                            return (itemValue as any[]).some(
                                (value) => (filter.path ? value.localized?.wordUrl : value) === filter.value,
                                //TODO Rendre générique (valeur à comparer dépendant de filter.path)
                            );
                    }
                });
            });
        });
        if (this.sort) {
            const keyValue: any = Object.entries(this.sort)[0];
            ((filteredItems as unknown) as (T & {
                [key: string]: any;
            })[]).sort((a: T & { [key: string]: any }, b: T & { [key: string]: any }) =>
                _.get(a, keyValue[0]) < _.get(b, keyValue[0]) ? keyValue[1] * -1 : keyValue[1] * 1,
            );
        }
        return filteredItems;
    }

    @computed get currentLoadingState() {
        return this.loadingStates[this.selectedPage];
    }

    @computed get hasMorePage() {
        return this.count === undefined || this.items.length < this.count;
    }

    @action load(page: number = this.selectedPage) {
        if (!this.loadingStates[page] || this.loadingStates[page].status === "IDLE") {
            const offset = (page - 1) * this.pageSize;
            if (this.items[offset]) {
                extendObservable(this.loadingStates, {
                    [page]: new LoadingStateMdl("SUCCEEDED"),
                }); //TODO set value in loading state
            } else {
                const loadPromise = this.listProvider
                    .list(offset, this.pageSize, this.listId, this.sort, this.filters)
                    .then(
                        action((result) => {
                            const { count, items } = result;
                            this.count = count;
                            if (offset > this.items.length) {
                                this.items.push(...new Array(offset - this.items.length).fill(undefined));
                            }
                            for (let i = 0; i < items.length; i++) {
                                this.items[offset + i] = items[i];
                            }
                            this.loadingStates[page].setSuccess(result);
                            return result;
                        }),
                        (err) => this.loadingStates[page].setError(err),
                    );
                if (!this.loadingStates[page]) {
                    extendObservable(this.loadingStates, {
                        [page]: createLoadingStateFromPromise(loadPromise),
                    });
                } else {
                    this.loadingStates[page].startLoading(loadPromise as any);
                }
            }
        }
        return this.loadingStates[page];
    }

    getLoadingState(page: number) {
        if (!this.loadingStates[page]) {
            this.loadingStates[page] = new LoadingStateMdl<{ count: number; items: T[] }>();
        }
        return this.loadingStates[page];
    }

    getSync(itemId: string) {
        return this.items.find((item) => (item as any)?._id === itemId);
    }

    hasFilter(newFilter: TListStoreFilter) {
        return !!this.filters.find(
            (filter) =>
                filter.id === newFilter.id && valueToLowerCase(filter.value) === valueToLowerCase(newFilter.value),
        );
    }

    @action setPageSize(pageSize: number) {
        this.pageSize = pageSize;
        this.resetAndReload();
    }

    @action setSelectedPage(page: number) {
        this.selectedPage = page;
        return this.load(page);
    }

    @action setSort(sort: { [key: string]: number }) {
        this.sort = sort;
        this.resetAndReload();
    }

    @action updateSort(sort: { [key: string]: number }) {
        this.sort = sort;
    }

    getFilterIndex(filterId: string, value?: any) {
        return this.filters.findIndex(
            (filter) => filter.id === filterId && (value === undefined || filter.value === value),
        );
    }

    @action updateFilter(filter: TListStoreFilter, index?: number) {
        if (index === undefined || this.filters.find((currentFilter) => currentFilter.id === filter.id) === undefined) {
            this.filters.push(filter);
        } else this.filters.splice(index, 1, filter);
    }

    @action updateFilterAndReload(filter: TListStoreFilter, index?: number) {
        this.updateFilter(filter, index);
        this.resetAndReload();
    }

    @action removeFilters() {
        this.filters.splice(0, this.filters.length);
    }

    @action removeFilter(index: number) {
        this.filters.splice(index, 1);
    }

    @action removeFilterAndReload(index: number) {
        this.removeFilter(index);
        this.resetAndReload();
    }

    @action reset() {
        this.loadingStates = {};
        ((this.items as unknown) as ObservableArray<void>).clear();
    }

    reload() {
        this.reset();
        this.load();
    }

    @action onCreate(item: T) {
        if (this.items.find((listItem) => listItem?._id === item._id)) {
            this.onUpdate(item);
            return;
        }
        this.items.unshift(item);
    }

    @action onUpdate(updatedItem: T) {
        this.items.splice(
            this.items.findIndex((item) => item?._id === updatedItem._id),
            1,
            updatedItem,
        );
    }

    @action onDelete(itemId: string) {
        this.items.splice(
            this.items.findIndex((item) => item?._id === itemId),
            1,
        );
    }

    @action swapItems(id1: string, id2: string) {
        const _items = [...this.items];
        const index1 = _items.findIndex((item) => item?._id === id1);
        const index2 = _items.findIndex((item) => item?._id === id2);
        const item1 = _items[index1];
        const item2 = _items[index2];
        _items[index1] = item2;
        _items[index2] = item1;
        this.items = _items;
    }

    private init(initialState: { count: number; pages: { [offset: number]: T[] } }) {
        this.count = initialState.count;
        Object.keys(initialState.pages)
            .map(Number)
            .forEach((offset) => {
                const page = offset + 1;
                const items = initialState.pages[offset];
                this.items.splice(offset, items.length, ...items);
                this.loadingStates[page] = new LoadingStateMdl("SUCCEEDED");
            });
    }
}
