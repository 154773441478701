import React from "react";
import { ProductReviewsList } from "products/reviews/ProductReviewsList";
import { ProductReviewsAdd } from "products/reviews/ProductReviewsAdd";
import { Grid } from "@material-ui/core";
import styles from "./_css/productReviews.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ProductReviewsGlobalMark from "products/reviews/ProductReviewsGlobalMark";

export function ProductReviews() {
    const { t } = useTranslation();
    return (
        <Grid container spacing={3} className={clsx(styles.container, "p_20")}>
            <Grid item xs={12}>
                <div className={styles.head}>
                    <div>
                        <h2>{t("product.comments.title")}</h2>
                        <ProductReviewsGlobalMark />
                    </div>
                    <ProductReviewsAdd />
                </div>
                <ProductReviewsList />
            </Grid>
        </Grid>
    );
}
