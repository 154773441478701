import React, { useState } from "react";
import styles from "./_css/newsLetterFloatingBtn.module.css";
import clsx from "clsx";
import { NewsletterDialog } from "main/actionLogo/NewsletterDialog";
import { userStore } from "users/_stores/userStore";
import { NoSsr } from "@material-ui/core";

const NewsLetterFloatingBtn = () => {
    const [open, setOpen] = useState(false);
    if (!userStore.displayNewsletterButton()) return null;
    return (
        <NoSsr>
            <div onClick={() => setOpen(true)} className={clsx(styles.container, "flex_center_center")}>
                <i className={clsx("icon-mail1", styles.icon)}></i>
            </div>
            {open && <NewsletterDialog onClose={() => setOpen(false)} />}
        </NoSsr>
    );
};

export default NewsLetterFloatingBtn;
