import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TCommentMdl } from "comments/_models/CommentMdl";

class CommentsStore extends BaseResourceStore<TCommentMdl> {
    constructor() {
        super("comments");
    }
}

const commentsStore = new CommentsStore();
export { commentsStore };
