import { appConfig } from "_configs/appConfig";
import { affiliationsStore } from "admin/affiliations/_stores/affiliationsStore";

class AffiliationsUtils {
    private queryName = "tag";
    private localStorageKey = "affiliation";
    getAffiliationUrl(name: string) {
        return `${appConfig.basePath}?${this.queryName}=${name.trim().toLowerCase()}`;
    }

    isAlreadyInLocalStorage(name: string) {
        const affiliation = localStorage.getItem(this.localStorageKey);
        if (affiliation) {
            return affiliation === name;
        }
        return false;
    }

    checkAffiliation() {
        return localStorage.getItem(this.localStorageKey);
    }

    async trackPage(search: string) {
        const affiliate = new URLSearchParams(search).get(this.queryName);
        if (!affiliate || this.isAlreadyInLocalStorage(affiliate)) return;
        const trueAffiliation = await affiliationsStore.checkAffiliate(affiliate);

        if (trueAffiliation) {
            localStorage.setItem(this.localStorageKey, affiliate);
        }
    }
}

export const affiliationsUtils = new AffiliationsUtils();
