import { action, computed, observable } from "mobx";
import { getResourceInitialStateValue } from "_common/_utils/initialStateUtils";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { IPageForSearchMdl } from "pages/_models/PageMdl";
import _ from "lodash";

class SearchStore {
    @observable articles: IPageForSearchMdl[];
    @observable products: TProductLocalizedMdl[];
    @observable opened = false;
    @observable search = "";

    @action setSearch = _.debounce((s: string) => {
        this.search = s.trim().toLowerCase();
    }, 400);

    constructor() {
        this.articles = getResourceInitialStateValue("articles")?.items || [];
        this.products = Array.from(new Set(getResourceInitialStateValue("products")?.items)) || [];
    }

    @computed get results() {
        return this.searchResults(this.search);
    }

    @computed get hasResults() {
        return this.results.articles.length > 0 || this.results.products.length > 0;
    }

    @computed get isSearching() {
        return this.opened && this.search.length > 1;
    }

    @action open(opened: boolean) {
        this.opened = opened;
    }

    searchResults(search: string) {
        const articles = this.articles.filter(
            (article) =>
                article.localized.description?.toLowerCase().includes(search) ||
                article.localized.title?.toLowerCase().includes(search),
        );
        const products = this.products.filter(
            (product) =>
                product.localized.description?.toLowerCase().includes(search) ||
                product.localized.name?.toLowerCase().includes(search) ||
                product.sku === this.search,
        );

        return { products, articles };
    }
}

export const searchStore = new SearchStore();
