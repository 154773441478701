export type TObject = {
    [lang in string]: string[];
};
export const messageOBJECTS: TObject = {
    fr: [
        "Demande commerciale",
        "Service après-vente",
        "Demande de réparation hors garantie",
        "Demande d'informations",
        "Demande concernant la livraison",
        "Autre",
    ],
    en: [
        "Commercial request",
        "After-sales service",
        "Out of warranty repair request",
        "Information request",
        "Request regarding delivery",
        "Other",
    ],
};
