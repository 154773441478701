import { PRODUCT_FILTER, PRODUCT_OPTIONS_CONFIG, TProductConfigDef } from "_configs/productConfig";
import { FILTER_TYPE, PRODUCT_TYPE } from "products/_models/ProductMdl";

export const FILTERS_CONFIG: TProductConfigDef[] = [
    ...PRODUCT_OPTIONS_CONFIG,
    {
        key: "brand",
        type: "string",
        required: false,
        onlyString: true,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.ACCESSORY, PRODUCT_TYPE.SPP],
        filter: {
            sort: 1,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.BRAND,
        },
    },
    {
        key: "subType",
        type: "enum",
        enumValues: [{ value: PRODUCT_TYPE.EUC }, { value: PRODUCT_TYPE.SCOOTER }, { value: PRODUCT_TYPE.NONE }],
        required: false,
        onlyString: true,
        types: [PRODUCT_TYPE.ACCESSORY, PRODUCT_TYPE.SPP],
        filter: {
            sort: 4,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.SUB_TYPE,
        },
    },
    {
        key: "price",
        type: "string",
        required: false,
        onlyString: true,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.ACCESSORY, PRODUCT_TYPE.SPP],
        filter: {
            sort: 2,
            filterType: FILTER_TYPE.SLIDER,
            itemPath: PRODUCT_FILTER.PRICE,
        },
    },
    {
        key: "mark",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.SPP, PRODUCT_TYPE.ACCESSORY],
    },
    {
        key: "label",
        type: "in",
        op: "in",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.SPP, PRODUCT_TYPE.ACCESSORY],
    },
];
