import React from "react";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import clsx from "clsx";
import styles from "products/variants/_css/productVariants.module.css";
import { MenuItem } from "@material-ui/core";
import i18next from "i18next";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";

type Props = {
    product: TProductLocalizedMdl;
    onVariantChange: (variantId: string) => void;
    defaultVariantId?: string;
    className?: string;
    fontSize?: number;
    disabled?: boolean;
};

export function ProductVariantPicker(props: Props) {
    if (!props.product.variants) return null;
    return (
        <div>
            <UiSelect
                disabled={props.disabled}
                style={{ fontSize: props.fontSize ? props.fontSize : "inherit" }}
                className={clsx(styles.select, "p_10", props.className)}
                defaultValue={props.defaultVariantId}
                onChange={(e) => props.onVariantChange(e.target.value as string)}
            >
                {props.product.variants.map((variant) => (
                    <MenuItem key={variant._id} value={variant._id}>
                        {variant.localized[i18next.language].name}
                    </MenuItem>
                ))}
            </UiSelect>
        </div>
    );
}
