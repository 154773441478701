import React, { CSSProperties } from "react";
import clsx from "clsx";
import styles from "./_css/newsCardComponent.module.css";
import { TComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-router-dom";

export type NewsCardComponentProps = {
    title?: string;
    date?: Dayjs | string;
    text?: string;
    image?: string;
    url?: string;
    style?: CSSProperties;
    $component?: TComponent;
};

function _NewsCardComponent(props: Omit<NewsCardComponentProps, "url">) {
    const { t } = useTranslation();
    const date = typeof props.date === "string" ? dayjs(props.date) : props.date;
    const displayText = props.title ? props.title.charAt(0) + props.title.substring(1).toLowerCase() : null;
    return (
        <div style={props.style} className={styles.container}>
            <div className={clsx(styles.image, "pt_20")} style={{ backgroundImage: `url(${props.image})` }}>
                {date && <span className={clsx(styles.date, "mh_20")}>{date.format("DD/MM/YYYY")}</span>}
            </div>
            <h2 className={clsx("mt_10 ph_20", styles.title)}>{displayText}</h2>
            <div className={clsx(styles.text, "p_25")}>
                <div className={styles.textGradient} />
                {props.text}
            </div>
            <div className={clsx(styles.readMore, "flex_center_center")}>
                <div className={styles.textGradient} />
                {t("news.seeMore")}
            </div>
        </div>
    );
}

export function NewsCardComponent(props: NewsCardComponentProps) {
    const { url, ...rest } = props;
    if (url && url.startsWith("/")) {
        return (
            <Link to={`/${i18next.language}${url}`}>
                <_NewsCardComponent {...rest} />
            </Link>
        );
    }
    if (url) {
        return (
            <a href={url} rel={"nofollow noopener noreferrer"} target={"_blank"}>
                <_NewsCardComponent {...rest} />
            </a>
        );
    }

    return <_NewsCardComponent {...rest} />;
}
