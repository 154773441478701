import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TExternalArticleMdl } from "externalArticles/_models/externalArticleMdl";

class ExternalArticlesStore extends BaseResourceStore<TExternalArticleMdl> {
    constructor() {
        super("externalArticles");
    }
}

export const externalArticlesStore = new ExternalArticlesStore();
