import React from "react";
import { Chip } from "@material-ui/core";
import { ListStore } from "_common/list/ListStore";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { toJS } from "mobx";
import { useHistory, useLocation } from "react-router";
import { updateSearchParams } from "shop/filtersType/CheckBoxFilter";
import styles from "../_css/labelFilters.module.css";
import clsx from "clsx";

type Props = {
    listStore: ListStore<TProductLocalizedMdl>;
};

const LabelFilters = (props: Props) => {
    const history = useHistory();
    const location = useLocation();
    const labels = props.listStore.items.flatMap((item) => toJS(item?.labels)).filter((label) => label);
    const uniqueLabels = labels.filter(
        (label, index, self) => self.findIndex((l) => l?.localized.text === label?.localized.text) === index,
    );

    const filters = uniqueLabels.map((label) => {
        if (!label) return null;
        return { key: "label", value: label.localized.wordUrl, text: label.localized.text };
    });

    const filterIsSelected = (filterValue: string) => location.search.includes(filterValue);

    const handleClick = (filterKey: string, filterValue: string) => {
        let updatedSearchParams;
        if (filterIsSelected(filterValue)) {
            updatedSearchParams = updateSearchParams(location.search, { key: filterKey, value: filterValue }, true);
        } else {
            updatedSearchParams = updateSearchParams(location.search, { key: filterKey, value: filterValue }, false);
        }

        history.replace(location.pathname + "?" + updatedSearchParams);
    };
    return (
        <div className={clsx(styles.container, "mv_20")}>
            {filters.map((filter) => {
                if (!filter) return null;

                return (
                    <Chip
                        key={filter.value}
                        label={filter.text}
                        onClick={() => handleClick(filter.key, filter.value)}
                        color={filterIsSelected(filter.value) ? "primary" : undefined}
                    />
                );
            })}
        </div>
    );
};

export default LabelFilters;
