import React from "react";
import { ProductReviewForm } from "products/reviews/ProductReviewForm";
import { ProductReviewAddBtn } from "products/reviews/ProductReviewAddBtn";
import { TReviewEditionMdl } from "products/_models/ProductMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "products/reviews/_css/reviewInModal.module.css";
import { userStore } from "users/_stores/userStore";
import { InputBlock } from "_common/ui/forms/InputBlock";
import i18next from "i18next";
import { Input } from "_common/ui/forms/Input";

type Props = {
    review: TReviewEditionMdl;
    setReview: (review: TReviewEditionMdl) => void;
    setIsOpening: (opened: boolean) => void;
};

export function ReviewInModal(props: Props) {
    const { t } = useTranslation();
    const user = !userStore.isLogged;
    return (
        <div className={clsx("mh_20 mb_20", styles.dialog)}>
            <h3 className="mb_20">{t("product.review.giveReview")}</h3>
            {user && (
                <div className="flex_row">
                    <InputBlock className="mr_20" label={i18next.t("words.firstName")}>
                        <Input
                            value={props.review.user?.firstName}
                            onChange={(e) => {
                                props.setReview({
                                    ...props.review,
                                    user: {
                                        ...JSON.parse(JSON.stringify(props.review.user)),
                                        firstName: e.target.value,
                                    },
                                });
                            }}
                        />
                    </InputBlock>
                    <InputBlock label={i18next.t("words.lastName")}>
                        <Input
                            value={props.review.user?.lastName}
                            onChange={(e) => {
                                props.setReview({
                                    ...props.review,
                                    user: {
                                        ...JSON.parse(JSON.stringify(props.review.user)),
                                        lastName: e.target.value,
                                    },
                                });
                            }}
                        />
                    </InputBlock>
                </div>
            )}
            <ProductReviewForm review={props.review} onReviewChange={(review) => props.setReview(review)} />
            <ProductReviewAddBtn
                review={props.review}
                onReviewChange={(review) => props.setReview(review)}
                setIsOpening={(opened) => props.setIsOpening(opened)}
            />
        </div>
    );
}
