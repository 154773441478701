import React, { useState } from "react";
import clsx from "clsx";
import styles from "./_css/servicesDescription.module.css";
import creditCard from "../../../shared/assets/images/credit-card.svg";
import shipping from "../../../shared/assets/images/delivery.svg";
import warranty1y from "../../../shared/assets/images/warranty1y.svg";
import warranty2y from "../../../shared/assets/images/warranty2y.svg";
import warranty6m from "../../../shared/assets/images/warranty6m.svg";
import funding from "../../../shared/assets/images/funding.svg";
import { useTranslation } from "react-i18next";
import { WARRANTY_DURATIONS } from "_configs/warrantyConfig";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { FundingModal } from "products/funding/FundingModal";

type Props = {
    dark?: boolean;
    warranty?: WARRANTY_DURATIONS;
};

export function ServicesDescription({ dark, warranty }: Props) {
    const { t } = useTranslation();
    const [isFundingModalOpen, setIsFundingModalOpen] = useState(false);
    const toggleFundingModal = () => setIsFundingModalOpen((prevState) => !prevState);

    let warrantySrc = "";

    switch (warranty) {
        case undefined:
        case WARRANTY_DURATIONS.NONE:
            break;
        case WARRANTY_DURATIONS.SIX_MONTHS:
            warrantySrc = warranty6m;
            break;
        case WARRANTY_DURATIONS.ONE_YEAR:
            warrantySrc = warranty1y;
            break;
        case WARRANTY_DURATIONS.TWO_YEARS:
            warrantySrc = warranty2y;
            break;
    }

    return (
        <>
            <div
                className={clsx("flex_row_center pv_20", styles.container, styles.defaultTheme, {
                    [styles.darkTheme]: dark,
                })}
            >
                <div className={clsx(styles.icon)}>
                    <img src={shipping} alt="shipping icon" />
                    <p>{t("product.page.shipping")}</p>
                </div>
                {warrantySrc ? (
                    <div className={clsx(styles.icon)}>
                        <img src={warrantySrc} alt="warranty icon" />
                        <p>{t("product.page.warranty", { duration: t(`product.options.warranty.${warranty}`) })}</p>
                    </div>
                ) : (
                    <div className={clsx(styles.icon, styles.clickable)} onClick={toggleFundingModal}>
                        <img src={funding} alt="funding icon" />
                        <p>{t("product.page.funding")}</p>
                    </div>
                )}
                <div className={clsx(styles.icon)}>
                    <img src={creditCard} alt="creditCard icon" />
                    <p>{t("product.page.credit")}</p>
                </div>
            </div>
            <Dialog
                closeCross={true}
                closeSize={"small"}
                open={isFundingModalOpen}
                onClose={() => setIsFundingModalOpen(false)}
            >
                <FundingModal setIsOpening={() => setIsFundingModalOpen(true)} />
            </Dialog>
        </>
    );
}
