import React from "react";
import { TOrderListingMdl } from "orders/_models/OrderMdl";
import { UserInfoSummary } from "users/profile/info/userInfo/UserInfoSummary";

type Props = {
    order?: TOrderListingMdl;
};

export function OrderDocumentCustomerInfo(props: Props) {
    if (!props.order) return null;
    return <UserInfoSummary user={props.order?.customer} />;
}
