import React, { useCallback } from "react";
import { Grid } from "@material-ui/core";
import styles from "./_css/productGallery.module.css";
import { useProductStore } from "products/ProductContext";
import ProductGalleryPreviewSlider from "products/gallery/ProductGalleryPreviewSlider";

export function ProductGallery() {
    const productStore = useProductStore();
    const openGallery = useCallback(
        (index: number) => {
            productStore.openGallery(index);
        },
        [productStore],
    );
    if (!productStore.product || !productStore.product.photos || productStore.product.photos.length === 0) return null;
    return (
        <Grid className={styles.container} container>
            <Grid item xs={12}>
                <ProductGalleryPreviewSlider photos={productStore.product.photos} onClick={openGallery} />
            </Grid>
            {productStore.product.photos.length > 1 && (
                <>
                    <Grid item xs={12} className={styles.bottomBorder} />
                    {productStore.product.photos[1] && (
                        <Grid item xs={4} onClick={() => productStore.openGallery(1)}>
                            <div
                                className={styles.secondaryPhoto}
                                style={{ backgroundImage: `url(${productStore.product.photos[1].url})` }}
                            />
                        </Grid>
                    )}
                    {productStore.product.photos[2] && (
                        <Grid item xs={4} onClick={() => productStore.openGallery(2)}>
                            <div
                                className={styles.secondaryPhoto}
                                style={{ backgroundImage: `url(${productStore.product.photos[2].url})` }}
                            />
                        </Grid>
                    )}
                    {productStore.product.photos[3] && (
                        <Grid item xs={4} onClick={() => productStore.openGallery(3)}>
                            <div
                                className={styles.secondaryPhoto}
                                style={{ backgroundImage: `url(${productStore.product.photos[3].url})` }}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}
