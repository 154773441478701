import React from "react";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { ordersStore } from "orders/_stores/ordersStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { OrderViewContent } from "orders/OrderViewContent";
import { PageNotFound } from "pages/PageNotFound";

type Props = {
    orderId?: string;
    token?: string;
    isNotOnDocumentPage?: boolean;
};

export function OrderView(props: Props) {
    const { loading } = useLoadingFromPromise(
        props.token
            ? ordersStore.getByToken(props.token)
            : props.orderId
            ? ordersStore.getById(props.orderId)
            : undefined,
    );
    if (!loading) return <PageNotFound />;
    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, _error, value) => {
                if (!value) return <PageNotFound />;
                const order = value.data;
                return <OrderViewContent isNotOnDocumentPage={props.isNotOnDocumentPage} order={order} />;
            }}
        />
    );
}
