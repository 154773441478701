import { i18nextInstance } from "_common/i18n/IntlProvider";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

export const URLS = {
    home: (lang?: string) => `/${lang || i18nextInstance.language}`,

    auth: {
        base: (lang?: string) => `/${lang || i18nextInstance.language}/auth`,
        signIn: (lang?: string) => `/${lang || i18nextInstance.language}/auth/signIn`,
        signUp: (lang?: string) => `/${lang || i18nextInstance.language}/auth/signUp`,
        askResetPassword: (lang?: string) => `/${lang || i18nextInstance.language}/auth/askResetPassword`,
        resetPassword: (lang?: string) => `/${lang || i18nextInstance.language}/auth/resetPassword`,
        verified: (lang?: string) => `/${lang || i18nextInstance.language}/auth/verify`,
    },

    user: {
        profile: (tab?: string, lang?: string) =>
            `/${lang || i18nextInstance.language}/${
                lang
                    ? getI18nExpByLang(lang, "routes.profile") ?? i18nextInstance.t("routes.profile")
                    : i18nextInstance.t("routes.profile")
            }/${tab ?? ""}`,
        orders: (lang?: string) =>
            `/${lang || i18nextInstance.language}/${
                lang
                    ? getI18nExpByLang(lang, "routes.orders") ?? i18nextInstance.t("routes.orders")
                    : i18nextInstance.t("routes.orders")
            }`,
        reviews: (lang?: string) =>
            `/${lang || i18nextInstance.language}/${
                lang
                    ? getI18nExpByLang(lang, "routes.reviews") ?? i18nextInstance.t("routes.reviews")
                    : i18nextInstance.t("routes.reviews")
            }`,
    },
    order: (tokenOrOrderId: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${
            lang
                ? getI18nExpByLang(lang, "routes.order") ?? i18nextInstance.t("routes.order")
                : i18nextInstance.t("routes.order")
        }/${tokenOrOrderId}`,

    about: (lang?: string) =>
        `/${lang || i18nextInstance.language}/${
            lang
                ? getI18nExpByLang(lang, "routes.aboutUs") ?? i18nextInstance.t("routes.aboutUs")
                : i18nextInstance.t("routes.aboutUs")
        }`,

    shop: (productTypeLabel?: string, brand?: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${
            lang
                ? getI18nExpByLang(lang, "routes.shop") ?? i18nextInstance.t("routes.shop")
                : i18nextInstance.t("routes.shop")
        }/${productTypeLabel ? productTypeLabel + "/" : ""}${brand ?? ""}`,
    cart: (lang?: string) =>
        `/${lang || i18nextInstance.language}/${
            lang
                ? getI18nExpByLang(lang, "routes.cart") ?? i18nextInstance.t("routes.cart")
                : i18nextInstance.t("routes.cart")
        }`,

    product: (aliasUrl: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${
            lang
                ? getI18nExpByLang(lang, "routes.product") ?? i18nextInstance.t("routes.product")
                : i18nextInstance.t("routes.product")
        }/${aliasUrl}`,

    special: (wordLabel: string, lang?: string) => `/${lang || i18nextInstance.language}/special/${wordLabel}`,
    giftCard: (lang?: string) =>
        `/${lang || i18nextInstance.language}/${
            lang
                ? getI18nExpByLang(lang, "routes.giftcard") ?? i18nextInstance.t("routes.giftcard")
                : i18nextInstance.t("routes.giftcard")
        }`,
    initiation: (lang?: string) =>
        `/${lang || i18nextInstance.language}/${
            lang
                ? getI18nExpByLang(lang, "routes.initiation") ?? i18nextInstance.t("routes.initiation")
                : i18nextInstance.t("routes.initiation")
        }`,
    search: (lang?: string) => {
        return `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.search")}`;
    },

    admin: () => "/noaio-admin",
};
