import React from "react";
import styles from "./_css/homepagePartners.module.css";
import { useTranslation } from "react-i18next";
import { PartnerCardComponent } from "components/misc/partners/partnerCard/PartnerCardComponent";
import { Grid } from "@material-ui/core";
import partner1 from "./assets/partner1.png";
import partner2 from "./assets/partner2.png";
import partner3 from "./assets/partner3.png";

const PARTNERS_OPINIONS = [
    {
        key: "1",
        image: partner1,
    },
    {
        key: "2",
        image: partner2,
    },
    {
        key: "3",
        image: partner3,
    },
];
const HomepagePartners = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h3>{t("homepage.partners.title")}</h3>
            <Grid container spacing={2}>
                {PARTNERS_OPINIONS.map((partner) => {
                    return (
                        <Grid item xs={12} md={6} lg={4} key={partner.key}>
                            <PartnerCardComponent
                                image={partner.image}
                                $component={{ id: partner.key }}
                                text={t(`homepage.partners.${partner.key}.text`)}
                                author={t(`homepage.partners.${partner.key}.author`)}
                                mark={"★★★★★"}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export default HomepagePartners;
