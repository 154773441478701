import React from "react";
import { Route, Switch } from "react-router";
import { AuthenticatedRoute } from "_common/_utils/AuthenticatedRoute";
import { URLS } from "_configs/URLS";
import { ProfilePage } from "users/profile/ProfilePage";
import { PageRegular } from "pages/PageRegular";
import { Shop } from "shop/Shop";
import { ProductPage } from "products/ProductPage";
import { OrdersPage } from "orders/OrdersPage";
import { ScrollTop } from "_common/_utils/ScrollTop";
import { NoSsr } from "@material-ui/core";
import { CartPage } from "cart/CartPage";
import { ReviewsPage } from "products/reviews/ReviewsPage";
import { SpecialPage } from "special/SpecialPage";
import { GiftCardPage } from "giftCards/GiftCardPage";
import { PageRouter } from "pages/PageRouter";
import InitiationPage from "initiation/InitiationPage";
import SearchPage from "main/menu/search/SearchPage";

export function AppContent() {
    return (
        <PageRegular>
            <Switch>
                <AuthenticatedRoute path={URLS.user.profile(":tab?", ":lang")} component={ProfilePage} />
                <AuthenticatedRoute path={URLS.user.orders(":lang")} component={OrdersPage} />
                <AuthenticatedRoute path={URLS.user.reviews(":lang")} component={ReviewsPage} />
                <Route path={URLS.product(":aliasUrl", ":lang")} component={ProductPage} />
                <Route path={URLS.shop(":productTypeLabel", ":brand?", ":lang")} component={Shop} />
                <Route path={URLS.cart(":lang")} component={CartPage} />
                <Route path={URLS.special(":labelName", ":lang")} component={SpecialPage} />
                <Route path={URLS.giftCard(":lang")} component={GiftCardPage} />
                <Route path={URLS.initiation(":lang")} component={InitiationPage} />
                <Route path={URLS.search(":lang")} component={SearchPage} />
                <Route path="/" component={() => <PageRouter />} />
            </Switch>
            <NoSsr>
                <ScrollTop />
            </NoSsr>
        </PageRegular>
    );
}
