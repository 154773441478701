import React from "react";
import styles from "cart/_css/cartPaymentMethod.module.css";
import visa from "../assets/images/visa.png";
import mastercard from "../assets/images/mastercard.png";
import square from "../assets/images/square.png";

export function CartPaymentMethod() {
    return (
        <div className={"flex_center_center justifyContent_spaceBetween mv_20"}>
            <img src={visa} alt={"logo visa"} className={styles.imgVisa} />
            <img src={mastercard} alt={"logo mastercard"} className={styles.imgMC} />
            <img src={square} alt={"logo square"} className={styles.imgSquare} />
        </div>
    );
}
