import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
    isEditing?: boolean;
    onStopEditing?: () => void;
};

export function UserInfoNotifications(props: Props) {
    const form = useFormContext();
    const { t } = useTranslation();
    return (
        <div>
            <div className={"flex_row_center"}>
                <Controller
                    disabled={!props.isEditing}
                    control={form.control}
                    as={Checkbox}
                    name="notification"
                    defaultValue={true}
                />
                {t("auth.signUp.notification")}
            </div>
        </div>
    );
}
