import React from "react";
import { Grid } from "@material-ui/core";
import { TCommentMdl } from "comments/_models/CommentMdl";
import { Comment } from "./Comment";
import { userStore } from "users/_stores/userStore";

type Props = {
    comments: TCommentMdl[];
    pageId: string;
};

export function CommentsList(props: Props) {
    const user = userStore.user;

    return (
        <Grid container spacing={2}>
            <h2 className="mb_10 pl_10">Commentaires</h2>
            {props.comments.map((comment) => {
                if (comment.pageId === props.pageId) {
                    if ((user && comment.user && user?._id === comment.user._id) || userStore.isAdmin) {
                        return <Comment key={comment._id} comment={comment} isAdminOrOwner />;
                    } else {
                        return <Comment key={comment._id} comment={comment} isAdminOrOwner={false} />;
                    }
                }
            })}
        </Grid>
    );
}
