import React from "react";
import { TProductEditionMdl, TReviewEditionMdl } from "products/_models/ProductMdl";
import { Review } from "products/reviews/Review";

type Props = {
    reviews: TReviewEditionMdl[];
    products: TProductEditionMdl[];
};

export function ReviewList(props: Props) {
    return (
        <div>
            {props.reviews.map((review, idx) => (
                <Review key={review._id} review={review} product={props.products[idx]} />
            ))}
        </div>
    );
}
