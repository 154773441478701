export const ADMIN_PATH = "/noaio-admin";

export const ADMIN_URLS = {
    home: () => ADMIN_PATH,

    users: {
        base: () => `${ADMIN_PATH}/users`,
        new: () => `${ADMIN_PATH}/users/edit`,
        edit: (id: string) => `${ADMIN_PATH}/users/edit/${id}`,
    },

    pages: {
        base: () => `${ADMIN_PATH}/pages`,
        new: () => `${ADMIN_PATH}/pages/edit`,
        edit: (id: string) => `${ADMIN_PATH}/pages/edit/${id}`,
    },

    products: {
        base: () => `${ADMIN_PATH}/products`,
        new: () => `${ADMIN_PATH}/products/edit`,
        edit: (id: string) => `${ADMIN_PATH}/products/edit/${id}`,
    },

    orders: {
        base: () => `${ADMIN_PATH}/orders`,
        new: () => `${ADMIN_PATH}/orders/edit`,
        edit: (id: string) => `${ADMIN_PATH}/orders/edit/${id}`,
    },

    giftCards: {
        base: () => `${ADMIN_PATH}/giftCards`,
        new: () => `${ADMIN_PATH}/giftCards/edit`,
        edit: (id: string) => `${ADMIN_PATH}/giftCards/edit/${id}`,
    },

    payments: {
        base: () => `${ADMIN_PATH}/payments`,
        new: () => `${ADMIN_PATH}/payments/edit`,
        edit: (id: string) => `${ADMIN_PATH}/payments/edit/${id}`,
    },

    brands: {
        base: () => `${ADMIN_PATH}/brands`,
        new: () => `${ADMIN_PATH}/brands/edit`,
        edit: (id: string) => `${ADMIN_PATH}/brands/edit/${id}`,
    },

    slideShows: {
        base: () => `${ADMIN_PATH}/slideShows`,
        new: () => `${ADMIN_PATH}/slideShows/edit`,
        edit: (id: string) => `${ADMIN_PATH}/slideShows/edit/${id}`,
    },

    settings: {
        base: () => `${ADMIN_PATH}/settings`,
    },

    menu: {
        base: () => `${ADMIN_PATH}/menu`,
    },

    dashboard: {
        base: () => `${ADMIN_PATH}/dashboard`,
    },

    comments: {
        base: () => `${ADMIN_PATH}/comments`,
        edit: (id: string) => `${ADMIN_PATH}/comments/edit/${id}`,
    },
    labels: {
        base: () => `${ADMIN_PATH}/labels`,
        edit: (id: string) => `${ADMIN_PATH}/labels/edit/${id}`,
        new: () => `${ADMIN_PATH}/labels/edit`,
    },
    affiliation: {
        base: () => `${ADMIN_PATH}/affiliation`,
        infos: (id: string) => `${ADMIN_PATH}/affiliation/infos/${id}`,
        new: () => `${ADMIN_PATH}/affiliation/edit`,
    },
    externalArticles: {
        base: () => `${ADMIN_PATH}/externalArticles`,
        new: () => `${ADMIN_PATH}/externalArticles/edit`,
        edit: (id: string) => `${ADMIN_PATH}/externalArticles/edit/${id}`,
    },
};
