import React from "react";
import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { ComponentRenderer } from "components/ComponentRenderer";
import { PageRendererContext } from "pages/PageContext";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { PageMeta } from "pages/PageMeta";
import { PageTitle } from "_common/ui/pages/PageTitle";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { Container } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { getItemsForBreadCrumb } from "_common/_utils/pageUtils";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { Comments } from "comments/Comments";
import { commentsStore } from "comments/_stores/commentsStore";
import { TCommentMdl } from "comments/_models/CommentMdl";

type Props = {
    page: IPageMdl;
};

export function PageRenderer(props: Props) {
    const localizedPage = props.page.localized[i18nextInstance.language as TLang];
    if (!localizedPage) return null;
    const listStore = commentsStore.getListStore();

    return (
        <PageRendererContext pageRendererStore={new PageRendererStore()}>
            <PageMeta page={props.page} />
            <PageTitle style={{ display: "none" }}>{localizedPage.title}</PageTitle>
            {localizedPage.url !== "/" && (
                <Container>
                    <Breadcrumb
                        currentItem={{ itemLabel: localizedPage.title }}
                        items={getItemsForBreadCrumb(props.page)}
                    />
                </Container>
            )}
            <ComponentRenderer component={localizedPage.component} isRoot />
            {props.page.type === PAGE_TYPE.ARTICLE && props.page.commentsIsDisplayed && (
                <LoadableFromLoading
                    loading={listStore.load()}
                    renderer={(_status, error) => {
                        if (error) return null;
                        return (
                            <Container>
                                <div className="mt_40">
                                    <Comments comments={listStore.items as TCommentMdl[]} pageId={props.page._id} />
                                </div>
                            </Container>
                        );
                    }}
                />
            )}
        </PageRendererContext>
    );
}
