export enum REGIONS {
    ALBERTA = "alberta",
    BRITISH_COLUMBIA = "britishColumbia",
    MANITOBA = "manitoba",
    NEW_BRUNSWICK = "newBrunswick",
    NEWFOUNDLAND_AND_LABRADOR = "newfoundlandAndLabrador",
    NORTHWEST_TERRITORIES = "northwestTerritories",
    NOVA_SCOTIA = "novaScotia",
    NANUVUT = "nunavut",
    ONTARIO = "ontario",
    PRINCE_EDWARD_ISLAND = "princeEdwardIsland",
    QUEBEC = "quebec",
    SASKATCHEWAN = "saskatchewan",
    YUKON = "yukon",
}

export const TAX_CONFIG = [
    {
        labelKey: REGIONS.ALBERTA,
        tax: [
            {
                taxName: "TPS",
                rate: 5,
            },
        ],
    },
    {
        labelKey: REGIONS.BRITISH_COLUMBIA,
        tax: [
            {
                taxName: "TVP",
                rate: 7,
            },
            {
                taxName: "TPS",
                rate: 5,
            },
        ],
    },
    {
        labelKey: REGIONS.MANITOBA,
        tax: [
            {
                taxName: "TVP",
                rate: 7,
            },
            {
                taxName: "TPS",
                rate: 5,
            },
        ],
    },
    {
        labelKey: REGIONS.NEW_BRUNSWICK,
        tax: [
            {
                taxName: "TVH",
                rate: 15,
            },
        ],
    },
    {
        labelKey: REGIONS.NEWFOUNDLAND_AND_LABRADOR,
        tax: [
            {
                taxName: "TVH",
                rate: 15,
            },
        ],
    },
    {
        labelKey: REGIONS.NORTHWEST_TERRITORIES,
        tax: [
            {
                taxName: "TPS",
                rate: 5,
            },
        ],
    },
    {
        labelKey: REGIONS.NOVA_SCOTIA,
        tax: [
            {
                taxName: "TVH",
                rate: 15,
            },
        ],
    },
    {
        labelKey: REGIONS.NANUVUT,
        tax: [
            {
                taxName: "TPS",
                rate: 5,
            },
        ],
    },
    {
        labelKey: REGIONS.ONTARIO,
        tax: [
            {
                taxName: "TVH",
                rate: 13,
            },
        ],
    },
    {
        labelKey: REGIONS.PRINCE_EDWARD_ISLAND,
        tax: [
            {
                taxName: "TVH",
                rate: 15,
            },
        ],
    },
    {
        labelKey: REGIONS.QUEBEC,
        tax: [
            {
                taxName: "TPS",
                rate: 5,
            },
            {
                taxName: "TVQ",
                rate: 9.975,
            },
        ],
    },
    {
        labelKey: REGIONS.SASKATCHEWAN,
        tax: [
            {
                taxName: "TVP",
                rate: 6,
            },
            {
                taxName: "TPS",
                rate: 5,
            },
        ],
    },
    {
        labelKey: REGIONS.YUKON,
        tax: [
            {
                taxName: "TPS",
                rate: 5,
            },
        ],
    },
];
