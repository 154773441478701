import React, { useState } from "react";
import { productsStore } from "products/_stores/productsStore";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ShopFilters } from "shop/filter/ShopFilters";
import { useProductTypeFromParams } from "_common/_utils/hookUtils";
import styles from "./_css/shopPage.module.css";
import { panelStore } from "main/menu/panel/_stores/panelStore";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import clsx from "clsx";
import { ShopList } from "shop/ShopList";
import { Meta } from "_common/_utils/Meta";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { useHistory, useLocation } from "react-router";
import NewsLetterFloatingBtn from "components/misc/newsLetter/NewsLetterFloatingBtn";
import LabelFilters from "shop/filter/LabelFilters";
import { SortingShop } from "shop/sort/SortingShop";
import { PageNotFound } from "pages/PageNotFound";
import { URLS } from "_configs/URLS";

export function ShopPage() {
    const productType = useProductTypeFromParams();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [isFilterPanelOpened, setIsFilterPanelOpened] = useState(false);

    const listStore = productsStore.getListStore(productType);
    const brandParam = location.pathname.split("/")[4];

    let title: string | undefined;
    let description: string | undefined;

    if (productType === "EUC" || productType === "SCOOTER") {
        title = t(`meta.${productType}.title`);
        description = t(`meta.${productType}.description`);
    }

    if (brandParam === "undefined") {
        history.push(location.pathname.substr(0, location.pathname.length - 10));
    }
    const brandsList = listStore.items.map((item) => item?.brand?.label?.toLowerCase());
    const is404 = !productType || (brandParam && !brandsList.includes(brandParam));
    if (is404) {
        return <PageNotFound redirect={{ link: URLS.home(), timer: 5000 }} />;
    }
    return (
        <Container>
            <NewsLetterFloatingBtn />
            <Meta url={location.pathname} title={title} description={description} />
            <Breadcrumb currentItem={{ itemLabelKey: "breadCrumb." + productType }} />
            <Grid container spacing={2} className="mt_20">
                <Grid item xs={12} sm={12} md={2}>
                    <div className={clsx("flex_row", styles.shopFilterDesktop)}>
                        <ShopFilters className="flex-1" listStore={listStore} productType={productType} />
                    </div>
                    <div className={styles.shopFiltersMobile}>
                        <UiButton
                            variant={"contained"}
                            color={"primary"}
                            onClick={() =>
                                panelStore.open({
                                    children: (
                                        <ShopFilters
                                            className="flex-1 mt_20 p_20"
                                            listStore={listStore}
                                            productType={productType}
                                        />
                                    ),
                                    size: "large",
                                    opened: true,
                                    onClose: () => setIsFilterPanelOpened(!isFilterPanelOpened),
                                })
                            }
                        >
                            {t("shop.filters")}
                        </UiButton>
                    </div>
                </Grid>
                <Grid className="mb_20" item xs={12} sm={12} md={10}>
                    <SortingShop listStore={listStore} productType={productType} />
                    <LabelFilters listStore={listStore} />
                    <ShopList listStore={listStore} />
                </Grid>
            </Grid>
        </Container>
    );
}
