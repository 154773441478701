import React from "react";
import { TOrderListingMdl } from "orders/_models/OrderMdl";
import Grid from "@material-ui/core/Grid";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";
import { OrderTypeLabel } from "orders/OrderTypeLabel";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Hidden } from "@material-ui/core";

type Props = {
    order: TOrderListingMdl;
};

export function Order(props: Props) {
    const { t } = useTranslation();
    return (
        <Link target={"_blank"} to={URLS.order(props.order._id)}>
            <Grid container spacing={2} className="flex_row_center">
                <Grid item xs={6} md={4}>
                    {props.order.type && (
                        <div className="mr_5">
                            <OrderTypeLabel label={props.order.no} type={props.order.type} />
                        </div>
                    )}
                </Grid>
                <Hidden xsDown>
                    <Grid item md={4}>
                        {dayjs(props.order.orderDate).format(t("dates.formats.full"))}
                    </Grid>
                </Hidden>
                <Grid item xs={6} md={4} className="textAlign_right">
                    {formatPrice(props.order.total)}
                </Grid>
            </Grid>
        </Link>
    );
}
