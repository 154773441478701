import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { memo, PropsWithChildren, ReactNode, useState } from "react";
import styles from "./_css/uiAccordion.module.css";
import { KeyboardArrowUp } from "@material-ui/icons";

type Props = {
    label: string;
    icon?: ReactNode;
};

const UiAccordion = memo(({ label, icon, children }: PropsWithChildren<Props>) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen((isOpen) => !isOpen);
    return (
        <div>
            <div className={styles.accordion} onClick={toggleOpen}>
                <div className={styles.label}>
                    {icon && <i className={styles.accordionIcon}>{icon}</i>}
                    <p>{label}</p>
                </div>
                <i className={styles.accordionIcon}>
                    {isOpen ? (
                        <KeyboardArrowUp width={16} height={16} />
                    ) : (
                        <KeyboardArrowDownIcon width={16} height={16} />
                    )}
                </i>
            </div>
            {isOpen && <div className={styles.accordionContent}>{children}</div>}
        </div>
    );
});

export default UiAccordion;
