import React, { HTMLAttributes } from "react";
import styles from "./_css/productCard.module.css";
import { productsStore } from "products/_stores/productsStore";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import { EucCard } from "products/cards/EucCard";
import { ScooterCard } from "products/cards/ScooterCard";
import { SppCard } from "products/cards/SppCard";
import clsx from "clsx";
import { formatPrice } from "_common/_utils/currencyUtils";
import i18next from "i18next";
import { getAverageMark, isActiveDiscountVariant } from "_common/_utils/productUtils";
import { UiMarkStars } from "_common/ui/UiMarkStars";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { URLS } from "_configs/URLS";
import { TagsCard } from "products/cards/TagsCard";

type Props = Omit<HTMLAttributes<HTMLDivElement>, "className"> & {
    productId: string;
};

const ProductCard = ({ productId, ...props }: Props) => {
    const product = productsStore.getSync(productId);
    if (!product) return <div />;

    const componentsCards: { [key in PRODUCT_TYPE]?: (props?: any) => JSX.Element } = {
        [PRODUCT_TYPE.EUC]: EucCard,
        [PRODUCT_TYPE.SCOOTER]: ScooterCard,
        [PRODUCT_TYPE.SPP]: SppCard,
        [PRODUCT_TYPE.ACCESSORY]: SppCard,
    };

    const isDiscountTagActive =
        product.variants?.find((variant) => isActiveDiscountVariant(variant.discount)) &&
        !product.labels?.find((label) => label.overrideDiscountTag);

    const ComponentTag = componentsCards[product.type] || EucCard;
    const hasOneVariant = product.variants?.length === 1 && isActiveDiscountVariant(product.variants[0].discount);
    const isMultiplesVariant = product.variants?.length ? product.variants?.length > 1 : false;
    const displayPrice = formatPrice(product.minPrice, true);

    const productImagesUrls = product.photos?.map((photo) => photo.url);
    const hasImage = productImagesUrls && productImagesUrls.length > 0;

    const rating = getAverageMark(product);
    return (
        <ExternalOrNavLink url={product ? URLS.product(product.localized.urlAlias) : "#"}>
            <div className={clsx(styles.container, "paper")} {...props}>
                <div className={clsx(styles.labels, "position_absolute")}>
                    {isDiscountTagActive && <TagsCard message="words.sale" color="sold" />}
                    {product.labels &&
                        product.labels.map((label) => {
                            if (label.shopVisibility) {
                                return (
                                    <TagsCard
                                        key={label._id}
                                        isNotKey
                                        message={label.localized.text}
                                        color={label.color}
                                    />
                                );
                            }
                        })}
                    {product.variants?.find((variant) => variant.stock > 0) && (
                        <TagsCard message="words.stock" color="green" />
                    )}
                </div>
                <div
                    className={clsx("mb_5", styles.picture, {
                        [styles.noPictures]: !hasImage,
                    })}
                >
                    {productImagesUrls && hasImage && (
                        <>
                            {productImagesUrls.length > 1 && (
                                <img
                                    alt={product.localized.name}
                                    className={styles.pictureBack}
                                    src={productImagesUrls[1]}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "https://via.placeholder.com/400?text=noaio.com";
                                    }}
                                />
                            )}
                            <img
                                alt={product.localized.name}
                                className={clsx(styles.pictureFront, { [styles.singlePicture]: !productImagesUrls[1] })}
                                src={productImagesUrls[0]}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "https://via.placeholder.com/400?text=noaio.com";
                                }}
                            />
                        </>
                    )}
                </div>
                <div className={"flex_column justifyContent_spaceBetween alignItems_center flex-1"}>
                    <div className={"flex_center_center flex_column"}>
                        <h2 className={styles.title}>{product.localized.name}</h2>
                        {product.reviews && product.reviews.length > 0 ? (
                            <UiMarkStars mark={rating} numberReviews={product.reviews ? product.reviews.length : 0} />
                        ) : (
                            <div />
                        )}
                    </div>

                    <ComponentTag product={product} />
                    {hasOneVariant ? (
                        <p className={styles.price}>
                            {product.variants?.[0] && (
                                <div className={styles.regularPrice}>{formatPrice(product?.variants[0].price)}</div>
                            )}
                            {formatPrice(product.minPrice, true, true)}
                        </p>
                    ) : (
                        product.minPrice && (
                            <p className={styles.price}>
                                {isMultiplesVariant && i18next.t("product.card.from")} {displayPrice}
                            </p>
                        )
                    )}
                </div>
            </div>
        </ExternalOrNavLink>
    );
};

export default ProductCard;
