import React from "react";
import { CardContent, Paper } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";

type Props = {
    text: string;
};

export function CartAddToast(props: Props) {
    const { t } = useTranslation();
    return (
        <Paper elevation={0}>
            <CardContent>{props.text + " " + t("cart.success")}</CardContent>
            <CardContent>
                <NavLink to={URLS.cart()}>
                    <i className="icon-cart mr_10" /> {t("cart.title")}
                </NavLink>
            </CardContent>
        </Paper>
    );
}
