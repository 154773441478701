import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { TEMPORARY_USER, userStore } from "users/_stores/userStore";
import sharedConfig from "_configs/sharedConfig";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { Checkbox, MenuItem } from "@material-ui/core";
import frImage from "../../../../assets/images/fr.png";
import enImage from "../../../../assets/images/ca.png";
import { UserInfoNotifications } from "users/profile/info/userInfo/UserInfoNotifications";
import { UserAddressForm } from "users/profile/info/userInfo/UserAddressForm";
import { useTranslation } from "react-i18next";
import { cartStore } from "cart/_stores/cartStore";
import { observer } from "mobx-react";
import { SHIPPING_METHOD } from "orders/_models/OrderMdl";

type Props = {
    isEditing?: boolean;
    onStopEditing?: () => void;
    cannotCancel?: boolean;
};

export const UserForm = observer((props: Props) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const useShippingAddress = form.watch("useShippingAddress");
    const collectInStore = form.watch("collectInStore");
    useEffect(() => {
        if (useShippingAddress) {
            cartStore.regionForTax = form.watch("shippingAddress.province");
        } else {
            cartStore.regionForTax = form.watch("address.province");
        }
        cartStore.setShippingMethod(collectInStore ? SHIPPING_METHOD.COLLECT_IN_STORE : SHIPPING_METHOD.DELIVERY);
    }, [form.watch("address.province"), form.watch("shippingAddress.province"), useShippingAddress, collectInStore]);

    useEffect(() => {
        localStorage.setItem(
            TEMPORARY_USER,
            JSON.stringify({
                firstName: form.watch("firstName"),
                lastName: form.watch("lastName"),
                email: form.watch("email"),
                lang: form.watch("lang"),
            }),
        );
        cartStore.save();
    }, [form.watch("firstName"), form.watch("lastName"), form.watch("email"), form.watch("lang")]);

    return (
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <InputBlock required label={t("words.email")}>
                    <Controller
                        as={Input}
                        control={form.control}
                        name={"email"}
                        theme={props.isEditing ? undefined : "label"}
                        rules={{
                            required: t<string>("errors.forms.required"),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t("errors.forms.emailInvalid"),
                            },
                        }}
                        disabled={!!form.watch("_id")}
                        error={form.errors.email}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12} md={6}>
                <InputBlock label={t("words.firstName")} required>
                    <Controller
                        as={Input}
                        control={form.control}
                        name={"firstName"}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                        theme={props.isEditing ? undefined : "label"}
                        disabled={!props.isEditing || form.formState.isSubmitting}
                        error={form.errors.firstName}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12} md={6}>
                <InputBlock label={t("words.lastName")} required>
                    <Controller
                        as={Input}
                        control={form.control}
                        name={"lastName"}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                        theme={props.isEditing ? undefined : "label"}
                        disabled={!props.isEditing || form.formState.isSubmitting}
                        error={form.errors.lastName}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12} md={12}>
                <InputBlock label={t("words.phone")} required>
                    <Controller
                        as={Input}
                        control={form.control}
                        name={"phone"}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                        theme={props.isEditing ? undefined : "label"}
                        disabled={!props.isEditing || form.formState.isSubmitting}
                        error={form.errors.phone}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12} md={6}>
                <InputBlock label={t("profile.lang")}>
                    <Controller
                        control={form.control}
                        name={"lang"}
                        rules={{
                            required: t<string>("errors.forms.required"),
                        }}
                        defaultValue={userStore.user?.lang ?? sharedConfig.defaultLang}
                        as={
                            <UiSelect disabled={!props.isEditing || form.formState.isSubmitting}>
                                {Object.entries(sharedConfig.languages).map(([itemKey, item], index) => {
                                    return (
                                        <MenuItem key={index} value={itemKey}>
                                            <div className="flex_row_center">
                                                <img
                                                    className={"mr_10"}
                                                    height={24}
                                                    src={itemKey === "fr" ? frImage : enImage}
                                                    alt={itemKey}
                                                />
                                                {item.title}
                                            </div>
                                        </MenuItem>
                                    );
                                })}
                            </UiSelect>
                        }
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12}>
                <UserInfoNotifications onStopEditing={props.onStopEditing} isEditing={props.isEditing} />
            </Grid>
            <UserAddressForm type={"address"} labelKey={"user.invoiceAddress"} isEditing={props.isEditing} />
            <Grid item sm={12}>
                <div className={"flex_row_center"}>
                    <Controller
                        disabled={!props.isEditing}
                        control={form.control}
                        render={(field) => {
                            return (
                                <Checkbox
                                    checked={field.value}
                                    onChange={(e) => {
                                        const value = e.target.checked;
                                        if (value) {
                                            form.setValue("useShippingAddress", false);
                                        }
                                        field.onChange(value);
                                    }}
                                    id={"collectInStore"}
                                />
                            );
                        }}
                        name={"collectInStore"}
                    />
                    <label className={"cursor_pointer flex_row"} htmlFor={"collectInStore"}>
                        {t("user.collectInStore")}
                    </label>
                </div>
                <div className={"text_discreet"}>
                    <p className={"fontWeight_bold"}>{t("store.schedule.title")}</p>
                    <p>{t("store.schedule.description")}</p>
                    <p className={"fontWeight_bold"}>{t("words.address")}:</p>
                    <p>{t("store.address")}</p>
                </div>
            </Grid>
            {!collectInStore && (
                <>
                    <Grid item sm={12}>
                        <div className={"flex_row_center"}>
                            <Controller
                                disabled={!props.isEditing}
                                control={form.control}
                                render={(field) => {
                                    return (
                                        <Checkbox
                                            checked={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.checked);
                                            }}
                                            id={"useShippingAddress"}
                                        />
                                    );
                                }}
                                name="useShippingAddress"
                            />
                            <label className={"cursor_pointer"} htmlFor={"useShippingAddress"}>
                                {t("user.useShippingAddress")}
                            </label>
                        </div>
                    </Grid>
                    {useShippingAddress && (
                        <UserAddressForm
                            type={"shippingAddress"}
                            labelKey={"user.shippingAddress"}
                            isEditing={props.isEditing}
                        />
                    )}
                </>
            )}
        </Grid>
    );
});
