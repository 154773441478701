import React from "react";
import i18next from "i18next";
import homepageMarketingBannerFr from "./assets/homepageMarketingBannerFr.png";
import homepageMarketingBannerEn from "./assets/homepageMarketingBannerEn.png";
import homepageMarketingBannerMobileFr from "./assets/homepageMarketingBannerMobileFr.jpeg";
import homepageMarketingBannerMobileEn from "./assets/homepageMarketingBannerMobileEn.jpeg";
import styles from "./_css/homepageMarketingBanner.module.css";
import clsx from "clsx";

const HomepageMarketingBanner = () => {
    const lang = i18next.language;
    const image = lang === "fr" ? homepageMarketingBannerFr : homepageMarketingBannerEn;
    const mobileImage = lang === "fr" ? homepageMarketingBannerMobileFr : homepageMarketingBannerMobileEn;
    return (
        <>
            <img src={image} className={clsx(styles.banner, styles.desktopBanner)} alt={"Noaio banner"} />
            <img src={mobileImage} className={clsx(styles.banner, styles.mobileBanner)} alt={"Noaio banner"} />
        </>
    );
};

export default HomepageMarketingBanner;
