import React from "react";
import Grid from "@material-ui/core/Grid";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { useTranslation } from "react-i18next";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { REGIONS } from "_configs/taxConfig";

type Props = {
    type: string;
    labelKey: string;
    isEditing?: boolean;
    isAdmin?: boolean;
};

export function UserAddressForm(props: Props) {
    const { t } = useTranslation();
    const form = useFormContext();

    return (
        <>
            <Grid item sm={12}>
                <h4>{t(props.labelKey)}</h4>
            </Grid>
            <Grid item sm={12}>
                <InputBlock required={!props.isAdmin && props.isEditing} label={t("user.street")}>
                    <Controller
                        as={Input}
                        control={form.control}
                        name={`${props.type}.street`}
                        theme={props.isEditing ? undefined : "label"}
                        placeholder={""}
                        disabled={!props.isEditing || form.formState.isSubmitting}
                        rules={
                            !props.isAdmin
                                ? {
                                      required: t<string>("errors.forms.required"),
                                  }
                                : undefined
                        }
                        error={form.errors?.[props.type]?.street}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12}>
                <InputBlock label={t("user.extra")}>
                    <Controller
                        as={Input}
                        control={form.control}
                        name={`${props.type}.extra`}
                        theme={props.isEditing ? undefined : "label"}
                        placeholder={props.isEditing ? "Apt 3" : ""}
                        disabled={!props.isEditing || form.formState.isSubmitting}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12} md={6}>
                <InputBlock required={!props.isAdmin && props.isEditing} label={t("words.city")}>
                    <Controller
                        as={Input}
                        control={form.control}
                        name={`${props.type}.city`}
                        theme={props.isEditing ? undefined : "label"}
                        placeholder={props.isEditing ? "Montreal" : ""}
                        disabled={!props.isEditing || form.formState.isSubmitting}
                        rules={
                            !props.isAdmin
                                ? {
                                      required: t<string>("errors.forms.required"),
                                  }
                                : undefined
                        }
                        error={form.errors?.[props.type]?.city}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12} md={6}>
                <InputBlock required={!props.isAdmin && props.isEditing} label={t("user.postalCode")}>
                    <Controller
                        as={Input}
                        control={form.control}
                        name={`${props.type}.postalCode`}
                        theme={props.isEditing ? undefined : "label"}
                        placeholder={props.isEditing ? "H1A 1A1" : ""}
                        disabled={!props.isEditing || form.formState.isSubmitting}
                        rules={
                            !props.isAdmin
                                ? {
                                      required: t<string>("errors.forms.required"),
                                  }
                                : undefined
                        }
                        error={form.errors?.[props.type]?.postalCode}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12} md={6}>
                <InputBlock required={!props.isAdmin && props.isEditing} label={t("user.country")}>
                    <Controller
                        control={form.control}
                        name={`${props.type}.country`}
                        defaultValue={"CA"}
                        as={
                            <UiSelect>
                                <MenuItem value="CA">Canada</MenuItem>
                                <MenuItem value="US">USA</MenuItem>
                            </UiSelect>
                        }
                        disabled={!props.isEditing || form.formState.isSubmitting}
                    />
                </InputBlock>
            </Grid>
            <Grid item sm={12} md={6}>
                <InputBlock required={!props.isAdmin && props.isEditing} label={t("user.province")}>
                    <Controller
                        as={
                            form.watch(`${props.type}.country`) === "CA" ? (
                                <UiSelect>
                                    {Object.values(REGIONS).map((province) => (
                                        <MenuItem key={province} value={province}>
                                            {t(`provinces.${province}`)}
                                        </MenuItem>
                                    ))}
                                </UiSelect>
                            ) : (
                                Input
                            )
                        }
                        control={form.control}
                        name={`${props.type}.province`}
                        theme={props.isEditing ? undefined : "label"}
                        disabled={!props.isEditing || form.formState.isSubmitting}
                        rules={
                            !props.isAdmin
                                ? {
                                      required: t<string>("errors.forms.required"),
                                  }
                                : undefined
                        }
                        error={form.errors?.[props.type]?.province}
                    />
                </InputBlock>
            </Grid>
        </>
    );
}
