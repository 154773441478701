import React, { useEffect } from "react";
import i18next from "i18next";
import { FilterList } from "shop/filter/FilterList";
import { ListStore } from "_common/list/ListStore";
import { FILTER_TYPE, PRODUCT_TYPE, TProductLocalizedMdl } from "products/_models/ProductMdl";
import { FILTERS_CONFIG } from "_configs/filtersConfig";
import styles from "../_css/shopFilter.module.css";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router";
import { getType } from "shop/filtersType/CheckBoxFilter";
import { TFilter } from "admin/_common/filters/TFilter";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { observer } from "mobx-react";
import { NoSsr } from "@material-ui/core";

type Props = {
    listStore: ListStore<TProductLocalizedMdl>;
    productType?: PRODUCT_TYPE;
    className?: string;
};

function getFilterIdFromOptionKey(optionKey: string) {
    let filterId: string;
    switch (optionKey) {
        case "variant":
            filterId = "variants";
            break;
        case "label":
            filterId = "labels";
            break;
        case "brand":
            filterId = "brand.label";
            break;
        case "price":
            filterId = "minPrice";
            break;
        case "subType":
            filterId = "subType";
            break;
        default:
            filterId = "options." + optionKey;
    }
    return filterId;
}

function getOptionKeyFromFilterId(filterId: string) {
    let optionKey: string;
    switch (filterId) {
        case "brand.label":
            optionKey = "brand";
            break;
        case "minPrice":
            optionKey = "price";
            break;
        default:
            optionKey = filterId.substr(filterId.indexOf(".") + 1);
    }
    return optionKey;
}

function updateFilterFromUrl(search: string, listStore: ListStore<TProductLocalizedMdl>) {
    const searchParams = new URLSearchParams(search);
    const entries = searchParams.entries();
    let searchParam = entries.next();
    const altFilterConfig: { [key: string]: any } = {};
    Object.values(FILTERS_CONFIG).forEach((value) =>
        (altFilterConfig[value.key] = value) && altFilterConfig[value.key].filter === undefined
            ? delete altFilterConfig[value.key]
            : {},
    );
    listStore.filters = listStore.filters.filter(
        (currentFilter) =>
            altFilterConfig[getOptionKeyFromFilterId(currentFilter.id)]?.filter.filterType == FILTER_TYPE.SLIDER,
    );
    while (!searchParam.done) {
        const option = FILTERS_CONFIG.find((options) => options.key === searchParam.value[0]);
        if (!option) return undefined;
        const type = getType(option.type);
        const value = type === "number" ? parseInt(searchParam.value[1]) : searchParam.value[1];
        const filter: TFilter = {
            id: getFilterIdFromOptionKey(searchParam.value[0]),
            type,
            op: option.op ?? "eq",
            path: option.key === "label" ? "localized.wordUrl" : undefined,
            value,
        };
        listStore.updateFilter(filter);
        searchParam = entries.next();
    }
}

export const ShopFilters = observer(({ className, ...props }: Props) => {
    const location = useLocation();
    const brand = location.pathname.split("/")[4];
    let locationSearch = location.search;
    if (brand && !location.search.includes("brand")) {
        locationSearch = location.search + `&brand=${brand}`;
    }
    const history = useHistory();
    const { t } = useTranslation();
    if (!__BROWSER__) {
        updateFilterFromUrl(locationSearch, props.listStore);
    }
    useEffect(() => {
        updateFilterFromUrl(locationSearch, props.listStore);
    }, [brand, locationSearch]);
    return (
        <div className={clsx("p_10 mb_40", className, styles.container)}>
            <NoSsr
                fallback={
                    <UiButton className={styles.button} variant={"outlined"} size={"small"} color={"primary"}>
                        {t("shop.removeFilters")} (0)
                    </UiButton>
                }
            >
                <UiButton
                    className={styles.button}
                    variant={"outlined"}
                    size={"small"}
                    color={"primary"}
                    onClick={() => {
                        props.listStore.removeFilters();
                        history.push(brand ? location.pathname.replace(`/${brand}`, "") : location.pathname);
                    }}
                >
                    {t("shop.removeFilters")} ({props.listStore.filters.length})
                </UiButton>
            </NoSsr>
            <NoSsr>
                {FILTERS_CONFIG.filter(
                    (config) => !!config.filter && props.productType && config.types?.includes(props.productType),
                )
                    .sort((a, b) => (a.filter && b.filter && a.filter?.sort > b.filter?.sort ? 1 : -1))
                    .map((filterConfig) => {
                        let preKeyLabel = "product.options.";
                        if (filterConfig.type === "enum") preKeyLabel = "product.options.enum.";
                        return (
                            <div key={filterConfig.key} className={"mt_7"}>
                                <h4 className={clsx("ml_10", styles.title)}>
                                    {i18next.t(preKeyLabel + filterConfig.key)}
                                </h4>
                                <FilterList listStore={props.listStore} optionsConfig={filterConfig} />
                            </div>
                        );
                    })}
            </NoSsr>
        </div>
    );
});
