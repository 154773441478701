import React from "react";
import styles from "../_css/tagsCard.module.css";
import clsx from "clsx";
import i18next from "i18next";

type Props = {
    message: string;
    color: string;
    isNotKey?: boolean;
};

export function TagsCard(props: Props) {
    return (
        <div className={clsx(styles.tag, styles[props.color])} style={{ backgroundColor: props.color }}>
            {props.isNotKey ? props.message : i18next.t(props.message)}
        </div>
    );
}
