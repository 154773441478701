import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const storePosition = {
    lat: 45.5166132,
    lng: -73.5673623,
};

type Props = {
    width?: number;
    height?: number;
    fullWidth?: boolean;
};

export const StoreMap = ({ width = 700, height = 400, fullWidth = true }: Props) => {
    const [map, setMap] = React.useState(null);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        // googleMapsApiKey: appConfig.google.apiKey,
        googleMapsApiKey: "AIzaSyCfSbD6NiRz5Pt045AInMjfZHaV4PO5QE4",
    });

    const containerStyle = {
        maxWidth: fullWidth ? "100%" : `${width}px`,
        height: `${height}px`,
        margin: "0 auto",
    };

    return (
        <>
            {isLoaded ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                <GoogleMap mapContainerStyle={containerStyle} center={storePosition} zoom={17} onUnmount={onUnmount}>
                    <Marker position={storePosition} />
                </GoogleMap>
            ) : (
                <></>
            )}
        </>
    );
};
