import React from "react";
import styles from "main/menu/search/_css/searchBar.module.css";
import { Paper } from "@material-ui/core";
import clsx from "clsx";
import { SearchHeader } from "main/menu/search/SearchHeader";
import { ProductFound } from "main/menu/search/ProductFound";
import { ArticleFound } from "main/menu/search/ArticleFound";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { searchStore } from "main/menu/search/_stores/searchStore";
import { IPageForSearchMdl } from "pages/_models/PageMdl";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

type Props = {
    results: {
        products: TProductLocalizedMdl[];
        articles: IPageForSearchMdl[];
    };
    selectedIdx: number | undefined;
};

const SearchBarResults = ({ results, selectedIdx }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { products, articles } = results;

    const handleClick = () => {
        history.push(URLS.search(i18next.language) + "?search=" + searchStore.search);
        searchStore.open(false);
    };
    return (
        <div className={styles.searchResult}>
            <Paper elevation={1} className={styles.resultContainer}>
                {products.length > 0 && (
                    <div className={clsx(styles.products, "p_20")}>
                        <SearchHeader label={t("words.products")} />
                        {results.products.slice(0, 3).map((product, index) => (
                            <ProductFound selected={selectedIdx === index} product={product} key={product._id} />
                        ))}
                        {results.products.length > 3 && (
                            <p className={styles.seeMore} onClick={handleClick}>
                                {t("words.seeMore")}
                            </p>
                        )}
                    </div>
                )}
                {articles.length > 0 && (
                    <div className={clsx(styles.articles, "p_20")}>
                        <SearchHeader label={t("words.pages")} />
                        {results.articles.slice(0, 3).map((article, index) => (
                            <ArticleFound
                                selected={!!selectedIdx && selectedIdx - products.length === index}
                                article={article}
                                key={article._id}
                            />
                        ))}
                        {results.articles.length > 3 && (
                            <p className={styles.seeMore} onClick={handleClick}>
                                {t("words.seeMore")}
                            </p>
                        )}
                    </div>
                )}
            </Paper>
        </div>
    );
};

export default SearchBarResults;
