import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import styles from "./_css/titleComponent.module.css";
import clsx from "clsx";

type Props = {
    style?: CSSProperties;
    value: string;
    type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    size?: "big" | "medium" | "small";
    centered?: boolean;
    $component: TComponent;
};

export function TitleComponent({ value, type, centered, $component, ...props }: Props) {
    const sizeClassName = {
        [styles.centered]: centered,
        [styles.big]: props.size === "big",
        [styles.medium]: props.size === "medium",
        [styles.small]: props.size === "small",
    };

    switch (type ?? "h2") {
        case "h1":
            return (
                <h1 {...props} className={clsx("h1", sizeClassName)}>
                    {value}
                </h1>
            );
        case "h2":
            return (
                <h2 {...props} className={clsx("h2", sizeClassName)}>
                    {value}
                </h2>
            );
        case "h3":
            return (
                <h3 {...props} className={clsx("h3", sizeClassName)}>
                    {value}
                </h3>
            );
        case "h4":
            return (
                <h4 {...props} className={clsx("h4", sizeClassName)}>
                    {value}
                </h4>
            );
        case "h5":
            return (
                <h5 {...props} className={clsx("h5", sizeClassName)}>
                    {value}
                </h5>
            );
        case "h6":
            return (
                <h6 {...props} className={clsx("h6", sizeClassName)}>
                    {value}
                </h6>
            );
    }
}
