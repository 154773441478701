import React from "react";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import clsx from "clsx";
import ProductSmallCard from "products/ProductSmallCard";
import styles from "./_css/cartInterestedProducts.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { getTopProducts } from "_common/_utils/productUtils";

export const CartInterestedProducts = () => {
    const { t } = useTranslation();

    const EUCProducts = getTopProducts(PRODUCT_TYPE.EUC);
    const SCOOTERProducts = getTopProducts(PRODUCT_TYPE.SCOOTER);
    const ACCESSORYProducts = getTopProducts(PRODUCT_TYPE.ACCESSORY);

    const interestedProducts = [...EUCProducts, ...SCOOTERProducts, ...ACCESSORYProducts];

    if (!interestedProducts || !interestedProducts.length) return null;

    return (
        <>
            <h3 className={"mb_20"}>{t("cart.interestedIn")}</h3>
            <div className={"mb_20"}>
                <Swiper
                    slidesPerView={1.6}
                    spaceBetween={16}
                    breakpoints={{
                        380: {
                            slidesPerView: 2.3,
                        },
                        460: {
                            slidesPerView: 2.8,
                        },
                        540: {
                            slidesPerView: 3.3,
                        },
                        620: {
                            slidesPerView: 3.8,
                        },
                        800: {
                            slidesPerView: 4.6,
                        },
                        900: {
                            slidesPerView: 5.3,
                        },
                        1100: {
                            slidesPerView: 6.7,
                        },
                        1400: {
                            slidesPerView: 7.7,
                        },
                    }}
                >
                    {interestedProducts.map((product) => {
                        if (product === undefined) return null;
                        return (
                            <SwiperSlide key={product._id}>
                                <div key={product._id} className={clsx(styles.productCard)}>
                                    <ProductSmallCard product={product} onClick={() => {}} />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </>
    );
};
