import { action, observable, reaction } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { UNKNOWN_ERROR } from "_common/errors/errorUtils";
import { TFilesData } from "_common/_utils/fetchUtils";
import { createFilesData } from "_common/_utils/fileUtils";
import _ from "lodash";
import eventsStore from "main/events/eventsStore";
import { TProductEditionMdl, TReviewMdl } from "products/_models/ProductMdl";
import { productsAdminStore } from "products/_stores/productsAdminStore";

const PHOTOS_UPLOAD_BATCH_SIZE = 3;

export class ProductAdminStore {
    @observable product: TProductEditionMdl;
    readonly productSavingState = new LoadingStateMdl<TProductEditionMdl | undefined>();

    @observable photosUploadingState: {
        url: string;
        loadingState: LoadingStateMdl<TProductEditionMdl | undefined>;
    }[] = [];
    @observable currentPhotosLoadingState: LoadingStateMdl<TProductEditionMdl | undefined> | undefined = undefined;

    constructor(product: TProductEditionMdl) {
        this.product = product;
        if (!this.product._id) {
            const disposer = eventsStore.on("products/created", (event) => {
                this.product = event.payload.item;
                disposer();
            });
        }
    }

    save(product: Partial<TProductEditionMdl>, files?: TFilesData) {
        return this.patch(product, files);
    }

    addPhotos(urls: string[]) {
        const urlsBatchs = _.chunk(urls, PHOTOS_UPLOAD_BATCH_SIZE);
        return urlsBatchs.map((urlsBatch) => this.uploadPhotosBatchs(urlsBatch));
    }

    movePhoto(startIndex: number, endIndex: number) {
        if (!this.currentPhotosLoadingState) {
            const updatedPhotos = [...(this.product.photos ?? [])];
            updatedPhotos.splice(endIndex, 0, ...updatedPhotos.splice(startIndex, 1));
            this.currentPhotosLoadingState = this.patch({ photos: updatedPhotos });
            this.currentPhotosLoadingState?.promise?.finally(() => (this.currentPhotosLoadingState = undefined));
            return this.currentPhotosLoadingState;
        }
    }

    deletePhoto(photoIndex: number) {
        const loadingState = new LoadingStateMdl<TProductEditionMdl | undefined>();
        const deletePromise = new Promise<TProductEditionMdl | undefined>((resolve) => {
            this.onNextPhotosLoadingState(() => {
                const updatedPhotos = [...(this.product.photos ?? [])];
                updatedPhotos.splice(photoIndex, 1);
                const patchLoadingState = this.patch({ photos: updatedPhotos });
                loadingState.sync(patchLoadingState);
                resolve(patchLoadingState.promise);
            });
        });
        loadingState.startLoading(deletePromise);
        return loadingState;
    }

    getPhotoUploadingState(url: string) {
        return this.photosUploadingState.find((uploadingState) => uploadingState.url === url)?.loadingState;
    }

    @action updateReview(item: TReviewMdl) {
        const reviewIndex = this.product.reviews.findIndex((review) => review._id === item._id);
        this.product.reviews.splice(reviewIndex, 1, item);
    }

    @action createReview(item: TReviewMdl) {
        this.product.reviews.push(item);
    }

    @action deleteReview(itemId: string) {
        const reviewIndex = this.product.reviews.findIndex((review) => review._id === itemId);
        this.product.reviews.splice(reviewIndex, 1);
    }

    getReviewById(id: string) {
        return this.product.reviews.find((review) => review._id === id);
    }

    @action deletePhotoUploadingState(url: string) {
        return this.photosUploadingState.splice(
            this.photosUploadingState.findIndex((uploadingState) => uploadingState.url === url),
            1,
        );
    }

    @action
    private uploadPhotosBatchs(urls: string[]) {
        const uploadingState = new LoadingStateMdl<TProductEditionMdl | undefined>("LOADING");
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            this.photosUploadingState.push({ url, loadingState: uploadingState });
        }
        this.onNextPhotosLoadingState(() => this.executePhotosUpload(uploadingState, urls));
        return uploadingState;
    }

    @action
    private async executePhotosUpload(uploadingState: LoadingStateMdl<TProductEditionMdl | undefined>, urls: string[]) {
        try {
            this.currentPhotosLoadingState = uploadingState;
            const updatedPhotos = [...(this.product.photos ?? []), ...urls.map((url) => ({ url }))];
            const filesData = await createFilesData(
                updatedPhotos.map(({ url }) => url),
                "photos.*.url",
                1200,
            );
            const patchLoadingState = this.patch({ photos: updatedPhotos }, filesData);
            uploadingState.sync(patchLoadingState);
            patchLoadingState.promise
                ?.then(
                    action(() => {
                        for (const url of urls) {
                            this.deletePhotoUploadingState(url);
                        }
                    }),
                )
                .finally(() => (this.currentPhotosLoadingState = undefined));
        } catch (err) {
            uploadingState.setError(err);
            this.currentPhotosLoadingState = undefined;
        }
    }

    private onNextPhotosLoadingState(cb: () => any) {
        if (!this.currentPhotosLoadingState) cb();
        else {
            const disposer = reaction(
                () => !this.currentPhotosLoadingState,
                () => {
                    if (!this.currentPhotosLoadingState) {
                        disposer();
                        cb();
                    }
                },
            );
        }
    }

    private patch(patch: Partial<TProductEditionMdl>, files?: TFilesData) {
        if (!this.productSavingState.isLoading) {
            const isCreate = !this.product._id;
            const request = isCreate
                ? productsAdminStore.create(patch, files)
                : productsAdminStore.patch({ ...patch, _id: this.product._id }, files);
            const promise = request.then(
                action((savedProduct: TProductEditionMdl | undefined) => {
                    if (savedProduct) {
                        this.product = savedProduct;
                        this.productSavingState.setSuccess(savedProduct);
                        eventsStore.send({
                            type: isCreate ? "products/created" : "products/updated",
                            payload: { item: savedProduct },
                        });
                    } else {
                        this.productSavingState.setError(UNKNOWN_ERROR);
                    }
                    return savedProduct;
                }),
                (err) => {
                    this.productSavingState.setError(err);
                    return undefined;
                },
            );
            this.productSavingState.startLoading(promise);
        }
        return this.productSavingState;
    }
}
