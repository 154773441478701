import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { TLang } from "_configs/sharedConfig";
import { TUserMdl } from "users/_models/UserMdl";
import { observer } from "mobx-react";
import i18next from "i18next";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { UserForm } from "users/profile/info/userInfo/UserForm";

type Props = {
    isEditing?: boolean;
    onStopEditing?: () => void;
    cannotCancel?: boolean;
};

export const UserInfoForm = observer((props: Props) => {
    const { t } = useTranslation();
    const user = !userStore.isLogged
        ? {
              email: "",
              phone: "",
              firstName: "",
              lastName: "",
              notification: true,
              address: {
                  name: "",
                  street: "",
                  extra: "",
                  city: "",
                  postalCode: "",
                  country: "CA",
                  province: "quebec",
              },
              useShippingAddress: false,
              collectInStore: false,
              shippingAddress: {
                  name: "",
                  street: "",
                  extra: "",
                  city: "",
                  postalCode: "",
                  country: "CA",
                  province: "quebec",
              },
              lang: i18next.language as TLang | undefined,
          }
        : userStore.user;
    const form = useForm<TUserMdl>({
        mode: "onBlur",
        defaultValues: user,
    });

    useEffect(() => {
        form.reset(user);
    }, [userStore.user]);

    return (
        <FormProvider {...form}>
            <UserForm {...props} />
            {props.isEditing && (
                <div className="mt_20">
                    {!props.cannotCancel && (
                        <UiButton
                            onClick={() => {
                                props.onStopEditing?.();
                                form.reset(userStore.user);
                            }}
                            variant={"outlined"}
                            color={"primary"}
                            className="mr_5"
                            disabled={form.formState.isSubmitting}
                        >
                            {t("words.cancel")}
                        </UiButton>
                    )}
                    <UiButton
                        onClick={form.handleSubmit(async (data) => {
                            return userStore
                                .save(
                                    {
                                        ...userStore.user,
                                        ...data,
                                    } as TUserMdl,
                                    undefined,
                                )
                                .then(
                                    () => {
                                        toast.success(t("profile.success"));
                                        props.onStopEditing?.();
                                    },
                                    (err) => {
                                        toast.error(getErrorMessage(err));
                                    },
                                );
                        })}
                        color={"primary"}
                        variant={"contained"}
                        disabled={form.formState.isSubmitting}
                    >
                        {form.formState.isSubmitting ? t("loaders.message") : t("words.submit")}
                    </UiButton>
                </div>
            )}
        </FormProvider>
    );
});
