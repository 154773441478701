import React, { useState } from "react";
import { CardContent, CardHeader, IconButton } from "@material-ui/core";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { authStore } from "users/auth/_stores/authStore";
import { URLS } from "_configs/URLS";
import { observer } from "mobx-react";

type TFormData = {
    email: string;
    password: string;
};

type Props = {
    backUrl?: string;
    onSuccess?: (success: boolean) => void;
};

export const SignInFormBlock = observer((props: Props) => {
    const { t } = useTranslation();
    const { handleSubmit, register, errors } = useForm<TFormData>({
        mode: "onBlur",
    });
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const { loading, setPromise } = useLoadingFromPromise();
    const onSubmit = (values: TFormData) => {
        const promise = authStore.signIn(values.email, values.password);
        setPromise(promise);
        promise.then(() => {
            if (props.onSuccess) props.onSuccess(true);
            history.push(props.backUrl ?? URLS.home());
        });
    };
    const isLoading = loading?.status === "LOADING";
    return (
        <>
            <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("auth.signIn.title")} />
            <CardContent>
                <ErrorBlock error={loading?.error} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputBlock className="mb_25" label={t("words.email")} required>
                        <Input
                            name="email"
                            ref={register({
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            })}
                            error={errors.email}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock className="mb_40" label={t("words.password")}>
                        <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            ref={register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={errors.password}
                            disabled={isLoading}
                            endIcon={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    size="small"
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            }
                        />
                    </InputBlock>
                    <div className="flex_center_center mb_15">
                        <UiButton type="submit" disabled={isLoading} variant="contained" color="primary">
                            {t(isLoading ? "loaders.message" : "auth.signIn.title")}
                        </UiButton>
                    </div>
                </form>
            </CardContent>
        </>
    );
});
