import React from "react";
import { useParams } from "react-router";
import { Container, Grid } from "@material-ui/core";
import { PageNotFound } from "pages/PageNotFound";
import { URLS } from "_configs/URLS";
import styles from "./_css/specialPage.module.css";
import { productsStore } from "products/_stores/productsStore";
import ProductCard from "products/ProductCard";
import { useTranslation } from "react-i18next";

export function SpecialPage() {
    const { labelName } = useParams<{ labelName: string | undefined }>();
    const { t } = useTranslation();
    if (!labelName) {
        return <PageNotFound redirect={{ link: URLS.shop(t("routes.productTypes.EUC")), timer: 3000 }} />;
    }
    const productsOfLabelName = productsStore.getByLabelName(labelName);
    if (productsOfLabelName.length === 0) {
        return <PageNotFound redirect={{ link: URLS.shop(t("routes.productTypes.EUC")), timer: 3000 }} />;
    }

    return (
        <Container>
            <Grid className={styles.container} container alignItems="flex-start" spacing={0}>
                {productsOfLabelName.map((product) => {
                    if (!product) return null;
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
                            <div className="flex_center_center">
                                <ProductCard productId={product._id} />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
}
