import React, { useEffect, useRef } from "react";
import { ListStore } from "_common/list/ListStore";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { TProductConfigDef } from "_configs/productConfig";
import { TFilter } from "admin/_common/filters/TFilter";
import { getType } from "shop/filtersType/CheckBoxFilter";
import { observer } from "mobx-react";
import _ from "lodash";
import styles from "../_css/sliderFilters.module.css";
import "../_css/sliderFilters.css";
import Slider from "@material-ui/core/Slider/Slider";
import clsx from "clsx";

type Props = {
    listStore: ListStore<TProductLocalizedMdl>;
    optionConfig: TProductConfigDef;
};

export const SliderFilters = observer((props: Props) => {
    const optionConfig = props.optionConfig;
    const values = props.listStore.items
        .map((item) => {
            const key = props.optionConfig.filter?.itemPath;
            return item && key ? _.get(item, key) : undefined;
        })
        .filter((value) => !!value);
    if (values.length === 0) return null;
    const minValue = parseInt(Math.min(...values).toFixed(2));
    const maxValue = parseInt(Math.max(...values).toFixed(2));

    const filterId = optionConfig.filter?.itemPath ?? optionConfig.key;
    const filterIndex = props.listStore.getFilterIndex(filterId);
    const filter = props.listStore.filters[filterIndex];

    const ref = useRef(null);

    useEffect(() => {
        const SliderRef: HTMLElement | null = ref.current;
        if (SliderRef !== null) {
            (SliderRef as { childNodes: HTMLElement[] }).childNodes.forEach((el) => {
                const classes = el.classList;
                if (classes.contains("MuiSlider-thumb")) {
                    const arrow = document.createElement("div");
                    arrow.className = "MuiSlider_arrow";
                    el.append(arrow);
                }
            });
        }
    }, []);
    return (
        <div className={styles.container}>
            <div className="ph_10">
                <Slider
                    ref={ref}
                    className={clsx("ph_20", styles.slider)}
                    valueLabelDisplay="on"
                    value={filter?.value ? [...filter.value] : [minValue, maxValue]}
                    defaultValue={[minValue, maxValue]}
                    min={minValue}
                    max={maxValue}
                    aria-labelledby="10"
                    step={10}
                    onChange={(_event, value) => {
                        const filter: TFilter = {
                            id: filterId,
                            type: getType(optionConfig.type),
                            value,
                            op: "minMax",
                        };

                        props.listStore.updateFilter(filter, filterIndex);
                    }}
                />
            </div>
            <div className={clsx("ph_5", styles.input)}>
                <div>{minValue}</div>
                <div>{maxValue}</div>
            </div>
        </div>
    );
});
