import React from "react";
import { TLeafMenu } from "settings/_models/MenuMdl";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import styles from "./_css/leafMenu.module.css";
import clsx from "clsx";
import WhatshotIcon from "@material-ui/icons/Whatshot";

type Props = {
    menu: TLeafMenu;
    onClick: () => void;
};

export function LeafMenu(props: Props) {
    return (
        <ExternalOrNavLink
            className={clsx(styles.container, "flex_row_center", {
                [styles.container_highlighted]: props.menu.highlighted,
            })}
            url={props.menu.url}
            onClick={props.onClick}
        >
            <div className={clsx(styles.item, "flexShrink_0")}>{props.menu.title}</div>
            {props.menu.highlighted && <WhatshotIcon className={styles.icon} />}
        </ExternalOrNavLink>
    );
}
