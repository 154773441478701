import React from "react";
import { PRODUCT_COLOR, TProductConfigDef } from "_configs/productConfig";
import { Grid } from "@material-ui/core";
import i18next from "i18next";
import { AdminColorInput } from "admin/products/editor/AdminColorInput";
import styles from "./_css/productFeatures.module.css";
import { convert } from "_common/_utils/unityUtils";

type Props = {
    optionConfig: TProductConfigDef;
    preKeyLabel: string;
    value: any;
    unit: string;
};

function getValueLabel(value: string | number, unit: string) {
    let display;
    switch (unit) {
        case '"':
            display = value + unit;
            break;
        case "heures":
            display = value + "h";
            break;
        case "W":
            display = value + unit;
            break;
        case "A":
            display = value + unit;
            break;
        case "V":
            display = value + unit;
            break;
        default:
            display = value + " " + unit;
    }
    return display;
}

export function ProductFeature(props: Props) {
    const ratio = props.optionConfig.unity?.ratio;
    const value = ratio ? convert(props.value, ratio) : props.value;
    const valueDisplay = getValueLabel(value, props.unit);
    return (
        <Grid container spacing={0} className={styles.cellContent}>
            <Grid item xs={7}>
                <strong className={styles.strong}>{i18next.t(props.preKeyLabel + props.optionConfig.key)}</strong>
            </Grid>
            <Grid item xs={5}>
                {Object.keys(PRODUCT_COLOR).find((color) => color.toLowerCase() === props.value) ? (
                    <div className="ml_10">
                        <AdminColorInput type={"color"} value={props.value} disabled={true} />
                    </div>
                ) : (
                    <div>{valueDisplay}</div>
                )}
            </Grid>
        </Grid>
    );
}
