import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./_css/productComparison.module.css";
import ProductComparisonList from "products/comparison/ProductComparisonList";
import ProductComparisonAddAction from "products/comparison/ProductComparisonAddAction";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";

const COMPARE_LIST_LIMIT = 2;

const ProductComparison = () => {
    const [comparisonList, setComparisonList] = useState<TProductLocalizedMdl[]>([]);

    const addProduct = (product: TProductLocalizedMdl) => {
        setComparisonList((prev) => {
            return [...prev, product];
        });
    };

    const removeProduct = (product: TProductLocalizedMdl) => {
        setComparisonList((prev) => {
            return prev.filter((_product) => _product._id !== product._id);
        });
    };

    return (
        <Container>
            <Grid container spacing={3} className={styles.container}>
                <div className={"flex_row"}>
                    <ProductComparisonList products={comparisonList} removeProduct={removeProduct} />
                    {comparisonList.length < COMPARE_LIST_LIMIT && (
                        <ProductComparisonAddAction onProductAddClick={addProduct} />
                    )}
                </div>
            </Grid>
        </Container>
    );
};

export default ProductComparison;
