import React, { useState } from "react";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { ordersStore } from "orders/_stores/ordersStore";
import { ORDER_TYPE, QUOTATION_STATUS } from "orders/_models/OrderMdl";
import { useOrderStore } from "orders/OrderContext";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { CheckoutPaymentBlock } from "checkout/CheckoutPaymentBlock";
import { TPaymentBaseMdl } from "admin/payments/_models/CrudPaymentMdl";
import { Dialog } from "_common/ui/dialogs/Dialog";

export const OrderDocumentActions = observer(() => {
    const orderStore = useOrderStore();
    const { t } = useTranslation();
    const [processingPayment, setProcessingPayment] = useState(false);
    return (
        <div className="no-print">
            {orderStore.order.type === ORDER_TYPE.QUOTATION && (
                <>
                    <Grid item xs={12}>
                        <UiButton
                            onClick={() => {
                                if (orderStore.order.token) {
                                    ordersStore
                                        .acceptQuotation(orderStore.order.token)
                                        .then(() => orderStore.setType(ORDER_TYPE.ACCEPTED_QUOTATION));
                                }
                            }}
                            variant={"contained"}
                            color={"primary"}
                        >
                            {t("order.accept")}
                        </UiButton>
                    </Grid>
                    <Grid item xs={12} className="no-print">
                        <UiButton
                            onClick={() => {
                                if (orderStore.order.token) {
                                    ordersStore
                                        .acceptQuotation(orderStore.order.token, QUOTATION_STATUS.DECLINED)
                                        .then(() => orderStore.setType(ORDER_TYPE.DECLINED_QUOTATION));
                                }
                            }}
                            variant="text"
                        >
                            {t("order.decline")}
                        </UiButton>
                    </Grid>
                </>
            )}
            {orderStore.order.type === ORDER_TYPE.INVOICE && !orderStore.order.payment && (
                <>
                    <Grid item xs={12}>
                        <UiButton
                            onClick={() => {
                                setProcessingPayment(true);
                            }}
                            variant={"contained"}
                            color={"primary"}
                        >
                            {t("checkout.steps.CHECK_OUT")}
                        </UiButton>
                    </Grid>
                    <Dialog open={processingPayment} closeCross={true} onClose={() => setProcessingPayment(false)}>
                        <Card>
                            <CardHeader className="textAlign_center" title={t("checkout.title")} />
                            <CardContent>
                                <CheckoutPaymentBlock
                                    order={orderStore.order}
                                    amount={orderStore.order.total ?? 0}
                                    onSuccess={(payment: TPaymentBaseMdl) => {
                                        setProcessingPayment(false);
                                        orderStore.setPayment(payment);
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Dialog>
                </>
            )}
        </div>
    );
});
