import _ from "lodash";

export function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function s6() {
    return Math.floor((1 + Math.random()) * 0x1000000)
        .toString(16)
        .substring(1);
}

export function guid() {
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export function iterateObject(obj: any, callback: (value: any, path: string[]) => void, path: string[] = []) {
    callback(obj, path);
    if (_.isObject(obj) || _.isArray(obj)) {
        for (const key in obj) {
            iterateObject((obj as any)[key], callback, [...path, key]);
        }
    }
}

export function isPromise<T, U>(value?: T | Promise<U>): value is Promise<U> {
    return typeof (value as Promise<U> | undefined)?.then === "function";
}

export function isGuid(token: string) {
    return guid().length === token.length;
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export function isEmail(email?: string) {
    return email !== undefined && email !== null && emailRegex.test(email.trim());
}
