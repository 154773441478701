import { ADMIN_PATH } from "admin/_configs/ADMIN_URLS";
import { appConfig } from "_configs/appConfig";
import sharedConfig from "_configs/sharedConfig";
import { TEMPORARY_USER, userStore } from "users/_stores/userStore";
import _ from "lodash";
import { productsStore } from "products/_stores/productsStore";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import { URLS } from "_configs/URLS";
import { TOrderMdl } from "orders/_models/OrderMdl";
import { TUserMdl } from "users/_models/UserMdl";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

type TGaAction = {
    name: string;
    type?: string;
};

export enum SIB_EVENTS {
    CART_UPDATED = "cart_updated",
    CART_DELETED = "cart_deleted",
    ORDER_COMPLETED = "order_completed",
}

class AnalyticsUtils {
    readonly crispPromise: Promise<any> | undefined;
    enabled = sharedConfig.isProd;
    sendinblue: { track: any } | undefined;

    constructor() {
        if (!__BROWSER__) {
            return;
        }
        TagManager.initialize({
            gtmId: appConfig.google.gtm.gtmId,
        });
        ReactGA.initialize(appConfig.google.analytics, { testMode: !this.enabled });
        this.loadingSIB().then((sendinblue) => (this.sendinblue = sendinblue as { track: any }));
    }

    sendEcommerceEvent(url: string) {
        if (!this.enabled) {
            return;
        }

        try {
            ReactGA.send({ hitType: "pageview", page: url });
        } catch (error) {
            console.error(error);
        }
    }

    sendEvent(event: { [key: string]: any }, url: string) {
        if (!this.enabled) {
            return;
        }

        try {
            ReactGA.set(event);
            ReactGA.send({ hitType: "pageview", page: url });
        } catch (error) {
            console.error(error);
        }
    }

    trackEcommerceEvent(_action: TGaAction, _payload: any, url: string, send = false) {
        if (!this.enabled) {
            return;
        }

        try {
            if (send) {
                this.sendEcommerceEvent(url);
            }
        } catch (error) {
            console.error(error);
        }
    }

    trackEcommerceEventDeferred(action: TGaAction, payload: any, url: string, send = false) {
        if (!__BROWSER__) {
            return;
        }

        setTimeout(() => this.trackEcommerceEvent(action, payload, url, send), 200);
    }

    tracksSibEvent(eventName: SIB_EVENTS, properties: any, payload: any) {
        if (!this.sendinblue) return;
        this.sendinblue.track(eventName, properties, payload);
    }

    sendInBlueTracking(cart: TOrderMdl & { id: string }, hasOrdered = false) {
        let user = {};
        if (userStore.isLogged) {
            user = {
                ..._.pick(userStore.user, ["firstName", "lastName", "email", "lang"]),
                email_id: userStore.user?.email,
            };
        } else {
            const temporaryUser = localStorage.getItem(TEMPORARY_USER);
            if (temporaryUser) {
                const _user = JSON.parse(temporaryUser);
                user = { ..._user, email_id: _user.email };
            }
        }
        if (hasOrdered) {
            this.tracksSibEvent(SIB_EVENTS.ORDER_COMPLETED, user, {
                id: cart.id,
                data: this.getAndReformatCartForSibEvent(cart, user),
            });
        }

        if (cart.lines.length === 0) {
            this.tracksSibEvent(SIB_EVENTS.CART_DELETED, user, {
                id: cart.id,
                data: _.omit(cart, "id"),
            });
            return;
        }

        this.tracksSibEvent(SIB_EVENTS.CART_UPDATED, user, {
            id: cart.id,
            data: this.getAndReformatCartForSibEvent(cart, user),
        });
    }

    setUserInfo(email: string) {
        this.crispPromise?.then((crisp) => {
            crisp.push(["set", "user:email", email]);
        });
    }

    trackPage(url: string) {
        if (url.startsWith(ADMIN_PATH)) {
            return;
        }
        try {
            ReactGA.ga("set", "page", url);
            ReactGA.send({ hitType: "pageview", page: url });
            import("react-facebook-pixel")
                .then((module) => module.default)
                .then((ReactPixel) => {
                    ReactPixel.init(sharedConfig.pixelId, undefined, {
                        autoConfig: true,
                        debug: !this.enabled,
                    });
                    ReactPixel.pageView();
                });
        } catch (err) {
            console.error(err);
        }
    }

    private getAndReformatCartForSibEvent(cart: TOrderMdl & { id: string }, user: Partial<TUserMdl>) {
        const cartWithProductLink = {
            ...cart,
            items: [
                ...cart.lines.map((line) => {
                    const { product } = productsStore.getProductByVariantId(line.variant);
                    return {
                        ...line,
                        name: line.text,
                        price: (line.price / 100).toFixed(2),
                        isEucOrScooter: product
                            ? product.type === PRODUCT_TYPE.EUC || product.type === PRODUCT_TYPE.SCOOTER
                            : false,
                        image: product ? (product.photos?.[0] ? sharedConfig.appUrl + product.photos[0].url : "") : "",
                        url: product
                            ? sharedConfig.appUrl + URLS.product(product?.urlAlias[user.lang ?? "en"], user.lang)
                            : "",
                    };
                }),
            ],
        };
        const hasAnyEucOrScooter = !!cartWithProductLink.items.find((line) => line.isEucOrScooter);
        return _.omit({ ...cartWithProductLink, hasAnyEucOrScooter }, ["id", "lines"]);
    }

    private loadingSIB() {
        return new Promise((resolve) => {
            (window as any).sib = { equeue: [], client_key: sharedConfig.sendinBlue.clientKey };
            /* OPTIONAL: email for identify request*/
            (window as any).sib.email_id = (window as any).email;
            (window as any).sendinblue = {};
            for (let j = ["track", "identify", "trackLink", "page"], l = 0; l < j.length; l++) {
                (function (k) {
                    (window as any).sendinblue[k] = function () {
                        // eslint-disable-next-line prefer-rest-params
                        const arg = Array.prototype.slice.call(arguments);
                        (
                            (window as any).sib[k] ||
                            function () {
                                const t = {};
                                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                // @ts-ignore
                                t[k] = arg;
                                (window as any).sib.equeue.push(t);
                            }
                        )(arg[0], arg[1], arg[2]);
                    };
                })(j[l]);
            }
            const n = document.createElement("script");
            const i = document.getElementsByTagName("script")[0];
            (n.type = "text/javascript"),
                (n.id = "sendinblue-js"),
                (n.async = !0),
                (n.src = "https://sibautomation.com/sa.js?key=" + (window as any).sib.client_key),
                i.parentNode?.insertBefore(n, i),
                (window as any).sendinblue.page();
            resolve((window as any).sendinblue);
        });
    }
}

const analyticsUtils = new AnalyticsUtils();
export { analyticsUtils };
