import React, { CSSProperties, useState } from "react";
import clsx from "clsx";
import { TComponent } from "pages/_models/PageMdl";
import { IconComponent } from "components/base/icon/IconComponent";
import styles from "./_css/styles.module.css";

export type TextComponentProps = {
    style: CSSProperties;
    collapse: boolean;
    value: {
        question: string;
        answer: string;
    };
    $component: TComponent;
    question: string;
};

export function QuestionComponent({ value, collapse, $component, ...props }: TextComponentProps) {
    const [hidden, setHidden] = useState(false);
    return (
        <div
            {...props}
            style={{ ...props.style }}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
        >
            <div
                className={clsx("flex_row_center", {
                    ["cursor_pointer"]: collapse,
                })}
                onClick={() => setHidden(!hidden)}
            >
                <h3 itemProp="name">{value.question}</h3>
                {collapse && (
                    <IconComponent
                        className={clsx("ml_20 mb_20", {
                            [styles.isOpened]: hidden,
                        })}
                        icon={"icon-chevron-down"}
                    />
                )}
            </div>
            <div
                className="mb_20"
                hidden={collapse && !hidden}
                itemScope
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer"
            >
                <div
                    itemProp="text"
                    style={{ margin: 5 }}
                    className={clsx("text_normal", styles.container)}
                    dangerouslySetInnerHTML={{ __html: value.answer }}
                />
            </div>
        </div>
    );
}
