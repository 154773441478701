import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";

type Props = {
    redirect?: { link: string; timer: number };
};

export function NotFound({ redirect }: Props) {
    const { t } = useTranslation();

    return (
        <Container>
            <p>{t("errors.pageNotFound")}</p>
            <p>{redirect && t("errors.pageRedirect", { timer: redirect.timer / 1000 })}</p>
        </Container>
    );
}
