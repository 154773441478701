import { TLang } from "_configs/sharedConfig";

export type IHeardAboutUs = { key: string; other?: string };

export enum USER_ROLE {
    ADMIN = "ADMIN",
}

export type TAddressModel = {
    name: string;
    street: string;
    extra: string;
    city: string;
    postalCode: string;
    country: string;
    province: string;
};

export type TUserMdl = {
    _id: string;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    notification: boolean;
    address?: TAddressModel;
    useShippingAddress: boolean;
    collectInStore: boolean;
    shippingAddress?: TAddressModel;
    lang: TLang;
    roles: string[];
};

export type TUserSummaryMdl = {
    _id: string;
    photo?: string;
    firstName?: string;
    email?: string;
    lastName?: string;
    lang: TLang;
};

export type TUserForOrderMdl = {
    _id: string;
    phone?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    address?: TAddressModel;
    useShippingAddress: boolean;
    collectInStore: boolean;
    shippingAddress?: TAddressModel;
    lang: TLang;
};
