import React from "react";
import { useProductStore } from "products/ProductContext";
import { observer } from "mobx-react";
import styles from "./_css/productTitle.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const ProductTitle = observer(() => {
    const productStore = useProductStore();
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex_row_center")}>
            <h1>{productStore.product.localized.name}</h1>
            <div className="flex-1" />
            {productStore.selectedVariant?.isPreorder && (
                <div className={styles.preorderLabel}>{t("product.preorder")}</div>
            )}
        </div>
    );
});
