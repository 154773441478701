import dayjs, { Dayjs } from "dayjs";
import { TPaymentBaseMdl } from "admin/payments/_models/CrudPaymentMdl";
import { TUserForOrderMdl } from "users/_models/UserMdl";
import { TGiftCardMdl } from "giftCards/_models/GiftCardMdl";

export enum ORDER_SHIPPING {
    UPS = "UPS",
    FEDEX = "FEDEX",
    POSTE_CANADA = "POSTE CANADA",
    OWNER_DELIVERY = "DEPOT DIRECT",
    TAKE_AWAY = "A EMPORTER",
}

export enum SHIPPING_METHOD {
    DELIVERY = "DELIVERY",
    COLLECT_IN_STORE = "COLLECT_IN_STORE",
}
export const SHIPPING_PROVIDERS = [
    {
        id: ORDER_SHIPPING.UPS,
        link: "https://www.ups.com/track?loc=fr_CA&tracknum=",
    },
    {
        id: ORDER_SHIPPING.FEDEX,
        link: "https://www.fedex.com/fedextrack?trknbr=",
    },
    {
        id: ORDER_SHIPPING.POSTE_CANADA,
        link: "https://parcelsapp.com/fr/tracking/",
    },
    {
        id: ORDER_SHIPPING.OWNER_DELIVERY,
    },
    {
        id: ORDER_SHIPPING.TAKE_AWAY,
    },
];

export enum PAYMENT_TYPES {
    NONE = "NONE",
    STRIPE = "STRIPE",
    SQUARE = "SQUARE",
    CASH = "CASH",
    CHECK = "CHECK",
    GIFT_CARD = "GIFT_CARD",
}

export enum ORDER_TYPE {
    QUOTATION = "QUOTATION",
    DECLINED_QUOTATION = "DECLINED_QUOTATION",
    ACCEPTED_QUOTATION = "ACCEPTED_QUOTATION",
    PREORDER_INVOICE = "PREORDER_INVOICE",
    INVOICE = "INVOICE",
    REFUND_INVOICE = "REFUND_INVOICE",
}

export enum QUOTATION_STATUS {
    ACCEPTED = "ACCEPTED",
    DECLINED = "DECLINED",
}

export type TOrderLine = {
    text?: string;
    variant?: string;
    quantity: number;
    price: number;
    codeGiftCard?: string;
};

export type TBaseOrderMdl = {
    description: string;
    lines: TOrderLine[];
    customer?: TUserForOrderMdl;
    subtotal: number;
    deliveryCost: number;
    total: number;
    localeTax: number;
    shippingMethod: SHIPPING_METHOD;
    gst: number;
    token?: string;
    no?: string;
    orderDate?: Dayjs;
    isShipping?: boolean;
    refund?: boolean;
    shipping?: ORDER_SHIPPING;
    shippingId?: string;
    issueWhilePaying?: boolean;
};

export type TOrderMdl = TBaseOrderMdl & {
    _id: string;
    payment_type?: PAYMENT_TYPES;
    payment?: string;
    giftCard?: string;
    type?: ORDER_TYPE;
    updatedAt: Dayjs;
    createdAt: Dayjs;
    issueWhilePaying?: boolean;
};

export type TOrderListingMdl = TBaseOrderMdl & {
    _id: string;
    payment?: TPaymentBaseMdl;
    giftCard?: TGiftCardMdl;
    type?: ORDER_TYPE;
    updatedAt: Dayjs;
    createdAt: Dayjs;
};

export const defaultOrder = {
    subtotal: 0,
    deliveryCost: 0,
    total: 0,
    gst: 0,
    localeTax: 0,
    shippingMethod: SHIPPING_METHOD.DELIVERY,
    lines: [{ text: "", quantity: 1, price: 0 }],
    customer: undefined,
    type: ORDER_TYPE.QUOTATION,
    orderDate: dayjs(),
    description: "",
    shippingId: "",
};
