import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { TitleComponent } from "components/base/title/TitleComponent";
import styles from "./_css/sectionTitleComponent.module.css";
import clsx from "clsx";

type Props = {
    style: CSSProperties;
    value: string;
    type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    theme?: "primary" | "secondary" | "default" | "white";
    size?: "big" | "medium" | "small";
    centered?: boolean;
    $component: TComponent;
};

export function SectionTitleComponent({ value, type, centered, $component, ...props }: Props) {
    return (
        <div
            {...props}
            className={clsx(styles.container, {
                [styles.centered]: centered,
                [styles.primary]: props.theme === "primary",
                [styles.secondary]: props.theme === "secondary",
                [styles.white]: props.theme === "white",
            })}
        >
            <TitleComponent value={value} type={type} size={props.size} $component={$component} />
            <div
                className={clsx(styles.underline, {
                    [styles.bigUnderline]: props.size === "big",
                    [styles.mediumUnderline]: props.size === "medium",
                    [styles.smallUnderline]: props.size === "small",
                })}
            />
        </div>
    );
}
