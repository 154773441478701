import React from "react";
import { FooterMenu } from "main/footer/FooterMenu";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { URLS } from "_configs/URLS";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import { productsStore } from "products/_stores/productsStore";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const FOOTER_LINKS = [
    {
        title: i18nextInstance.t("footer.navigation.euc"),
        url: URLS.shop(i18nextInstance.t(`routes.productTypes.${PRODUCT_TYPE.EUC}`)),
    },
    {
        title: i18nextInstance.t("footer.navigation.scooter"),
        url: URLS.shop(i18nextInstance.t(`routes.productTypes.${PRODUCT_TYPE.SCOOTER}`)),
    },
    {
        title: i18nextInstance.t("footer.navigation.spareParts"),
        url: URLS.shop(i18nextInstance.t(`routes.productTypes.${PRODUCT_TYPE.SPP}`)),
    },
    {
        title: i18nextInstance.t("footer.navigation.accessories"),
        url: URLS.shop(i18nextInstance.t(`routes.productTypes.${PRODUCT_TYPE.ACCESSORY}`)),
    },
    {
        title: i18nextInstance.t("footer.navigation.contact"),
        url: i18nextInstance.t("contact.url"),
    },
];

type Props = {
    className?: string;
};

const isBrand = (brand: string | undefined): brand is string => !!brand;

export function FooterNavigation(props: Props) {
    const { t } = useTranslation();
    const eucBrands = new Set(
        productsStore
            .getListStore(PRODUCT_TYPE.EUC)
            .items.map((product) => product?.brand?.label)
            .filter(isBrand),
    );
    const eucLinks = [...eucBrands].map((brand) => ({
        title: t("product.types.EUC") + " " + brand,
        url: URLS.shop(i18nextInstance.t(`routes.productTypes.${PRODUCT_TYPE.EUC}`), brand.toLowerCase()),
    }));
    const scooterBrands = new Set(
        productsStore
            .getListStore(PRODUCT_TYPE.SCOOTER)
            .items.map((product) => product?.brand?.label)
            .filter(isBrand),
    );
    const scooterLinks = [...scooterBrands].map((brand) => ({
        title: t("product.types.SCOOTER") + " " + brand,
        url: URLS.shop(i18nextInstance.t(`routes.productTypes.${PRODUCT_TYPE.SCOOTER}`), brand.toLowerCase()),
    }));

    return (
        <div className={"flex_row flex-1 flex_center_center"}>
            <FooterMenu className={clsx(props.className, "mr_40")} title="Navigation" links={FOOTER_LINKS} />
            <FooterMenu className={props.className} links={eucLinks.concat(scooterLinks)} />
        </div>
    );
}
