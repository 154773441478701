const isDev = process.env.NODE_ENV === "development";
const isStaging = process.env.IS_STAGING === "true";
const isProd = process.env.NODE_ENV === "production" && !isStaging;

const languages = {
    fr: {
        lang: "fr",
        baseUrl: `${process.env.APP_URL}/fr`,
        basePath: `/fr`,
        title: "Français",
    },
    en: {
        lang: "en",
        baseUrl: `${process.env.APP_URL}/en`,
        basePath: `/en`,
        title: "English",
    },
};

export type TLang = keyof typeof languages;

const sharedConfig = {
    env: process.env.NODE_ENV,
    isDev,
    isStaging,
    isProd,

    tinyMce: {
        apiKey: "nkkmc3xh294xfylti5bk9h824u990jnlslwrpycnpf1m00r9",
    },

    emailName: "Noaio",
    email: isProd ? "contact@noaio.com" : "noaio@incodewetrust.co",

    defaultLang: "fr" as TLang, // langue par défaut, utilisé si des textes ne sont pas présent dans une autre langue
    languages,

    apiUrl: process.env.API_URL as string,
    appUrl: process.env.APP_URL as string,

    sentry: {
        dsn: undefined, // to_change
    },

    uploadsBaseUrl: "/static/uploads",

    provinces: ["AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "QC", "SK", "NT", "NU", "YT"],

    endDateAuctionsThreshold: 2,

    taxes: {
        gst: 0.05,
        qst: 0.09975,
    },

    pixelId: "338051616841804",

    sendinBlue: {
        clientKey: "jiruvekjfv8b95wswxje5hzn",
    },
};

export default sharedConfig;
