import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";

class SettingsStore extends BaseResourceStore<ISettingsMdl> {
    constructor() {
        super("settings");
    }
}

const settingsStore = new SettingsStore();
export { settingsStore };
