import React from "react";
import { slideShowsAdminStore } from "admin/slideShows/_stores/slideShowsAdminStore";
import { observer } from "mobx-react";
import { Slider } from "components/pages/slider/Slider";

type Props = {
    slideShowId: string;
    navigation: boolean;
    loop: boolean;
    autoplay?: boolean;
};

export const GenericSlider = observer((props: Props) => {
    const value = slideShowsAdminStore.getSync(props.slideShowId);
    if (!value) return null;

    return <Slider slider={value} navigation={props.navigation} loop={props.loop} autoplay={props.autoplay} />;
});
