import { action, computed, observable } from "mobx";
import { TVariantMdl } from "products/_models/VariantMdl";
import _ from "lodash";
import { SHIPPING_METHOD, TOrderLine, TOrderMdl } from "orders/_models/OrderMdl";
import i18next from "i18next";
import { giftCardsStore } from "giftCards/_stores/giftCardsStore";
import { GIFT_CARD_TYPE, TGiftCardMdl } from "giftCards/_models/GiftCardMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { DELIVERY_COSTS_CONFIG } from "_configs/deliveryCostsConfig";
import { productsStore } from "products/_stores/productsStore";
import { TAX_CONFIG } from "_configs/taxConfig";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { guid } from "_common/_utils/coreUtils";

const emptyCart = (id: string): Partial<TOrderMdl & { id: string }> => ({
    id,
    description: "",
    lines: [],
    subtotal: 0,
    total: 0,
    gst: 0,
    localeTax: 0,
});

const CART_NAME = "cart2.0";

class CartStore {
    @observable cart: TOrderMdl & { id: string } = emptyCart(guid()) as TOrderMdl & { id: string };
    @observable giftCard: TGiftCardMdl | undefined;
    @observable giftCardLoadingState: LoadingStateMdl<TGiftCardMdl> = new LoadingStateMdl<TGiftCardMdl>();
    @observable giftCardCreatedAfterPayment: Partial<TGiftCardMdl>[] | undefined;
    @observable regionForTax: string | undefined;

    constructor() {
        this.init();
    }

    @computed get isEmpty() {
        return this.cart.lines.length === 0;
    }

    @computed get regularTotal() {
        return this.cart.lines.reduce((current, line) => line.price * line.quantity + current, 0);
    }

    @computed get subtotal() {
        if (!this.isEmpty) {
            let discount = 0;
            if (this.giftCard) {
                switch (this.giftCard.kind) {
                    case "$":
                        discount = this.giftCard.value;
                        break;
                    case "%":
                        discount = (this.giftCard.value * this.regularTotal) / 10000;
                        break;
                }
            }
            return this.regularTotal - discount > 0 ? this.regularTotal - discount : 0;
        }
        return 0;
    }

    @computed get deliveryCost() {
        if (this.subtotal < DELIVERY_COSTS_CONFIG.limit && !this.isOnlyGiftCard) {
            return DELIVERY_COSTS_CONFIG.cost;
        } else {
            return 0;
        }
    }

    @computed get nameGst() {
        const provinceWithTaxs = TAX_CONFIG.find((province) => province.labelKey === this.regionForTax);
        if (!provinceWithTaxs) return 0;
        const tax = provinceWithTaxs.tax.find((tax) => tax.taxName === "TPS" || tax.taxName === "TVH");
        if (!tax) return null;
        return tax.taxName;
    }

    @computed get gst() {
        // TPS - TVH
        const provinceWithTaxs = TAX_CONFIG.find((province) => province.labelKey === this.regionForTax);
        if (!provinceWithTaxs) return 0;
        const taxLocal = provinceWithTaxs.tax.filter((tax) => tax.taxName === "TPS" || tax.taxName === "TVH");
        let rate = 0;
        taxLocal?.forEach((tax) => (rate += tax.rate));
        return (this.subtotal + this.deliveryCost) * (rate / 100);
    }

    @computed get nameLocaleTax() {
        const provinceWithTaxs = TAX_CONFIG.find((province) => province.labelKey === this.regionForTax);
        if (!provinceWithTaxs) return 0;
        const taxLocal = provinceWithTaxs.tax.find((tax) => tax.taxName === "TVQ");
        if (!taxLocal) return "";
        return taxLocal.taxName;
    }

    @computed get localeTax() {
        // TVP - TVQ
        const provinceWithTaxs = TAX_CONFIG.find((province) => province.labelKey === this.regionForTax);
        if (!provinceWithTaxs) return 0;
        const taxLocal = provinceWithTaxs.tax.filter((tax) => tax.taxName === "TVQ");
        let rate = 0;
        taxLocal?.forEach((tax) => (rate += tax.rate));
        return (this.subtotal + this.deliveryCost) * (rate / 100);
    }

    @computed get total() {
        return parseInt(
            (this.subtotal + this.deliveryCost + (this.localeTax ? this.localeTax : 0) + this.gst).toString(),
        );
    }

    @computed get giftCardLeft() {
        if (this.giftCard) {
            switch (this.giftCard.type) {
                case GIFT_CARD_TYPE.DISCOUNT:
                    return undefined;
                case GIFT_CARD_TYPE.GIFT_CARD:
                    return this.giftCard.value > this.regularTotal
                        ? this.giftCard.value - this.regularTotal
                        : undefined;
            }
        }
        return undefined;
    }

    @computed get isOnlyGiftCard() {
        const productsLines = this.cart.lines.filter((line) => line.codeGiftCard === undefined);
        if (productsLines.length > 0) {
            return false;
        }
        return true;
    }

    init() {
        if (__BROWSER__) {
            if (this.cart && this.cart.lines && this.cart.lines.length <= 0) {
                localStorage.removeItem("cart");
                const localStorageCart = localStorage.getItem(CART_NAME);
                if (!_.isNull(localStorageCart)) {
                    this.cart = (JSON.parse(localStorageCart) as unknown) as TOrderMdl & { id: string };
                }
            }
        }
    }

    @action addVariantItem(variant: TVariantMdl) {
        if (!variant._id) return;
        const item = this.getItemByVariantId(variant._id);
        if (item) item.quantity = item.quantity + 1;
        else {
            this.cart?.lines.push({
                variant: variant._id,
                price: variant.isPreorder ? productsStore.getPreorderPrice(variant.price) : variant.finalePrice,
                quantity: 1,
                text: variant.localized[i18next.language].name,
            });
        }
        this.save();
    }

    @action addItem(item: TOrderLine) {
        this.cart.lines.push(item);
        this.save();
    }

    @action removeItem(variant: TVariantMdl) {
        if (!variant._id) return;
        const item = this.getItemByVariantId(variant._id);
        if (item && item.quantity > 1) item.quantity = item.quantity - 1;
        this.save();
    }

    @action deleteVariantItem(variant: TVariantMdl) {
        if (!variant._id) return;
        const itemToDelete = this.getItemByVariantId(variant._id);
        if (!itemToDelete) return;
        this.cart.lines.splice(this.cart.lines.indexOf(itemToDelete), 1);
        this.save();
    }

    @action deleteItem(text: string | undefined, code?: string) {
        if (!text) return;
        const itemToDeleteIdx = this.cart.lines.findIndex((line) => line.text === text);
        if (itemToDeleteIdx > -1) {
            this.cart.lines.splice(itemToDeleteIdx, 1);
            this.save();
        }
        if (code) {
            const itemToDeleteIdxInGiftCardArray = this.giftCardCreatedAfterPayment?.findIndex(
                (giftCard) => giftCard.code === code,
            );
            this.giftCardCreatedAfterPayment?.splice(itemToDeleteIdxInGiftCardArray as number, 1);
            this.save();
        }
    }

    @action setGiftCard(giftCard: string | undefined, force = false) {
        this.cart.giftCard = giftCard;
        this.save();
        if (force) this.fetchGiftCard();
    }

    getItemByVariantId(variantId: string) {
        return this.cart?.lines.find((item) => item.variant === variantId);
    }

    save(hasOrdered = false) {
        if (__BROWSER__) localStorage.setItem(CART_NAME, JSON.stringify(this.cart));
        analyticsUtils.sendInBlueTracking(this.cart, hasOrdered);
    }

    resetAfterOrder() {
        this.cart = emptyCart(guid()) as TOrderMdl & { id: string };
        this.giftCard = undefined;
        this.giftCardCreatedAfterPayment = [];
        this.save(true);
    }

    fetchGiftCard() {
        if (!this.giftCardLoadingState.isLoading) {
            if (this.cart.giftCard) {
                this.giftCardLoadingState.startLoading();
                return giftCardsStore.getByCode(this.cart.giftCard).then(({ data }) => {
                    this.giftCard = data;
                    this.giftCardLoadingState.setSuccess(data);
                });
            } else this.giftCard = undefined;
        }
        return this.giftCardLoadingState;
    }

    @action addGiftCardCreatedAfterPayment(giftCard: Partial<TGiftCardMdl>) {
        if (!this.giftCardCreatedAfterPayment) {
            this.giftCardCreatedAfterPayment = [];
        }
        this.giftCardCreatedAfterPayment.push(giftCard);
        this.save();
    }

    @action setShippingMethod(shippingMethod: SHIPPING_METHOD) {
        this.cart.shippingMethod = shippingMethod;
    }
}

export const cartStore = new CartStore();
