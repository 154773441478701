import React from "react";
import styles from "./_css/videoComponent.module.css";
import clsx from "clsx";
import { UiButton } from "_common/ui/mui/buttons/UiButton";

enum VIDEO_TYPE {
    YOUTUBE = "youtube",
    VIMEO = "vimeo",
}

type Props = {
    url?: string;
    size?: "100%" | 360 | 720;
    controls: boolean;
    loop: boolean;
    title?: string;
    text?: string;
    cta?: string;
    ctaUrl?: string;
    style?: string;
};

function parseVideoUrl(url: string) {
    let type: VIDEO_TYPE | undefined;
    let id;

    const ytRegex = /https:\/\/youtu\.be\/[a-zA-Z0-9]*/;
    const vimeoRegex = /https:\/\/vimeo\.com\/[a-zA-Z0-9]*/;
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    if (url.match(ytRegex)) {
        type = VIDEO_TYPE.YOUTUBE;
        // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    } else if (url.match(vimeoRegex)) {
        type = VIDEO_TYPE.VIMEO;
    }

    const splittedUrl = url.split("/");
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    if (splittedUrl[splittedUrl.length - 1].match(/[a-zA-Z0-9]*/)) {
        id = splittedUrl[splittedUrl.length - 1];
    }

    return { type, id };
}

export function VideoComponent(props: Props) {
    const { type, id } = parseVideoUrl(props.url ?? "https://youtu.be/RB8PKb7DhDo");
    const width = props.size === 360 ? 640 : props.size === 720 ? 1280 : "100%";
    const height = props.size === 360 ? 360 : props.size === 720 ? 720 : "100%";
    const loop = props.text || props.title || props.cta ? 1 : props.loop;
    const controls = props.text || props.title || props.cta ? 0 : props.controls;
    const autoplay = !controls;
    const mute = controls ? 0 : 1;
    return (
        <div
            className={clsx(styles.container, props.style)}
            style={{ paddingBottom: props.size === 360 ? 360 : props.size === 720 ? 720 : "52.25%" }}
        >
            {type === VIDEO_TYPE.YOUTUBE && (
                <iframe
                    src={`https://www.youtube.com/embed/${id}?playlist=${id}&autoplay=${autoplay}&loop=${loop}&controls=${controls}&mute=${mute}`}
                    width={width}
                    height={height}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    name={"Video player" + props.title}
                    aria-label={"Video player" + props.title}
                />
            )}
            {type === VIDEO_TYPE.VIMEO && (
                <iframe
                    src={`https://player.vimeo.com/video/${id}?autoplay=${autoplay}&amp;loop=${loop}&amp;muted=${mute}`}
                    frameBorder="0"
                    width={width}
                    height={height}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    name={"Video player" + props.title}
                    aria-label={"Video player" + props.title}
                />
            )}
            {(props.cta || props.title) && (
                <div style={{ width, height }} className={clsx(styles.content, "flex_center_center")}>
                    <div className={"textAlign_center"}>
                        <h2 className={styles.title}>{props.title}</h2>
                        {props.cta && (
                            <div className="mt_20">
                                <a href={props.ctaUrl} className={styles.button}>
                                    <UiButton color={"primary"} variant={"contained"}>
                                        {props.cta}
                                    </UiButton>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
