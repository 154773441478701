import React, { useState } from "react";
import { TRootMenu } from "settings/_models/MenuMdl";
import styles from "./_css/rootMenuUser.module.css";
import clsx from "clsx";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import i18next from "i18next";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { SubGroupsMenuUser } from "main/menu/SubGroupsMenuUser";

type Props = {
    menu: TRootMenu;
    withLang?: boolean;
};

function renderInnerNav(props: Props, className: string) {
    return (
        <div className={clsx("flex_row_center", className, props.menu.className)}>
            {props.menu.image ? (
                <div className={styles.image} style={{ backgroundImage: `url('${props.menu.image}')` }} />
            ) : (
                props.menu.icon && <i style={{ marginRight: 5 , color: "white"}} className={"icon-" + props.menu.icon} />
            )}{" "}
            <div className="text_ellipsis">{props.menu.title}</div>
        </div>
    );
}

function NavContent(props: Props) {
    return props.menu.highlighted ? (
        <UiButton variant={"contained"} color={"primary"}>
            {renderInnerNav(props, styles.textHighlighted)}
        </UiButton>
    ) : (
        renderInnerNav(props, styles.text)
    );
}

export function RootMenuUser(props: Props) {
    const [menuOpened, setMenuOpened] = useState(false);
    const menuClassName = clsx(styles.menu, "flex_center_center");
    return (
        <div
            className={clsx(styles.container, {
                [styles.container_separator]: props.menu.separator,
            })}
            onMouseEnter={() => setMenuOpened(true)}
            onMouseLeave={() => setMenuOpened(false)}
        >
            {props.menu.url ? (
                <ExternalOrNavLink
                    className={menuClassName}
                    activeClassName={styles.menu_active_user}
                    url={(props.withLang ? "" : "/" + i18next.language) + props.menu.url}
                >
                    <NavContent {...props} />
                </ExternalOrNavLink>
            ) : (
                <div className={menuClassName}>
                    <NavContent {...props} />
                </div>
            )}
            {menuOpened && props.menu.subGroups?.length && <SubGroupsMenuUser subGroups={props.menu.subGroups} />}
        </div>
    );
}
