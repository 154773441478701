import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { productsStore } from "products/_stores/productsStore";
import { ShopPage } from "shop/ShopPage";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { useProductTypeFromParams } from "_common/_utils/hookUtils";

export function Shop() {
    const productType = useProductTypeFromParams();
    return (
        <LoadableFromLoading
            loading={productsStore.getListStore(productType).currentLoadingState}
            renderer={(_status, error) => {
                if (error) {
                    return <ErrorBlock error={error} />;
                }
                return <ShopPage />;
            }}
        />
    );
}
