import React from "react";
import { useLocation } from "react-router";
import { pagesStore } from "pages/_stores/pagesStore";
import { IPageMdl } from "pages/_models/PageMdl";
import { PageRenderer } from "pages/PageRenderer";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { PageNotFound } from "pages/PageNotFound";
import { URLS } from "_configs/URLS";
import Homepage from "homepage/Homepage";

export function PageRouter() {
    const location = useLocation();
    const syncPage = pagesStore.getByUrlSync(location.pathname, i18nextInstance.language as TLang);
    const page = syncPage ?? pagesStore.getByUrl(location.pathname, i18nextInstance.language as TLang);
    const { loading } = useLoadingFromPromise("then" in page ? (page as Promise<IPageMdl>) : undefined);
    if (
        location.pathname === URLS.home() ||
        location.pathname === "/" ||
        location.pathname === URLS.home() + "/" ||
        location.pathname === ""
    ) {
        return <Homepage />;
    }
    if (!__BROWSER__ && !syncPage) {
        return <div id={"SSR_page404NotFound"} />;
    }
    if ("then" in page) {
        if (!loading) return null;
        return (
            <LoadableFromLoading
                loading={loading}
                renderer={(_status, error, fetchedPage) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    if (!fetchedPage) {
                        return <PageNotFound redirect={{ link: URLS.home(), timer: 5000 }} />;
                    }
                    return <PageRenderer page={fetchedPage} />;
                }}
            />
        );
    }

    return <PageRenderer page={page} />;
}
