import React from "react";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import styles from "./_css/cartAddPanelContent.module.css";
import { URLS } from "_configs/URLS";
import { fillRelatedProductsIds } from "_common/_utils/productUtils";
import CartProductCard from "cart/CartProductCard";

type Props = {
    product: TProductLocalizedMdl;
};

const MAX_RELATED_PRODUCTS = 6;
const CartAddPanelContent = ({ product }: Props) => {
    const { t } = useTranslation();
    const relatedProducts = fillRelatedProductsIds(product, MAX_RELATED_PRODUCTS);
    return (
        <div className={styles.container}>
            <div className={styles.successContainer}>
                <p className={styles.success}>{t("cart.success")}</p>
                <div className={"flex_row justifyContent_spaceBetween flexWrap_wrap alignItems_center"}>
                    <div className={"flex_row alignItems_center"}>
                        {product.photos?.[0] && (
                            <img className={styles.image} src={product.photos[0].url} alt={product.localized.name} />
                        )}
                        <p className={"ml_10"}>{product.localized.name}</p>
                    </div>
                    <UiButton variant={"contained"} color={"primary"} size={"large"} href={URLS.cart()}>
                        {t("cart.seeCart")}
                    </UiButton>
                </div>
            </div>
            {relatedProducts && relatedProducts.length > 0 && (
                <div className={styles.continue}>
                    <p className={styles.continueTitle}>{t("cart.continueShopping")}</p>
                    <div className={styles.relatedProducts}>
                        {relatedProducts.map((productId) => {
                            return <CartProductCard key={productId} productId={productId} />;
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CartAddPanelContent;
