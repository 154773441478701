import React from "react";
import { Card, CardContent, Grid, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function OrderDocumentNoaioInfo() {
    const { t } = useTranslation();
    return (
        <Card elevation={0}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} />
                    <Grid item xs={8}>
                        {t("noaio.companyName")}
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={4}>
                            <span className="fontWeight_bold">{t("words.phone")}</span>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8}>
                        {t("noaio.phone")}
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={4}>
                            <span className="fontWeight_bold">{t("words.email")}</span>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8}>
                        {t("noaio.email")}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <span className="fontWeight_bold">{t("words.address")}</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {t("noaio.address")}
                    </Grid>
                    <Grid item xs={4}>
                        <span className="fontWeight_bold">{t("order.gst")}</span>
                    </Grid>
                    <Grid item xs={8}>
                        {t("noaio.gst")}
                    </Grid>
                    <Grid item xs={4}>
                        <span className="fontWeight_bold">{t("order.localeTax")}</span>
                    </Grid>
                    <Grid item xs={8}>
                        {t("noaio.qst")}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
