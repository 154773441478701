import React, { CSSProperties, useCallback, useState } from "react";
import { TComponent } from "pages/_models/PageMdl";
import clsx from "clsx";
import { Input } from "_common/ui/forms/Input";
import { useTranslation } from "react-i18next";
import styles from "./_css/newsLetterComponent.module.css";
import { emailsStore } from "emails/emailsStore";
import { toast } from "react-toastify";
import { userStore } from "users/_stores/userStore";
import { getErrorMessage } from "_common/errors/errorUtils";
import { isEmail } from "_common/_utils/coreUtils";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

type Props = {
    title?: string;
    text?: string;
    style?: CSSProperties;
    colors?: "light" | "dark";
    $component?: TComponent;
};

export function NewsLetterComponent({ colors = "dark", ...props }: Props) {
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = useCallback(() => {
        if (email && isEmail(email)) {
            emailsStore.subscribeNewsletter(email).then(
                () => {
                    toast.success(t("newsletter.success"));
                    userStore.setIsSubscribed(true);
                    setError("");
                    setEmail("");
                },
                (e) => {
                    toast.error(getErrorMessage(e));
                },
            );
        } else {
            setError(t("newsletter.error"));
        }
    }, [email]);

    return (
        <div
            className={clsx(styles.container, {
                [styles.darkColors]: colors === "dark",
                [styles.lightColors]: colors === "light",
            })}
            style={props.style ?? { paddingBottom: 96, paddingTop: 96 }}
        >
            <p className={styles.title}>{props.title ?? t("newsletter.title")}</p>
            <p className={styles.text}>{props.text ?? t("newsletter.desc")}</p>
            <div className={styles.inputContainer}>
                <Input
                    placeholder={t("newsletter.email")}
                    endIcon={
                        <div className={styles.icon}>
                            <ArrowForwardIcon />
                        </div>
                    }
                    endIconAction={handleSubmit}
                    type={"text"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={clsx({
                        [styles.darkColors]: colors === "dark",
                        [styles.lightColors]: colors === "light",
                    })}
                    error={error ? { type: "newsletter_error", message: error } : undefined}
                />
            </div>
        </div>
    );
}
