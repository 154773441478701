import { getResourceInitialStateValue } from "_common/_utils/initialStateUtils";
import { observable } from "mobx";
import { userStore } from "users/_stores/userStore";
import { TProductEditionMdl, TReviewEditionMdl } from "products/_models/ProductMdl";

class ReviewStore {
    @observable reviews: TReviewEditionMdl[] = [];
    @observable products: TProductEditionMdl[] = [];

    constructor() {
        if (__BROWSER__) {
            this.init();
        }
    }

    private init() {
        const products = getResourceInitialStateValue("products");
        if (!products || products.items || products.items.length === 0) return undefined;
        for (const product of products.items) {
            for (const review of product.reviews) {
                if (review.user._id === userStore.user?._id) {
                    this.reviews.push(review);
                    this.products.push(product);
                }
            }
        }
    }
}

const reviewStore = new ReviewStore();
export { reviewStore };
