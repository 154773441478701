import React from "react";
import { TCommentBaseMdl } from "./_models/CommentMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "products/reviews/_css/reviewInModal.module.css";
import { userStore } from "users/_stores/userStore";
import { InputBlock } from "_common/ui/forms/InputBlock";
import i18next from "i18next";
import { CommentInModalForm } from "comments/CommentInModalForm";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { CommentAddBtn } from "comments/CommentAddBtn";

type Props = {
    comment: TCommentBaseMdl;
    setComment: (comment: TCommentBaseMdl) => void;
    setIsOpening: (opened: boolean) => void;
};

export function CommentInModal(props: Props) {
    const { t } = useTranslation();
    const comment = props.comment;
    const form = useForm<TCommentBaseMdl>({
        mode: "onBlur",
        defaultValues: comment,
    });
    const user = !userStore.isLogged;
    return (
        <div className={clsx("mh_20 mb_20", styles.dialog)}>
            <h3 className="mb_20">{t("comment.giveComment")}</h3>
            <FormProvider {...form}>
                {user && (
                    <div className="flex_row">
                        <InputBlock className="mr_20" label={i18next.t("words.firstName")} required>
                            <Controller
                                name={"user.firstName"}
                                as={Input}
                                control={form.control}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                            />
                        </InputBlock>
                        <InputBlock label={i18next.t("words.lastName")} required>
                            <Controller
                                name={"user.lastName"}
                                as={Input}
                                control={form.control}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                            />
                        </InputBlock>
                    </div>
                )}
                <CommentInModalForm />
                <CommentAddBtn comment={comment} onCommentChange={props.setComment} setIsOpening={props.setIsOpening} />
            </FormProvider>
        </div>
    );
}
