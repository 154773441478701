import { TDef } from "admin/pages/builder/props/values/ValueEditor";
import { FILTER_TYPE, PRODUCT_TYPE } from "products/_models/ProductMdl";
import { UNIT_CONVERSION, UNITY } from "_common/_utils/unityUtils";
import { dimension, general, performance } from "_configs/productCategoryConfig";
import { TOpItems } from "admin/_common/filters/OpPicker";
import { WARRANTY_DURATIONS } from "_configs/warrantyConfig";
import batteryIcon from "../assets/images/battery.png";
import suspensionIcon from "../assets/images/suspension.png";
import speedIcon from "../assets/images/speed.png";
import weightIcon from "../assets/images/weight.png";

export const KEY_FEATURES_ICONS = {
    battery: batteryIcon,
    suspension: suspensionIcon,
    speed: speedIcon,
    weight: weightIcon,
};

export const chargerSuffix = "A";

export type TProductConfigDef = TDef & {
    key: string;
    required: boolean;
    type: string;
    card?: { icon: string; sort: number };
    types?: PRODUCT_TYPE[];
    unity?: { from: UNITY; to?: UNITY; ratio?: UNIT_CONVERSION };
    filter?: { sort: number; filterType: FILTER_TYPE; itemPath: PRODUCT_FILTER };
    productCategory?: { sort: number; labelKey: string; position: number };
    enumValues?: any[];
    op?: TOpItems;
};

export enum PRODUCT_FILTER {
    MOTOR = "options.motor",
    WEIGHT = "options.weight",
    WHEEL_DIAMETER = "options.wheelDiameter",
    MAX_SPEED = "options.maxSpeed",
    MAX_RANGE = "options.maxRange",
    TIRE_TYPE = "options.tireType",
    BRAND = "brand.label",
    PRICE = "minPrice",
    TITLE = "localized.name",
    SPP_TYPE = "options.sppType",
    ACCESSORY_TYPE = "options.accessoryType",
    SUB_TYPE = "subType",
    POSITION = "position",
}

export const PRODUCT_OPTIONS_CONFIG: TProductConfigDef[] = [
    {
        key: "motor",
        type: "number",
        required: false,
        unity: {
            from: UNITY.W,
        },
        card: {
            icon: "icon-zap",
            sort: 5,
        },
        onlyString: true,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        filter: {
            sort: 6,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.MOTOR,
        },
        productCategory: performance,
    },
    {
        key: "nbMotor",
        type: "enum",
        required: true,
        enumValues: [
            { value: "single", label: "Single" },
            { value: "dual", label: "Dual" },
        ],
        types: [PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
    },
    {
        key: "accessoryType",
        type: "enum",
        required: true,
        enumValues: [
            { value: "helmet" },
            { value: "powerPad" },
            { value: "bodyGuard" },
            { value: "seat" },
            { value: "light" },
            { value: "cover" },
            { value: "hoodies" },
            { value: "pedal" },
        ],
        types: [PRODUCT_TYPE.ACCESSORY],
        filter: {
            sort: 3,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.ACCESSORY_TYPE,
        },
    },
    {
        key: "sppType",
        type: "enum",
        required: true,
        enumValues: [
            { value: "battery" },
            { value: "motor" },
            { value: "sensor" },
            { value: "mudguard" },
            { value: "controller" },
            { value: "tire" },
            { value: "tube" },
            { value: "shell" },
            { value: "lightOrLed" },
            { value: "handle" },
            { value: "brakes" },
            { value: "electronicComponents" },
            { value: "charger" },
            { value: "pedalOrGrip" },
            { value: "speaker" },
        ],
        types: [PRODUCT_TYPE.SPP],
        filter: {
            sort: 3,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.SPP_TYPE,
        },
    },
    {
        key: "battery",
        type: "string",
        required: false,
        onlyString: true,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        card: {
            icon: "icon-briefcase1",
            sort: 6,
        },
        productCategory: performance,
    },
    {
        key: "weight",
        type: "number",
        required: false,
        card: {
            icon: "icon-user1",
            sort: 3,
        },
        unity: {
            from: UNITY.LB,
            to: UNITY.KG,
            ratio: UNIT_CONVERSION.KG_TO_LB,
        },
        filter: {
            sort: 5,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.WEIGHT,
        },
        productCategory: dimension,
    },
    {
        key: "height",
        type: "number",
        required: false,
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "width",
        type: "number",
        required: false,
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "length",
        type: "number",
        required: false,
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "foldedWidth",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "foldedHeight",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "foldedLength",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "pedalHeight",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "pedalWidth",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "pedalLength",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.CM,
        },
        productCategory: dimension,
    },
    {
        key: "wheelDiameter",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.INCH,
        },
        card: {
            icon: "icon-slash",
            sort: 2,
        },
        filter: {
            sort: 7,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.WHEEL_DIAMETER,
        },
        productCategory: dimension,
    },
    {
        key: "wheelWidth",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.INCH,
        },
        productCategory: dimension,
    },
    {
        key: "chargingTimeMinutes",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.MIN,
        },
        productCategory: performance,
    },
    {
        key: "maxWeight",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        productCategory: dimension,
        unity: {
            from: UNITY.LB,
            ratio: UNIT_CONVERSION.KG_TO_LB,
        },
    },
    {
        key: "maxSpeed",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.KMH,
        },
        card: {
            icon: "icon-wind",
            sort: 4,
        },
        filter: {
            sort: 4,
            filterType: FILTER_TYPE.SLIDER,
            itemPath: PRODUCT_FILTER.MAX_SPEED,
        },
        productCategory: { ...performance, position: 1 },
    },
    { key: "color", type: "color", required: false, onlyString: true, productCategory: general },
    {
        key: "waterproof",
        type: "string",
        required: false,
        onlyString: true,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        productCategory: general,
    },
    {
        key: "voltage",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.V,
        },
        productCategory: { ...performance, position: 2 },
    },
    {
        key: "ampere",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.A,
        },
        productCategory: performance,
    },
    {
        key: "charger",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.A,
        },
        productCategory: performance,
    },
    {
        key: "maxRange",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        unity: {
            from: UNITY.KM,
        },
        card: {
            icon: "icon-battery",
            sort: 1,
        },
        filter: {
            sort: 3,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.MAX_RANGE,
        },
        productCategory: performance,
    },
    {
        key: "minRange",
        type: "number",
        required: false,
        types: [PRODUCT_TYPE.NONE],
        unity: {
            from: UNITY.KM,
        },
    },
    {
        key: "tireType",
        type: "enum",
        required: true,
        enumValues: [
            { value: "street", label: "Street" },
            { value: "off-road", label: "Off-road" },
        ],
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.SCOOTER, PRODUCT_TYPE.REFURBISHED],
        filter: {
            sort: 9,
            filterType: FILTER_TYPE.CHECKBOX,
            itemPath: PRODUCT_FILTER.TIRE_TYPE,
        },
        productCategory: general,
    },
    {
        key: "suspensionType",
        type: "string",
        required: false,
        onlyString: true,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.REFURBISHED, PRODUCT_TYPE.SCOOTER],
        productCategory: general,
    },
    {
        key: "suspension",
        type: "boolean",
        required: false,
        types: [PRODUCT_TYPE.EUC, PRODUCT_TYPE.REFURBISHED, PRODUCT_TYPE.SCOOTER],
        productCategory: general,
    },
    {
        key: "warranty",
        type: "enum",
        enumValues: [
            { value: WARRANTY_DURATIONS.DEFAULT, label: "Par défaut" },
            { value: WARRANTY_DURATIONS.NONE, label: "Aucune garantie" },
            { value: WARRANTY_DURATIONS.SIX_MONTHS, label: "6 mois" },
            { value: WARRANTY_DURATIONS.ONE_YEAR, label: "1 an" },
            { value: WARRANTY_DURATIONS.TWO_YEARS, label: "2 ans" },
        ],
        required: false,
        types: [
            PRODUCT_TYPE.EUC,
            PRODUCT_TYPE.SCOOTER,
            PRODUCT_TYPE.REFURBISHED,
            PRODUCT_TYPE.SPP,
            PRODUCT_TYPE.ACCESSORY,
        ],
        productCategory: general,
    },
];

export const PRODUCT_COLOR = {
    "#FFFFFF": "white",
    "#C0C0C0": "silver",
    "#808080": "gray",
    "#000000": "black",
    "#FFA500": "orange",
    "#FF8C00": "darkorange",
    "#FF0000": "red",
    "#800000": "maroon",
    "#FFD700": "gold",
    "#FFFF00": "yellow",
    "#808000": "olive",
    "#00FF00": "lime",
    "#008000": "green",
    "#00FFFF": "aqua",
    "#008080": "teal",
    "#0000FF": "blue",
    "#000080": "navy",
    "#FF69B4": "pink",
    "#FF00FF": "fuchsia",
    "#800080": "purple",
    "#4B0082": "indigo",
};
