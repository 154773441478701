import React from "react";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import styles from "./_css/footerMenu.module.css";
import clsx from "clsx";

type Props = {
    title?: string;
    links: { url: string; title: string; nofollow?: boolean }[];
    className?: string;
};
export function FooterMenu(props: Props) {
    return (
        <nav className={clsx(props.className, "mb_20 flex_column")}>
            {props.title && <div className={clsx("mb_10", styles.title)}>{props.title}</div>}
            {props.links.map((link, index) => (
                <ExternalOrNavLink key={index} className={styles.link} nofollow={link.nofollow} url={link.url}>
                    {link.title}
                </ExternalOrNavLink>
            ))}
        </nav>
    );
}
