import React from "react";
import { useTranslation } from "react-i18next";
import { Container, NoSsr } from "@material-ui/core";
import { reviewStore } from "products/reviews/_stores/ReviewStore";
import { ReviewList } from "products/reviews/ReviewList";

export function ReviewsPage() {
    const { t } = useTranslation();
    const reviews = reviewStore.reviews;
    const products = reviewStore.products;

    return (
        <NoSsr>
            <Container className="mt_20 mb_40">
                <div>
                    <h2 className="flex_row_center flexWrap_wrap">
                        <div className="mr_15 mh_10 flex-1">{t("words.myReviews")}</div>
                    </h2>
                    <div className="mt_25">
                        <ReviewList reviews={reviews} products={products} />
                    </div>
                </div>
            </Container>
        </NoSsr>
    );
}
