import { action, computed, observable } from "mobx";
import { ORDER_TYPE, TOrderMdl } from "orders/_models/OrderMdl";
import { GIFT_CARD_TYPE, TGiftCardMdl } from "giftCards/_models/GiftCardMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import sharedConfig from "_configs/sharedConfig";
import { giftCardsStore } from "giftCards/_stores/giftCardsStore";
import { TPaymentBaseMdl } from "admin/payments/_models/CrudPaymentMdl";
import { DELIVERY_COSTS_CONFIG } from "_configs/deliveryCostsConfig";

const emptyOrder = () => ({ description: "", lines: [] });

export class OrderStore {
    @observable order: Partial<TOrderMdl> = emptyOrder();
    @observable giftCardLoadingState: LoadingStateMdl<TGiftCardMdl> = new LoadingStateMdl<TGiftCardMdl>();

    constructor(order: Partial<TOrderMdl>) {
        this.order = order;
    }

    @computed get isEmpty() {
        return this.order.lines?.length === 0;
    }

    @computed get regularTotal() {
        return (this.order.lines?.reduce((current, line) => line.price * line.quantity + current, 0) ?? 0) * 100;
    }

    @computed get subtotal() {
        if (!this.isEmpty) {
            let discount = 0;
            if (this.giftCard) {
                switch (this.giftCard.kind) {
                    case "$":
                        discount = this.giftCard.value;
                        break;
                    case "%":
                        discount = (this.giftCard.value * this.regularTotal * 100) / 10000;
                        break;
                }
            }
            return this.regularTotal - discount > 0 ? this.regularTotal - discount : 0;
        }
        return 0;
    }

    @computed get gst() {
        return (this.subtotal + this.deliveryCost) * sharedConfig.taxes.gst;
    }

    @computed get deliveryCost() {
        return this.subtotal < DELIVERY_COSTS_CONFIG.limit ? DELIVERY_COSTS_CONFIG.cost : 0;
    }

    @computed get localeTax() {
        return (this.subtotal + this.deliveryCost) * sharedConfig.taxes.qst;
    }

    @computed get taxes() {
        return this.gst + this.localeTax;
    }

    @computed get total() {
        return this.subtotal + this.deliveryCost + this.taxes;
    }

    @computed get giftCard() {
        if (!this.order.giftCard) return undefined;
        return giftCardsStore.getListStore().getSync(this.order.giftCard._id);
    }

    @computed get giftCardLeft() {
        if (this.giftCard) {
            switch (this.giftCard.type) {
                case GIFT_CARD_TYPE.DISCOUNT:
                    return undefined;
                case GIFT_CARD_TYPE.GIFT_CARD:
                    return this.giftCard.value > this.regularTotal
                        ? this.giftCard.value - this.regularTotal
                        : undefined;
            }
        }
        return undefined;
    }

    reset() {
        this.order = emptyOrder();
    }

    @action setType(newType: ORDER_TYPE) {
        this.order.type = newType;
    }

    @action setPayment(payment: TPaymentBaseMdl) {
        this.order.payment = payment._id;
    }
}
