import React from "react";
import { Container, NoSsr } from "@material-ui/core";
import { UiPaper } from "_common/ui/mui/surfaces/UiPaper";
import { useTranslation } from "react-i18next";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ordersStore } from "orders/_stores/ordersStore";
import { OrdersList } from "orders/OrdersList";

export function OrdersPage() {
    const { t } = useTranslation();
    return (
        <NoSsr>
            <Container className="mt_20 mb_40">
                <div>
                    <h2 className="flex_row_center flexWrap_wrap">
                        <div className="mr_15 mh_10 flex-1">{t("orders.title")}</div>
                    </h2>
                    <div className="mt_25">
                        <UiPaper className="p_25" color={"white"} elevation={0}>
                            <LoadableFromLoading
                                loading={ordersStore.fetchMyOrders()}
                                renderer={() => <OrdersList />}
                            />
                        </UiPaper>
                    </div>
                </div>
            </Container>
        </NoSsr>
    );
}
