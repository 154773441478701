import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { useProductStore } from "products/ProductContext";
import { GalleryProduct } from "gallery/GalleryProduct";
import { observer } from "mobx-react";

export const ProductGalleryPage = observer(() => {
    const productStore = useProductStore();
    return (
        <Dialog
            closeCross={true}
            closeSize={"large"}
            fullScreen
            open={productStore.isGalleryOpened > -1}
            onClose={() => productStore.closeGallery()}
        >
            <GalleryProduct />
        </Dialog>
    );
});
