import React from "react";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import Grid from "@material-ui/core/Grid";
import styles from "products/_css/eucCardsOptions.module.css";
import { PRODUCT_OPTIONS_CONFIG } from "_configs/productConfig";
import { CardOptionsValues } from "products/cards/CardOptionsValues";

type Props = {
    product: TProductLocalizedMdl;
};

export function EucCardsOptions(props: Props) {
    if (!props.product.options) return null;
    return (
        <Grid container className={styles.container}>
            {PRODUCT_OPTIONS_CONFIG.filter((config) => !!config.card)
                .sort((a, b) => (a.card && b.card && a.card?.sort > b.card?.sort ? 1 : -1))
                .map((optionConfig) => {
                    if (!optionConfig.card) return null;
                    return (
                        <CardOptionsValues key={optionConfig.key} optionConfig={optionConfig} product={props.product} />
                    );
                })}
        </Grid>
    );
}
