import React from "react";
import styles from "./_css/bestSeller.module.css";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import { productsStore } from "products/_stores/productsStore";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "products/ProductCard";

type Props = {
    productType: PRODUCT_TYPE;
};

const BestSeller = ({ productType }: Props) => {
    const { t } = useTranslation();
    const products = productsStore.getListStore(productType).items.slice(0, 5);
    return (
        <div className={styles.container}>
            <h2>
                {t("product.bestSellers")} - {t(`product.types.${productType}`)}
            </h2>
            <Swiper
                className={styles.swiper}
                slidesPerView={1.1}
                spaceBetween={16}
                breakpoints={{
                    400: {
                        slidesPerView: 1.3,
                    },
                    630: {
                        slidesPerView: 2,
                    },
                    700: {
                        slidesPerView: 2.5,
                    },
                    900: {
                        slidesPerView: 3,
                    },
                    1100: {
                        slidesPerView: 3.5,
                    },
                    1400: {
                        slidesPerView: 4,
                    },
                }}
            >
                {products.map((product) => {
                    if (!product) return null;
                    return (
                        <SwiperSlide key={product._id}>
                            <ProductCard productId={product._id} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default BestSeller;
