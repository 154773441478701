import React from "react";
import UiAccordion from "_common/ui/mui/surfaces/UiAccordion";
import { useTranslation } from "react-i18next";
import warrantyIcon from "../../assets/images/warrantyIcon.png";
import shippingIcon from "../../assets/images/shippingIcon.png";
import cardIcon from "../../assets/images/cardIcon.png";
import { useProductStore } from "products/ProductContext";
import { WARRANTY_DURATIONS, warrantyConfig } from "_configs/warrantyConfig";

const ProductServicesAccordion = () => {
    const { t } = useTranslation();
    const { product } = useProductStore();
    const warranty =
        !product.options.warranty || product.options.warranty === WARRANTY_DURATIONS.DEFAULT
            ? warrantyConfig[product.type].default
            : product.options.warranty;

    return (
        <div className={"mt_30"}>
            {warranty !== WARRANTY_DURATIONS.NONE && (
                <UiAccordion label={t("product.services.warranty")} icon={<img src={warrantyIcon} />}>
                    {t("product.services.warrantyText", { duration: t(`product.options.warranty.${warranty}`) })}
                </UiAccordion>
            )}
            <UiAccordion label={t("product.services.shipping")} icon={<img src={shippingIcon} />}>
                {t("product.services.shippingText")}
            </UiAccordion>
            <UiAccordion label={t("product.services.payment")} icon={<img src={cardIcon} />}>
                {t("product.services.paymentText")}
            </UiAccordion>
        </div>
    );
};

export default ProductServicesAccordion;
