import React from "react";
import { settingsStore } from "settings/_stores/settingsStore";
import { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import styles from "./_css/announcementBar.module.css";

const AnnouncementBar = () => {
    const announcement = settingsStore.getOne()?.announcement?.[i18nextInstance.language as TLang];

    if (!announcement) return null;
    return <div className={styles.container}>{announcement}</div>;
};

export default AnnouncementBar;
