import React from "react";
import clsx from "clsx";
import styles from "cart/_css/cartLine.module.css";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function CartHeader() {
    const { t } = useTranslation();
    return (
        <Paper elevation={0} className={clsx("flex_row p_20", styles.headerLine)}>
            <div className={clsx(styles.productQuantity, "flex_row")}>
                <div className={styles.product}>{t("cart.header.item")}</div>
                <div className={styles.quantity}>{t("cart.header.quantity")}</div>
            </div>
            <div className={clsx(styles.total, "justifyContent_flexEnd textAlign_right")}>{t("cart.header.total")}</div>
        </Paper>
    );
}
