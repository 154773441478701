import React from "react";
import { TMenuGroup } from "settings/_models/MenuMdl";
import styles from "./_css/subGroupsMenuUser.module.css";
import clsx from "clsx";
import { LeafMenuUser } from "main/menu/LeafMenuUser";

type Props = {
    subGroups: TMenuGroup[];
    userBar?: boolean;
};

export function SubGroupsMenuUser(props: Props) {
    return (
        <div className={styles.container}>
            <div className={styles.bodyWrapper}>
                <div className={clsx(styles.body, "flex_row")}>
                    {props.subGroups.map((subGroup, index) => (
                        <div key={index} className={styles.subGroup}>
                            {subGroup.title && <h4 className={styles.title}>{subGroup.title}</h4>}
                            {subGroup.items?.map((leafMenu, index) => (
                                <LeafMenuUser key={index} menu={leafMenu} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
