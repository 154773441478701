export const appConfig = {
    basePath: process.env.APP_URL as string,
    token: {
        storageKey: "TOKEN",
    },

    stripe: {
        publicKey: process.env.STRIPE_PUBLIC_KEY as string,
    },

    square: {
        appId: process.env.SQUARE_APP_ID as string,
        locationId: process.env.SQUARE_LOCATION_ID as string,
    },

    crisp: {
        id: "0b1bbb45-06a3-483f-b8fe-a891ca9b1139",
    },

    google: {
        analytics: "G-EVMR6Y1MV0",
        gtm: {
            gtmId: "GTM-5QGDL3R",
        },
        apiKey: process.env.GOOGLE_API_KEY as string,
    },

    facebook: {
        options: {
            autoConfig: true,
            debug: true,
        },
    },
};
