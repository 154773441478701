import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { productsStore } from "products/_stores/productsStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { PaginatedProducts } from "components/sections/productList/paginatedProducts";

export function ProductsComponent() {
    return (
        <div>
            <LoadableFromLoading
                loading={productsStore.getListStore().currentLoadingState}
                renderer={(_status, error) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    return <PaginatedProducts />;
                }}
            />
        </div>
    );
}
