import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TSlideShowMdl } from "slideShows/_models/SlideShowMdl";
import { ListStore } from "_common/list/ListStore";

class SlideShowsStore extends BaseResourceStore<TSlideShowMdl> {
    constructor() {
        super("slideShows");
    }

    protected onInit(fromRootCtor?: boolean) {
        super.onInit(fromRootCtor);
        const slideShows = Object.values(this.cache) as TSlideShowMdl[];
        if (slideShows.length === 0) return;
        this.listsStores["slideShows"] = new ListStore<TSlideShowMdl>(
            "slideShows",
            this,
            {
                count: slideShows.length,
                pages: [slideShows],
            },
            Number.MAX_SAFE_INTEGER,
        );
    }
}

export const slideShowsStore = new SlideShowsStore();
