import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import styles from "./_css/uiButton.module.css";

type Props = ButtonProps & {
    isDeleteButton?: boolean;
};

export const UiButton = ({ isDeleteButton, ...props }: Props) => {
    return (
        <Button
            {...props}
            className={clsx(styles.container, { [styles.containerForDelete]: isDeleteButton }, props.className)}
            classes={{
                root: styles.root,
                label: styles.label,
                sizeSmall: styles.sizeSmall,
                sizeLarge: styles.sizeLarge,
                contained: styles.contained,
                outlinedPrimary: styles.outlinedPrimary,
                containedPrimary: styles.containedPrimary,
            }}
        />
    );
};
