import React from "react";
import { ORDER_TYPE } from "orders/_models/OrderMdl";
import clsx from "clsx";
import styles from "./_css/orderTypeLabel.module.css";
import { useTranslation } from "react-i18next";

type Props = {
    type: ORDER_TYPE;
    label?: string;
};

export function OrderTypeLabel(props: Props) {
    const { t } = useTranslation();
    return (
        <div className="flex_row">
            <div
                className={clsx(styles.default, {
                    [styles.INVOICE]: props.type === ORDER_TYPE.INVOICE,
                    [styles.QUOTATION]: props.type === ORDER_TYPE.QUOTATION,
                    [styles.PREORDER_INVOICE]: props.type === ORDER_TYPE.PREORDER_INVOICE,
                    [styles.ACCEPTED_QUOTATION]: props.type === ORDER_TYPE.ACCEPTED_QUOTATION,
                    [styles.DECLINED_QUOTATION]: props.type === ORDER_TYPE.DECLINED_QUOTATION,
                    [styles.REFUND]: props.type === ORDER_TYPE.REFUND_INVOICE,
                })}
            >
                {props.label ?? t("order.types." + props.type)}
            </div>
        </div>
    );
}
