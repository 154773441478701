import React from "react";
import { useProductStore } from "products/ProductContext";
import { ProductReview } from "products/reviews/ProductReview";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import styles from "./_css/productReviewsList.module.css";
import clsx from "clsx";

export function ProductReviewsList() {
    const { t } = useTranslation();
    const { product } = useProductStore();
    return (
        <Grid container>
            {product.reviews.length > 0 ? (
                <div className={styles.reviewsContainer}>
                    {product.reviews.map((review) => {
                        if (review.checked && review.isDisplay) {
                            return <ProductReview key={review._id} review={review} />;
                        }
                    })}
                </div>
            ) : (
                <div className={"flex_center_center flex_column flex-1 mv_30"}>
                    <p className={styles.noComment}>{t("product.comments.noComment")}</p>
                    <p className={clsx(styles.noCommentText, "mt_10")}>{t("product.comments.noCommentText")}</p>
                </div>
            )}
        </Grid>
    );
}
