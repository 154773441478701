import React, { useState } from "react";
import clsx from "clsx";
import styles from "./_css/giftCard.module.css";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { GIFT_CARD_TYPE, TGiftCardMdl } from "giftCards/_models/GiftCardMdl";
import dayjs from "dayjs";
import { VARIANT_STATUS } from "products/_models/VariantMdl";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { InputDate } from "_common/ui/forms/InputDate";
import { TextArea } from "_common/ui/forms/TextArea";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { cartStore } from "cart/_stores/cartStore";
import { toast } from "react-toastify";
import { CartAddToast } from "cart/CartAddToast";
import { s6 } from "_common/_utils/coreUtils";
import { Checkbox, NoSsr, Slider } from "@material-ui/core";
import giftCard from "../assets/images/giftCard.svg";
import { isNumber } from "_common/_utils/currencyUtils";

const giftCardDefault: Partial<TGiftCardMdl> = {
    value: 0,
    kind: "$",
    code: s6(),
    status: VARIANT_STATUS.ACTIVE,
    type: GIFT_CARD_TYPE.GIFT_CARD,
    startDate: dayjs().toDate(),
    isGift: {
        sender: "",
        senderMail: "",
        recipient: "",
        recipientMail: "",
        message: "",
        sendNow: true,
    },
};

export function GiftCardPage() {
    const { t } = useTranslation();
    const form = useForm<Partial<TGiftCardMdl>>({
        mode: "onBlur",
        defaultValues: giftCardDefault,
    });
    const [value, setValue] = useState(50);
    const [editValue, setEditValue] = useState(false);
    const max = 300;
    const min = 10;

    return (
        <div className={styles.container}>
            <div className={"flex-1 empty_column"} />
            <FormProvider {...form}>
                <div className={clsx("flex-1", styles.column_center_left)}>
                    <div className={styles.title}> {t("giftCard.selectAmount")} </div>

                    <div className={styles.container_slider}>
                        <div
                            className={styles.icon_moin}
                            onClick={() => {
                                if (value - 10 >= 0) setValue(value - 10);
                            }}
                        >
                            {" "}
                            -{" "}
                        </div>
                        <Slider
                            className={styles.slider}
                            min={min}
                            max={max}
                            step={5}
                            value={value}
                            onChange={(_e, v) => {
                                setValue(v as number);
                            }}
                        />
                        <div
                            className={styles.icon_more}
                            onClick={() => {
                                if (value + 10 <= 300) setValue(value + 10);
                            }}
                        >
                            {" "}
                            +{" "}
                        </div>
                    </div>
                    <div className={styles.value_slider} onClick={() => setEditValue(!editValue)}>
                        <input
                            className={styles.inputValue}
                            type={"text"}
                            readOnly={editValue}
                            value={value}
                            onChange={(e) => {
                                if (!isNaN(parseFloat(e.target.value)) && parseFloat(e.target.value) < max + 1) {
                                    setValue(parseFloat(e.target.value));
                                } else {
                                    setValue(0);
                                }
                            }}
                        />
                        $CA
                    </div>
                    <img src={giftCard} className={styles.imgGiftCard} alt={"img"} />
                </div>
                <div className={clsx("flex-1", styles.column_center_right)}>
                    <div className={styles.text}>{t("giftCard.text")}</div>
                    <InputBlock label={t("giftCard.senderName")}>
                        <Controller
                            name={"isGift.sender"}
                            type={"text"}
                            as={Input}
                            control={form.control}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            error={form.errors.isGift?.sender}
                        />
                    </InputBlock>
                    <InputBlock label={t("giftCard.senderMail")}>
                        <Controller
                            name={"isGift.senderMail"}
                            type={"text"}
                            as={Input}
                            rules={{
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            }}
                            control={form.control}
                            error={form.errors.isGift?.senderMail}
                        />
                    </InputBlock>
                    <InputBlock label={t("giftCard.recipientName")}>
                        <Controller
                            name={"isGift.recipient"}
                            type={"text"}
                            as={Input}
                            control={form.control}
                            rules={{
                                required: t<string>("errors.forms.required"),
                            }}
                            error={form.errors.isGift?.recipient}
                        />
                    </InputBlock>
                    <InputBlock label={t("giftCard.recipientMail")}>
                        <Controller
                            name={"isGift.recipientMail"}
                            type={"text"}
                            as={Input}
                            rules={{
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            }}
                            control={form.control}
                            error={form.errors.isGift?.recipientMail}
                        />
                    </InputBlock>
                    <div className={"flex_row"}>
                        <InputBlock label={t("giftCard.startDate")}>
                            <Controller
                                name={"startDate"}
                                as={InputDate}
                                control={form.control}
                                rules={{
                                    required: t<string>("errors.forms.required"),
                                }}
                            />
                        </InputBlock>
                        <NoSsr>
                            <InputBlock label={t("giftCard.sendNow")}>
                                <Controller name={"isGift.sendNow"} as={Checkbox} control={form.control} />
                            </InputBlock>
                        </NoSsr>
                    </div>
                    <InputBlock label={t("giftCard.message")}>
                        <Controller name={"isGift.message"} as={TextArea} rows={6} control={form.control} />
                    </InputBlock>
                    <UiButton
                        variant={"contained"}
                        color={"primary"}
                        onClick={form.handleSubmit<Partial<TGiftCardMdl>>((data) => {
                            if (
                                value < 1 ||
                                !isNumber(value) ||
                                !dayjs(data.startDate as Date).isValid() ||
                                (data.isGift?.sendNow &&
                                    dayjs(data.startDate as Date).format("DD-MM-YYYY") != dayjs().format("DD-MM-YYYY"))
                            ) {
                                toast.error(t("errors.forms.fieldInvalid"));
                            } else {
                                const _data: Partial<TGiftCardMdl> = {
                                    ...data,
                                    value: value * 100,
                                    kind: "$",
                                    code: s6(),
                                    status: VARIANT_STATUS.ACTIVE,
                                    type: GIFT_CARD_TYPE.GIFT_CARD,
                                    startDate: dayjs(data.startDate as Date)
                                        .startOf("day")
                                        .toDate(),
                                    endDate: dayjs(data.startDate as Date)
                                        .add(1, "year")
                                        .endOf("day")
                                        .toDate(),
                                    updatedAt: dayjs(),
                                    createdAt: dayjs(),
                                };
                                cartStore.addGiftCardCreatedAfterPayment(_data);
                                cartStore.addItem({
                                    text: t("words.giftCard"),
                                    quantity: 1,
                                    price: value * 100,
                                    codeGiftCard: _data.code,
                                });
                                toast.info(<CartAddToast text={t("words.giftCard")} />, {
                                    type: "default",
                                    hideProgressBar: true,
                                    position: "top-center",
                                    pauseOnFocusLoss: false,
                                });
                                form.reset();
                            }
                        })}
                    >
                        <i className={clsx(styles.icon, "icon-shopping-cart mr_10")} /> {t("cart.addToCart")}
                    </UiButton>
                </div>
            </FormProvider>
            <div className={"flex-1 empty_column"} />
        </div>
    );
}
