import React from "react";
import { useProductStore } from "products/ProductContext";
import { PRODUCT_OPTIONS_CONFIG, TProductConfigDef } from "_configs/productConfig";
import styles from "./_css/productFeatures.module.css";
import { TVariantMdl } from "products/_models/VariantMdl";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import i18next from "i18next";
import { ProductFeature } from "products/ProductFeature";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import { ProductDimensions } from "products/ProductDimensions";

function isVariantOptionNull(variant: TVariantMdl | undefined, option: TProductConfigDef) {
    if (!variant || variant.options[option.key] === "") return undefined;
    return variant?.options[option.key];
}

type Props = {
    hidden?: boolean;
};

export const ProductFeatures = observer(({ hidden }: Props) => {
    const productStore = useProductStore();
    const { t } = useTranslation();
    let previousCategory: undefined | string = undefined;
    const productType = productStore.product.type;
    const isVehicule = productType === PRODUCT_TYPE.EUC || productType === PRODUCT_TYPE.SCOOTER;

    return (
        <Container style={{ display: hidden ? "none" : "block" }}>
            {isVehicule && <ProductDimensions product={productStore.product} />}
            <Grid spacing={3} className={clsx(styles.container, "pv_20 ph_20")} container>
                {PRODUCT_OPTIONS_CONFIG.filter(
                    (config) => !config.types || config.types?.includes(productStore.product.type),
                )
                    .sort((a, b) =>
                        a.productCategory && b.productCategory && a.productCategory.sort > b.productCategory.sort
                            ? 1
                            : -1,
                    )
                    .map((optionConfig) => {
                        const currentCategory = optionConfig.productCategory?.labelKey;
                        const hasHeader = currentCategory !== previousCategory;
                        previousCategory = currentCategory;

                        const selectedVariantOption = isVariantOptionNull(productStore.selectedVariant, optionConfig);
                        let value = selectedVariantOption ?? productStore.product.options[optionConfig.key];
                        let preKeyLabel = "product.options.characteristics.";
                        if (optionConfig.type === "enum") {
                            preKeyLabel = "product.options.enum.";
                            value = t("product.options." + optionConfig.key + "." + value);
                        }
                        let unit = optionConfig.unity?.from ? i18next.t("units." + optionConfig.unity?.from) : "";
                        if (!productStore.product.options[optionConfig.key]) return null;
                        if (optionConfig.key === "chargingTimeMinutes") {
                            value = Math.round((value / 60) * 10) / 10;
                            unit = t("units.H");
                        }
                        if (optionConfig.type === "boolean") {
                            value = productStore.product.options[optionConfig.key] ? t("words.yes") : t("words.no");
                        }
                        return (
                            <React.Fragment key={optionConfig.key}>
                                {hasHeader && currentCategory && (
                                    <Grid item xs={12} className={clsx("mt_20 pl_0", styles.titleCell)}>
                                        <h4 className={styles.labelCategory}>{t(currentCategory)}</h4>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6} lg={4} className={styles.cell}>
                                    <ProductFeature
                                        value={value}
                                        optionConfig={optionConfig}
                                        preKeyLabel={preKeyLabel}
                                        unit={unit}
                                    />
                                </Grid>
                            </React.Fragment>
                        );
                    })}
            </Grid>
        </Container>
    );
});
