import React, { useEffect } from "react";
import { cartStore } from "cart/_stores/cartStore";
import { observer } from "mobx-react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { CartGiftCardView } from "cart/giftCard/CartGiftCardView";

export const CartGiftCard = observer(() => {
    useEffect(() => {
        cartStore.fetchGiftCard();
    }, []);

    return <LoadableFromLoading loading={cartStore.giftCardLoadingState} renderer={() => <CartGiftCardView />} />;
});
