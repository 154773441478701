import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment-timezone";
import styles from "./_css/inputDate.module.css";
import dayjs, { Dayjs } from "dayjs";

type Props = {
    value?: Dayjs | string | Date;
    onChange?: (newValue: string) => void;
};

export const InputDate = (props: Props) => {
    const isDayjsType = typeof props.value == "object" ? "$y" in (props.value as any) : false;
    return (
        <div className={styles.input}>
            <Datetime
                timeFormat={false}
                onChange={(newDate) =>
                    props.onChange ? props.onChange(moment(newDate).toDate().toString()) : undefined
                }
                initialValue={
                    typeof props.value === "string"
                        ? dayjs(props.value ?? "").toDate()
                        : isDayjsType
                        ? (props.value as Dayjs)?.toDate()
                        : props.value
                }
            />
        </div>
    );
};
