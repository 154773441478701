import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TSlideShowMdl } from "slideShows/_models/SlideShowMdl";

class SlideShowsAdminStore extends BaseResourceStore<TSlideShowMdl> {
    constructor() {
        super("slideShows");
    }

    async setBanner(
        bannerType: "isHomepageBanner" | "isPopup",
        newSlideShow?: TSlideShowMdl,
        oldSlideShow?: TSlideShowMdl,
    ) {
        if (oldSlideShow) {
            await this.update({ ...oldSlideShow, [bannerType]: false });
        }
        if (newSlideShow) {
            return this.update({ ...newSlideShow, [bannerType]: true });
        }
        return;
    }
}

const slideShowsAdminStore = new SlideShowsAdminStore();
export { slideShowsAdminStore };
