import React from "react";
import { Grid } from "@material-ui/core";
import { CommentsAdd } from "comments/CommentsAdd";
import { CommentsList } from "comments/CommentsList";
import { TCommentMdl } from "comments/_models/CommentMdl";

type Props = {
    comments: TCommentMdl[];
    pageId: string;
};

export function Comments(props: Props) {
    return (
        <Grid container spacing={3} className="pv_20">
            <Grid item xs={12}>
                <CommentsList comments={props.comments} pageId={props.pageId} />
            </Grid>
            <Grid item xs={12}>
                <CommentsAdd pageId={props.pageId} />
            </Grid>
        </Grid>
    );
}
