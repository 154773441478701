import { TKeyFeatureBaseMdl, TKeyFeatureLocalizedMdl, TOption } from "products/_models/ProductMdl";
import { Dayjs } from "dayjs";

type TLocalized = { name: string };

export type TDiscount = {
    kind?: "$" | "%";
    value: number;
    startDate?: Date;
    endDate?: Date;
    status?: VARIANT_STATUS;
};

export enum VARIANT_STATUS {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export type TVariantBaseMdl = {
    _id?: string;
    localized: { [lang: string]: TLocalized };
    price: number;
    stock: number;
    options: TOption;
    status: VARIANT_STATUS;
    discount?: TDiscount;
    finalePrice: number;
    isPreorder: boolean;
    deliveryDate: Dayjs;
    sellingLink: string;
    keyFeatures?: TKeyFeatureBaseMdl[];
    sku?: string;
};

export type TVariantMdl = TVariantBaseMdl & {
    keyFeatures?: TKeyFeatureLocalizedMdl[];
};
