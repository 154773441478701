import React from "react";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import ProductComparisonBlock from "products/comparison/ProductComparisonBlock";
import { useProductStore } from "products/ProductContext";
import { observer } from "mobx-react";

type Props = {
    products: TProductLocalizedMdl[];
    removeProduct: (product: TProductLocalizedMdl) => void;
};

const ProductComparisonList = observer(({ products, removeProduct }: Props) => {
    const { product } = useProductStore();
    return (
        <div className={"flex_row"}>
            <ProductComparisonBlock product={product} baseProduct />
            {products.map((product) => {
                return <ProductComparisonBlock key={product._id} product={product} removeProduct={removeProduct} />;
            })}
        </div>
    );
});

export default ProductComparisonList;
