import React from "react";
import { SignInFormBlock } from "users/auth/blocks/SignInFormBlock";
import { URLS } from "_configs/URLS";
import { CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./_css/signInModal.module.css";

type Props = {
    onSuccess: (success: boolean) => void;
    backUrl: string;
};

export function SignInModal(props: Props) {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <SignInFormBlock backUrl={props.backUrl} onSuccess={(success) => props.onSuccess(success)} />
            <hr />
            <CardContent className="flex_center_center flex_column">
                <div>{t("auth.signUp.notRegisteredYet")}</div>
                <Link to={URLS.auth.signUp()} className="link">
                    {t("auth.signUp.title")}
                </Link>
            </CardContent>
        </div>
    );
}
