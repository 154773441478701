import React, { useState } from "react";
import { Container } from "@material-ui/core";
import { CartView } from "cart/CartView";
import { CheckoutPage } from "checkout/CheckoutPage";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/cartPage.module.css";
import { CheckoutThanksPage } from "checkout/CheckoutThanksPage";
import { TPaymentBaseMdl } from "admin/payments/_models/CrudPaymentMdl";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { CartInterestedProducts } from "cart/giftCard/CartInterestedProducts";

export enum CHECKOUT_STEP {
    CART = "CART",
    CHECK_OUT = "CHECK_OUT",
    PAID = "PAID",
}

export function CartPage() {
    const [step, setStep] = useState<CHECKOUT_STEP>(CHECKOUT_STEP.CART);
    const [payment, setPayment] = useState<TPaymentBaseMdl>();
    const { t } = useTranslation();

    function renderStep() {
        switch (step) {
            case CHECKOUT_STEP.CART:
                return (
                    <CartView
                        onProcessing={() => {
                            analyticsUtils.trackEcommerceEventDeferred(
                                {
                                    name: "setAction",
                                    type: "checkout",
                                },
                                {
                                    step: 2,
                                },
                                window.location.pathname,
                                true,
                            );
                            setStep(CHECKOUT_STEP.CHECK_OUT);
                        }}
                    />
                );
            case CHECKOUT_STEP.CHECK_OUT:
                return (
                    <CheckoutPage
                        onBackToCart={() => setStep(CHECKOUT_STEP.CART)}
                        onSuccessPayment={(payment) => {
                            setPayment(payment);
                            setStep(CHECKOUT_STEP.PAID);
                        }}
                    />
                );
            case CHECKOUT_STEP.PAID:
                return <CheckoutThanksPage payment={payment} />;
        }
    }
    return (
        <Container className="mb_20">
            <div className={clsx(styles.container, "flex_row_center justifyContent_spaceBetween")}>
                <div>
                    <UiButton
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => (step !== CHECKOUT_STEP.PAID ? setStep(CHECKOUT_STEP.CART) : undefined)}
                    >
                        1. {t("checkout.steps.cart")}
                    </UiButton>
                </div>
                <div className={styles.arrow}>
                    <i className="icon-chevron-right" />
                    <i className="icon-chevron-right" />
                    <i className="icon-chevron-right" />
                </div>
                <div>
                    <UiButton color={"primary"} variant={step === CHECKOUT_STEP.CART ? "outlined" : "contained"}>
                        2. {t("checkout.steps.checkout")}
                    </UiButton>
                </div>
                <div className={styles.arrow}>
                    <i className="icon-chevron-right" />
                    <i className="icon-chevron-right" />
                    <i className="icon-chevron-right" />
                </div>
                <div>
                    <UiButton color={"primary"} variant={step === CHECKOUT_STEP.PAID ? "contained" : "outlined"}>
                        3. {t("checkout.steps.paid")} <i className="icon-check" />
                    </UiButton>
                </div>
            </div>
            {renderStep()}
            <CartInterestedProducts/>
        </Container>

    );
}
