import React from "react";
import { useParams } from "react-router";
import { userStore } from "users/_stores/userStore";
import { OrderView } from "orders/OrderView";
import { NoSsr } from "@material-ui/core";
import { Loader } from "_common/loaders/Loader";
import { isGuid } from "_common/_utils/coreUtils";

export function OrderPage() {
    const { tokenOrOrderId } = useParams();
    if (!tokenOrOrderId) return null;
    let token = undefined;
    let orderId = undefined;
    if (isGuid(tokenOrOrderId)) token = tokenOrOrderId;
    else orderId = tokenOrOrderId;
    return (
        <NoSsr fallback={<Loader />}>
            {(token || (orderId && userStore.isLogged)) && (
                <div>{orderId ? <OrderView orderId={orderId} /> : <OrderView token={token} />}</div>
            )}
        </NoSsr>
    );
}
