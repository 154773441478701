import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "products/_css/eucCardsOptions.module.css";
import i18next from "i18next";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { TProductConfigDef } from "_configs/productConfig";
import clsx from "clsx";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";

type Props = {
    optionConfig: TProductConfigDef;
    product: TProductLocalizedMdl;
};

export function CardOptionsValues(props: Props) {
    const optionConfig = props.optionConfig;
    let value = props.product.options[optionConfig.key];
    if (!value) return null;
    const unit = optionConfig.unity;
    switch (optionConfig.key) {
        case "battery":
            value = value.substr(0, value.indexOf("W")).trim() ?? null;
            value += "Wh";
            break;
        case "wheelDiameter":
            value = value + "x" + props.product.options["wheelWidth"];
            break;
        case "weight":
            value = convert(value, UNIT_CONVERSION.KG_TO_LB);
    }
    const isNotSpace = optionConfig.key !== "wheelDiameter" && optionConfig.key !== "motor";

    return (
        <Grid key={optionConfig.key} item xs={4} className={styles.option}>
            <div className={clsx("mb_3", styles.optionLabel)}>
                {i18next.t("product.card.options." + optionConfig.key)}
            </div>
            <div className={clsx("mt_5", styles.textOptions)}>
                {value}
                {isNotSpace && " "}
                {unit && i18next.t("units." + unit?.from)}
            </div>
        </Grid>
    );
}
