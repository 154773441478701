import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";

class ContactsStore {
    async sendContact(data: any) {
        return fetchUtils.post(sharedConfig.apiUrl + "/emails/send", data);
    }
}

const contactsStore = new ContactsStore();
export { contactsStore };
