import React from "react";
import { useProductStore } from "products/ProductContext";
import { ProductVariantPicker } from "products/variants/ProductVariantPicker";
import { observer } from "mobx-react";
import clsx from "clsx";
import styles from "./_css/productVariants.module.css";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import dayjs from "dayjs";

export const ProductVariants = observer(() => {
    const productStore = useProductStore();
    const { t } = useTranslation();
    if (!productStore.product.variants) return null;
    const noStock = productStore.selectedVariant && productStore.selectedVariant.stock <= 0;
    const singleVariant = productStore.product.variants.length <= 1;

    return (
        <div className={clsx(styles.container)}>
            {!singleVariant && (
                <div className="mr_10 mt_30">
                    <ProductVariantPicker
                        product={productStore.product}
                        defaultVariantId={productStore.selectedVariantId}
                        onVariantChange={(variantId) => productStore.selectVariant(variantId)}
                    />
                </div>
            )}
            <div>
                {productStore.selectedVariant?.isPreorder || noStock ? (
                    <Tooltip title={t("words.ETA").toString()} placement={"bottom-start"}>
                        <div className={clsx(styles.stock, "text_error")}>
                            {productStore?.selectedVariant?.deliveryDate
                                ? t("product.ETA", {
                                      nbOfWeeks:
                                          dayjs().diff(productStore.selectedVariant.deliveryDate, "w") === 0
                                              ? 1
                                              : Math.abs(dayjs().diff(productStore.selectedVariant.deliveryDate, "w")),
                                  })
                                : t("product.ETA", {
                                      nbOfWeeks: "3-4",
                                  })}
                        </div>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
});
