import React from "react";
import { Grid } from "@material-ui/core";
import { productsStore } from "products/_stores/productsStore";

export function PaginatedProducts() {
    const productList = productsStore.getListStore();
    return (
        <div>
            {productList.items && (
                <Grid container alignItems="flex-start" spacing={3}>
                    {productList.items.map((product) => {
                        if (!product) return null;
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
                                <ProductCard productId={product._id} />;
                            </Grid>
                        );
                    })}
                </Grid>
            )}
            Liste des produits
        </div>
    );
}
