export enum UNIT_CONVERSION {
    KG_TO_LB = 2.20462,
    LB_TO_KG = 0.4535,
    CM_TO_IN = 0.393701,
    KM_TO_MI = 0.621371,
}

export enum UNITY {
    KG = "KG",
    CM = "CM",
    KM = "KM",
    W = "W",
    KMH = "KMH",
    LB = "LB",
    INCH = "INCH",
    MI = "MI",
    WH = "WH",
    MIN = "MIN",
    V = "V",
    A = "A",
}

export function convert(value: number, fromToRatio: UNIT_CONVERSION): number {
    return Math.round(value * fromToRatio * 10) / 10;
}
