import React, { useState } from "react";
import { observer } from "mobx-react";
import { TProductEditionMdl, TReviewEditionMdl, TReviewMdl } from "products/_models/ProductMdl";
import { ProductReviewForm } from "products/reviews/ProductReviewForm";
import dayjs from "dayjs";
import { Card } from "@material-ui/core";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import styles from "./_css/review.module.css";
import { ProductAdminStore } from "products/_stores/ProductAdminStore";
import { toast } from "react-toastify";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { ConfirmationDialog } from "_common/ui/dialogs/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type Props = {
    review: TReviewEditionMdl;
    product: TProductEditionMdl;
    onValidated?: (boolean: boolean) => void;
};

export const Review = observer((props: Props) => {
    const { t } = useTranslation();
    const productStore = new ProductAdminStore(props.product);
    if (!props.review._id) return null;
    const review = productStore.getReviewById(props.review._id);
    if (!review) return null;
    const [updateReview, setUpdateReview] = useState<TReviewMdl>({
        ...review,
        dateCreation: !review.dateCreation ? review.createdAt : review.dateCreation,
    });

    const [open, setOpen] = useState<boolean>(false);

    return (
        <Card elevation={0} className={clsx("p_20 mb_20")}>
            {review.checked && <h3 className={"textAlign_right"}>{t("review.reviewValidate")}</h3>}
            <div className={clsx({ [styles.disabled]: review.checked })}>
                <div className={styles.label}>{t("review.reviewDate")}</div>
                <div>{dayjs(review?.dateCreation).format(t("dates.formats.full"))}</div>
                <ProductReviewForm
                    review={updateReview}
                    onReviewChange={(updatedReview) => setUpdateReview({ ...updateReview, ...updatedReview })}
                />
                <UiButton
                    onClick={() => {
                        productStore.updateReview(updateReview);
                        productStore.save({ reviews: productStore.product.reviews, _id: productStore.product._id });
                        toast.success("Successfull Save");
                        props.onValidated?.(false);
                    }}
                    color="primary"
                    variant={"contained"}
                >
                    Mettre à jour
                </UiButton>
                <UiButton className={styles.rightBtn} variant={"outlined"} onClick={() => setOpen(true)}>
                    Supprimer
                </UiButton>
                <Dialog
                    closeCross={true}
                    closeSize={"large"}
                    maxWidth={"md"}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <ConfirmationDialog
                        title={"Suppression d'avis"}
                        open={open}
                        message={"Etes vous sûr de vouloir supprimer ?"}
                        agreeLabel={"Supprimer"}
                        disagreeLabel={"Annuler"}
                        onDisagree={() => setOpen(false)}
                        onAgree={() => {
                            if (props.review._id) productStore.deleteReview(props.review._id);
                            setOpen(false);
                            toast.success("Successfull Delete");
                            productStore.save({ reviews: productStore.product.reviews, _id: productStore.product._id });
                        }}
                    />
                </Dialog>
            </div>
        </Card>
    );
});
