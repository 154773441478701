import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { formatPrice } from "_common/_utils/currencyUtils";
import { SHIPPING_METHOD, TOrderListingMdl } from "orders/_models/OrderMdl";
import { useTranslation } from "react-i18next";

type Props = {
    order: TOrderListingMdl;
};

export function OrderDocumentTotal(props: Props) {
    const { t } = useTranslation();

    return (
        <Card elevation={0}>
            <CardContent>
                <Grid container>
                    {props.order.giftCard && (
                        <>
                            <Grid item xs={8}>
                                <span className="fontWeight_bold">
                                    {t(`giftCard.type.${props.order.giftCard?.type}`) +
                                        ": " +
                                        props.order.giftCard?.code}
                                </span>
                            </Grid>
                            <Grid item xs={4} className="textAlign_right">
                                <strong>{"-" + formatPrice(props.order.giftCard?.value)}</strong>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={8}>
                        <span className="fontWeight_bold">{t("cart.subtotal")}</span>
                    </Grid>
                    <Grid item xs={4} className="textAlign_right">
                        <strong>{formatPrice(props.order.subtotal)}</strong>
                    </Grid>
                    <Grid item xs={8}>
                        <span className="fontWeight_bold">{t("cart.shippingMethod")}</span>
                    </Grid>
                    <Grid item xs={4} className="textAlign_right">
                        <strong>{t(`shippingMethod.${props.order.shippingMethod}`)}</strong>
                    </Grid>
                    {props.order.shippingMethod !== SHIPPING_METHOD.COLLECT_IN_STORE && (
                        <>
                            <Grid item xs={8}>
                                <span className={"fontWeight_bold"}>{t("cart.deliveryCost")}</span>
                            </Grid>
                            <Grid item xs={4} className="textAlign_right">
                                <strong>
                                    {props.order.deliveryCost
                                        ? formatPrice(props.order.deliveryCost)
                                        : t("cart.freeShipping")}
                                </strong>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={8}>
                        <span className="fontWeight_bold">{t("cart.gst")}</span>
                    </Grid>
                    <Grid item xs={4} className="textAlign_right">
                        <strong>{formatPrice(props.order.gst)}</strong>
                    </Grid>
                    <Grid item xs={8}>
                        <span className="fontWeight_bold">{t("cart.localTax")}</span>
                    </Grid>
                    <Grid item xs={4} className="textAlign_right">
                        <strong>{formatPrice(props.order.localeTax)}</strong>
                    </Grid>
                    <Grid item xs={8}>
                        <span className="fontWeight_bold">{t("cart.total")}</span>
                    </Grid>
                    <Grid item xs={4} className="textAlign_right">
                        <strong>{formatPrice(props.order.total)}</strong>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
