import React from "react";
import { useProductStore } from "products/ProductContext";
import styles from "./_css/productKeyFeatures.module.css";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { TKeyFeatureLocalizedMdl } from "products/_models/ProductMdl";
import { KEY_FEATURES_ICONS } from "_configs/productConfig";

const ProductKeyFeatures = observer(() => {
    const { product, selectedVariant } = useProductStore();
    const keyFeatures = selectedVariant?.keyFeatures?.length ? selectedVariant.keyFeatures : product.keyFeatures;

    if (!keyFeatures) return null;
    return (
        <div>
            <div className={styles.line} />
            {keyFeatures.map((keyFeature: TKeyFeatureLocalizedMdl, index) => {
                if (!keyFeature.localized?.title || !keyFeature.localized?.description) return null;
                return (
                    <div key={index} className={clsx(styles.keyFeature, "mt_20")}>
                        <img
                            className={styles.icon}
                            src={KEY_FEATURES_ICONS[keyFeature.icon as keyof typeof KEY_FEATURES_ICONS]}
                        />
                        <div className={styles.keyFeatureInfos}>
                            <h3>{keyFeature.localized?.title}</h3>
                            <p>{keyFeature.localized?.description}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
});

export default ProductKeyFeatures;
