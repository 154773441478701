import React from "react";
import { TProductConfigDef } from "_configs/productConfig";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import styles from "../_css/checkBoxFilter.module.css";
import { ListStore } from "_common/list/ListStore";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router";
import { Checkbox } from "@material-ui/core";
import { valueToLowerCase } from "_common/_utils/filterUtils";
import { useTranslation, UseTranslationResponse } from "react-i18next";

export function getType(optionConfigType: string): TFilterType {
    if (optionConfigType === "number") return TFilterType.NUMBER;
    return TFilterType.STRING;
}

function getLabelFromOptionKey(
    optionConfig: TProductConfigDef,
    optionValue: string | number,
    translation: UseTranslationResponse,
) {
    switch (optionConfig.key) {
        case "sppType":
            return translation.t("product.options.sppType." + optionValue);
        case "subType":
            return translation.t("product.types." + optionValue);
        case "accessoryType":
            return translation.t("product.options.accessoryType." + optionValue);
        default:
            return optionValue;
    }
}

export function updateSearchParams(
    search: string,
    currentFilter: { key: string; value: string | number },
    deleteFilter: boolean,
) {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has(currentFilter.key) && deleteFilter) {
        const values = searchParams.getAll(currentFilter.key);
        if (values.length === 1) {
            searchParams.delete(currentFilter.key);
        } else {
            const valueIndex = values.findIndex((value) => valueToLowerCase(value) === currentFilter.value.toString());
            searchParams.delete(currentFilter.key);
            values.splice(valueIndex, 1);
            values.forEach((value) => searchParams.append(currentFilter.key, value));
        }
    } else if (!searchParams.has(currentFilter.key) && !deleteFilter) {
        searchParams.set(currentFilter.key, currentFilter.value.toString());
    } else if (searchParams.has(currentFilter.key) && !deleteFilter) {
        searchParams.append(currentFilter.key, currentFilter.value.toString());
    }
    return searchParams.toString();
}

type Props = {
    optionConfig: TProductConfigDef;
    listStore: ListStore<TProductLocalizedMdl>;
    optionValue: string | number;
};

export const CheckBoxFilter = observer((props: Props) => {
    const translation = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const filterId = props.optionConfig.filter?.itemPath ?? props.optionConfig.key;
    const filterIndex = props.listStore.getFilterIndex(filterId, valueToLowerCase(props.optionValue));
    const filter = props.listStore.filters[filterIndex];
    const checked = valueToLowerCase(filter?.value) === valueToLowerCase(props.optionValue);
    const labelOptionValue = getLabelFromOptionKey(props.optionConfig, props.optionValue, translation);
    const brand = location.pathname.split("/")[4];
    return (
        <div
            className={styles.item}
            key={props.optionConfig.key}
            onClick={() => {
                let url = location.pathname;
                if (props.optionConfig.key === "brand") {
                    if (!brand && !checked) {
                        url = url + (url.endsWith("/") ? "" : "/") + valueToLowerCase(props.optionValue);
                    } else {
                        url = url.replace(valueToLowerCase(props.optionValue), "");
                    }
                }
                if (url.endsWith("/")) {
                    url = url.substring(0, url.length - 1);
                }
                const searchParams = updateSearchParams(
                    location.search,
                    { key: props.optionConfig.key, value: valueToLowerCase(props.optionValue) },
                    checked,
                );
                history.replace(url + "?" + searchParams);
            }}
        >
            <Checkbox checked={checked} value={props.optionConfig.key} size={"small"} />
            <div className={styles.label}>{labelOptionValue}</div>
        </div>
    );
});
