export const performance = {
    sort: 1,
    labelKey: "product.productCategory.performance",
    position: Number.MAX_SAFE_INTEGER,
};

export const dimension = {
    sort: 2,
    labelKey: "product.productCategory.dimension",
    position: Number.MAX_SAFE_INTEGER,
};
export const general = {
    sort: 3,
    labelKey: "product.productCategory.general",
    position: Number.MAX_SAFE_INTEGER,
};
