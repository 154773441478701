import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { CartTotal } from "cart/CartTotal";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { CheckoutPaymentBlock } from "checkout/CheckoutPaymentBlock";
import { CHECKOUT_STEP } from "cart/CartPage";
import { cartStore } from "cart/_stores/cartStore";
import { TPaymentBaseMdl } from "admin/payments/_models/CrudPaymentMdl";
import { CartLines } from "cart/CartLines";
import { CheckoutUserInfo } from "checkout/CheckoutUserInfo";
import { userStore } from "users/_stores/userStore";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { FormProvider, useForm } from "react-hook-form";
import { TUserMdl } from "users/_models/UserMdl";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";

type Props = {
    onBackToCart: () => void;
    onSuccessPayment: (payment: TPaymentBaseMdl) => void;
};

export function CheckoutPage(props: Props) {
    const { t } = useTranslation();
    const [processingPayment, setProcessingPayment] = useState(false);
    const user = !userStore.isLogged
        ? {
              email: "",
              phone: "",
              firstName: "",
              lastName: "",
              notification: true,
              address: {
                  name: "",
                  street: "",
                  extra: "",
                  city: "",
                  postalCode: "",
                  country: "CA",
                  province: "quebec",
              },
              useShippingAddress: false,
              shippingAddress: {
                  name: "",
                  street: "",
                  extra: "",
                  city: "",
                  postalCode: "",
                  country: "CA",
                  province: "quebec",
              },
              lang: i18next.language as TLang | undefined,
          }
        : userStore.user;
    const form = useForm<TUserMdl>({
        mode: "onBlur",
        defaultValues: user,
    });
    return (
        <FormProvider {...form}>
            <Card elevation={0} style={{ backgroundColor: "transparent" }}>
                <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("checkout.orderSummary")} />
                <CardContent className="p_0 mt_10">
                    <Grid container spacing={3} className="mt_10">
                        <Grid item xs={12}>
                            <CartLines isNotEditable />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CheckoutUserInfo />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CartTotal
                                currentStep={CHECKOUT_STEP.CHECK_OUT}
                                collectInStore={form.watch("collectInStore")}
                                onProcessing={form.handleSubmit(async (data) => {
                                    return userStore
                                        .save(
                                            {
                                                ...userStore.user,
                                                ...data,
                                            } as TUserMdl,
                                            undefined,
                                        )
                                        .then(
                                            () => setProcessingPayment(true),
                                            (err) => {
                                                toast.error(getErrorMessage(err));
                                            },
                                        );
                                })}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth
                    open={processingPayment}
                    closeCross={true}
                    onClose={() => setProcessingPayment(false)}
                >
                    <Card>
                        <CardHeader className="textAlign_center" title={t("checkout.title")} />
                        <CardContent>
                            <CheckoutPaymentBlock
                                order={cartStore.cart}
                                amount={cartStore.total}
                                giftCards={cartStore.giftCardCreatedAfterPayment}
                                onSuccess={(payment: TPaymentBaseMdl) => {
                                    setProcessingPayment(false);
                                    cartStore.resetAfterOrder();
                                    props.onSuccessPayment(payment);
                                }}
                            />
                        </CardContent>
                    </Card>
                </Dialog>
            </Card>
        </FormProvider>
    );
}
