import React from "react";
import { FILTER_TYPE, TProductLocalizedMdl } from "products/_models/ProductMdl";
import { TProductConfigDef } from "_configs/productConfig";
import { CheckBoxFilters } from "shop/filtersType/CheckBoxFilters";
import { ListStore } from "_common/list/ListStore";
import { SliderFilters } from "shop/filtersType/SliderFilters";

type Props = {
    listStore?: ListStore<TProductLocalizedMdl>;
    optionsConfig?: TProductConfigDef;
};

export function FilterList(props: Props) {
    const optionsConfig = props.optionsConfig;
    if (!(optionsConfig?.filter && props.listStore)) return null;
    let FilterComponent = undefined;
    switch (optionsConfig.filter.filterType) {
        case FILTER_TYPE.CHECKBOX:
            FilterComponent = CheckBoxFilters;
            break;
        case FILTER_TYPE.SLIDER:
            FilterComponent = SliderFilters;
            break;
        case FILTER_TYPE.BOOLEAN:
            FilterComponent = CheckBoxFilters; // Associer, à coder
            break;
    }
    return (
        <>
            <FilterComponent listStore={props.listStore} optionConfig={optionsConfig} />
        </>
    );
}
