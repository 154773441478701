import React from "react";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import styles from "./_css/productSmallCard.module.css";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { URLS } from "_configs/URLS";
import { formatPrice } from "_common/_utils/currencyUtils";

type Props = {
    product: TProductLocalizedMdl;
    onClick: () => void;
};

const ProductSmallCard = ({ product, onClick }: Props) => {
    const productImage = product.photos?.[0]?.url;

    return (
        <ExternalOrNavLink
            className={styles.productCard}
            url={URLS.product(product.localized.urlAlias)}
            onClick={onClick}
        >
            <div>
                <div className={styles.imageContainer}>
                    {productImage ? (
                        <img src={productImage} alt={product.localized.name} />
                    ) : (
                        <div className={styles.placeholder} />
                    )}
                </div>
                <p className={"mt_10"}>{product.localized.name}</p>
            </div>
            <span className={styles.price}>{formatPrice(product.minPrice)}</span>
        </ExternalOrNavLink>
    );
};

export default ProductSmallCard;
