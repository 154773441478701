import React, { useEffect } from "react";
import { TCommentMdl } from "comments/_models/CommentMdl";
import i18next from "i18next";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { commentsStore } from "comments/_stores/commentsStore";
import { Input } from "_common/ui/forms/Input";
import styles from "./_css/commentForm.module.css";
import { TextArea } from "_common/ui/forms/TextArea";

type Props = {
    comment: TCommentMdl;
    isEditing?: boolean;
    onStopEditing?: () => void;
    cannotCancel?: boolean;
};

export function CommentForm(props: Props) {
    const { t } = useTranslation();
    const comment = props.comment;
    const form = useForm<TCommentMdl>({
        mode: "onBlur",
        defaultValues: comment,
    });
    useEffect(() => {
        form.reset(comment);
    }, [props.comment]);

    return (
        <FormProvider {...form}>
            <h4>
                <Controller
                    className={styles.title}
                    as={Input}
                    control={form.control}
                    name={"title"}
                    theme={props.isEditing ? undefined : "label"}
                    rules={{
                        required: t<string>("errors.forms.required"),
                    }}
                    disabled={!props.isEditing || form.formState.isSubmitting}
                    error={form.errors.title}
                />
            </h4>
            <div className={styles.date}>
                {i18next.t("words.at") + " " + dayjs(props.comment.createdAt).format(i18next.t("dates.formats.full"))}
            </div>
            <div>
                <Controller
                    className={styles.description}
                    as={TextArea}
                    control={form.control}
                    name={"description"}
                    theme={props.isEditing ? undefined : "label"}
                    disabled={!props.isEditing || form.formState.isSubmitting}
                    error={form.errors.title}
                />
            </div>

            {props.isEditing && (
                <div className="mt_20">
                    {!props.cannotCancel && (
                        <UiButton
                            onClick={() => {
                                props.onStopEditing?.();
                                form.reset(props.comment);
                            }}
                            variant={"outlined"}
                            color={"primary"}
                            className="mr_5"
                            disabled={form.formState.isSubmitting}
                        >
                            {t("words.cancel")}
                        </UiButton>
                    )}
                    <UiButton
                        onClick={form.handleSubmit((data) => {
                            commentsStore
                                .patch({
                                    ...comment,
                                    ...data,
                                } as TCommentMdl)
                                .then(() => {
                                    toast.success(i18next.t("comment.successCommenting"));
                                })
                                .catch((e) => toast.error(getErrorMessage(e)));
                        })}
                        color={"primary"}
                        variant={"contained"}
                        disabled={form.formState.isSubmitting}
                    >
                        {form.formState.isSubmitting ? t("loaders.message") : t("words.submit")}
                    </UiButton>
                </div>
            )}
        </FormProvider>
    );
}
