import React from "react";
import { cartStore } from "cart/_stores/cartStore";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { TGiftCardMdl } from "giftCards/_models/GiftCardMdl";
import { formatPrice } from "_common/_utils/currencyUtils";
import { IconComponent } from "components/base/icon/IconComponent";
import styles from "./_css/cartGiftCardView.module.css";

export function getGiftCardValue(giftCard: TGiftCardMdl, regularPrice: number) {
    switch (giftCard.kind) {
        case "$":
            return giftCard.value;
        case "%":
            return Math.round(giftCard.value * regularPrice) / 10000;
    }
    return 0;
}

export const CartGiftCardView = observer(() => {
    const { t } = useTranslation();
    return (
        <Grid container>
            {cartStore.giftCard && (
                <>
                    <Grid item xs={7}>
                        {t(`giftCard.type.${cartStore.giftCard.type}`)}{" "}
                        <div className="flex_row_center">
                            <span className="text_discreet mr_5">{cartStore.giftCard.code}</span>
                            <IconComponent
                                className={styles.removeGiftCard}
                                onClick={() => cartStore.setGiftCard(undefined, true)}
                                icon="icon-cross"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={5} className="textAlign_right">
                        <span className={styles.fontPrice}>
                            {formatPrice(getGiftCardValue(cartStore.giftCard, cartStore.regularTotal))}
                        </span>
                    </Grid>
                    {cartStore.giftCardLeft && (
                        <>
                            <Grid item xs={7}>
                                {t(`giftCard.left`)}
                            </Grid>
                            <Grid item xs={5} className="textAlign_right">
                                <h4>{formatPrice(cartStore.giftCardLeft)}</h4>
                            </Grid>
                        </>
                    )}
                </>
            )}
            {!cartStore.giftCard && cartStore.cart.giftCard && (
                <Grid item xs={12} className="textAlign_right text_error">
                    {t("cart.noGiftCard")}
                </Grid>
            )}
        </Grid>
    );
});
