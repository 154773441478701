import styles from "products/comparison/_css/productComparisonBlock.module.css";
import { OpenInNewOutlined } from "@material-ui/icons";
import React from "react";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";

type Props = {
    product: TProductLocalizedMdl;
};

const ProductComparisonBlockImage = ({ product }: Props) => {
    const productImage = product.photos?.[0].url;
    return (
        <>
            {productImage ? (
                <img src={productImage} alt={product.localized.name} className={styles.image} />
            ) : (
                <div className={styles.imagePlaceholder} />
            )}
            <i className={styles.openInNew}>
                <OpenInNewOutlined width={80} height={80} />
            </i>
        </>
    );
};

export default ProductComparisonBlockImage;
