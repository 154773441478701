import React from "react";
import { TMenuGroup } from "settings/_models/MenuMdl";
import styles from "./_css/subGroupsMenu.module.css";
import { LeafMenu } from "main/menu/LeafMenu";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router";
import { getTopProducts } from "_common/_utils/productUtils";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import ProductSmallCard from "products/ProductSmallCard";

const MAX_RELATED_PRODUCTS = 5;

type Props = {
    subGroups: TMenuGroup[];
    onClick: () => void;
    relatedProducts?: PRODUCT_TYPE;
};

export function SubGroupsMenu(props: Props) {
    const history = useHistory();
    const relatedProducts = props.relatedProducts
        ? getTopProducts(props.relatedProducts, MAX_RELATED_PRODUCTS)
        : undefined;
    return (
        <div className={styles.container}>
            <div className={styles.bodyWrapper}>
                <Container>
                    <Grid container className={clsx(styles.body, "flex_row")}>
                        {props.subGroups.map((subGroup, index) => (
                            <Grid sm={6} md={3} item key={index} className={styles.subGroup}>
                                <div className={styles.subGroupBody}>
                                    {subGroup.title && (
                                        <h4
                                            onClick={() => {
                                                if (subGroup.url) {
                                                    history.push(subGroup.url);
                                                    props.onClick();
                                                }
                                            }}
                                            style={subGroup.url ? { cursor: "pointer" } : undefined}
                                            className={clsx(styles.title, { [styles.link]: !!subGroup.url })}
                                        >
                                            {subGroup.title}
                                        </h4>
                                    )}
                                    {subGroup.items?.map((leafMenu, index) => (
                                        <LeafMenu onClick={props.onClick} key={index} menu={leafMenu} />
                                    ))}
                                </div>
                            </Grid>
                        ))}
                        {relatedProducts && (
                            <Grid md={7} item className={clsx(styles.relatedProducts, "flex_row pv_20")}>
                                {relatedProducts.map((product, index) => (
                                    <div className={styles[`product-${index + 1}`]} key={product._id}>
                                        <ProductSmallCard product={product} onClick={props.onClick} />
                                    </div>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </div>
        </div>
    );
}
