import { useEffect } from "react";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { useLocation } from "react-router-dom";
import { affiliationsUtils } from "_common/_utils/affiliationsUtils";

export function useTrackPage() {
    const location = useLocation();
    const url = location.pathname + location.search + location.hash;
    useEffect(() => {
        analyticsUtils.trackPage(url);
        void affiliationsUtils.trackPage(location.search);
    }, [url]);
}
