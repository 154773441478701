import React from "react";
import { Warranty } from "products/description/Warranty";
import { useProductStore } from "products/ProductContext";
import { formatPrice } from "_common/_utils/currencyUtils";
import { observer } from "mobx-react";

export const ScooterDescription = observer(() => {
    const productStore = useProductStore();
    if (!productStore.selectedVariant) return null;
    return (
        <div className="flex_row_center mt_10">
           {/* <Warranty />*/}
            {/*<h4>+{formatPrice(productStore.warrantyPrice)}</h4>*/}
        </div>
    );
});
