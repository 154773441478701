import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { QUOTATION_STATUS, TOrderListingMdl, TOrderMdl } from "orders/_models/OrderMdl";
import { PAYMENT_PROVIDER, TPaymentMdl } from "admin/payments/_models/CrudPaymentMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import dayjs from "dayjs";
import { TGiftCardMdl } from "giftCards/_models/GiftCardMdl";

class OrdersStore extends BaseResourceStore<TOrderMdl> {
    @observable myOrders: TOrderListingMdl[] = [];
    @observable myOrdersLoadingState = new LoadingStateMdl<TOrderListingMdl[]>("IDLE");

    constructor() {
        super("orders");
    }

    checkout(
        service = PAYMENT_PROVIDER.SQUARE,
        payload: {
            locationId: string;
            sourceId: string;
            order: Partial<TOrderMdl> | TOrderListingMdl;
            amount: number;
            giftCard?: TGiftCardMdl;
            affiliateName?: string;
        },
    ) {
        return fetchUtils.post<TPaymentMdl>(this.apiPath + "/checkout/" + service, payload);
    }

    sendOrder(orderId: string) {
        return fetchUtils.post<TOrderMdl>(this.apiPath + "/invoice/" + orderId);
    }

    fetchMyOrders() {
        if (!this.myOrdersLoadingState.isLoading) {
            this.myOrdersLoadingState.startLoading();
            fetchUtils.get<TOrderListingMdl[]>(`${this.apiPath}/listing`).then(({ data }) => {
                this.myOrders = data;
                this.myOrdersLoadingState.setSuccess(data);
            });
        }
        return this.myOrdersLoadingState;
    }

    getByToken(token: string) {
        return fetchUtils.get<TOrderListingMdl>(`${this.apiPath}/getByToken/${token}`);
    }

    getById(itemId: string) {
        const order = this.myOrders.find((order) => order._id === itemId);
        if (order) return new Promise<{ data: TOrderListingMdl }>((r) => r({ data: order }));
        return fetchUtils.get<TOrderListingMdl>(`${this.apiPath}/${itemId}`);
    }

    acceptQuotation(token: string, accept = QUOTATION_STATUS.ACCEPTED) {
        return fetchUtils.post<TOrderListingMdl>(`${this.apiPath}/accept/${token}`, { accept });
    }

    protected reformatItem(item: TOrderMdl) {
        const reformatedItem = super.reformatItem(item);
        if (reformatedItem.orderDate) reformatedItem.orderDate = dayjs(reformatedItem.orderDate);
        return {
            ...reformatedItem,
            lines: reformatedItem.lines.map((line) => ({ ...line, price: line.price / 100 })),
        };
    }
}

const ordersStore = new OrdersStore();
export { ordersStore };
