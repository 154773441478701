import React, { useState } from "react";
import styles from "./_css/searchBar.module.css";
import { Input } from "_common/ui/forms/Input";
import { searchStore } from "main/menu/search/_stores/searchStore";
import { observer } from "mobx-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { useHistory } from "react-router";
import SearchBarResults from "main/menu/search/SearchBarResults";
import { TLang } from "_configs/sharedConfig";

enum KEYS {
    ARROW_DOWN = "ArrowDown",
    ARROW_UP = "ArrowUp",
    ENTER = "Enter",
}
export const SearchBar = observer(() => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selectedIdx, setSelectedIdx] = useState<number | undefined>(undefined);
    const results = searchStore.results;
    const products = results.products.slice(0, 3);
    const articles = results.articles.slice(0, 3);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        switch (e.key) {
            case KEYS.ARROW_DOWN:
                if (selectedIdx === undefined) {
                    setSelectedIdx(0);
                } else if (selectedIdx < products.length + articles.length - 1) {
                    setSelectedIdx(selectedIdx + 1);
                } else {
                    setSelectedIdx(undefined);
                }
                break;
            case KEYS.ARROW_UP:
                if (selectedIdx === undefined) {
                    setSelectedIdx(products.length + articles.length - 1);
                } else if (selectedIdx > 0) {
                    setSelectedIdx(selectedIdx - 1);
                } else {
                    setSelectedIdx(undefined);
                }
                break;
            case KEYS.ENTER:
                if (selectedIdx !== undefined) {
                    if (selectedIdx < products.length) {
                        history.push(URLS.product(products[selectedIdx].urlAlias[i18next.language as TLang]));
                    } else {
                        history.push("/" + i18next.language + articles[selectedIdx - products.length].localized.url);
                    }
                    searchStore.open(false);
                } else {
                    if (searchStore.hasResults) {
                        history.push(URLS.search(i18next.language) + "?search=" + searchStore.search);
                        searchStore.open(false);
                    }
                }
        }
    };
    return (
        <div className={clsx("mr_20", styles.container)}>
            <Input
                onChange={(e) => searchStore.setSearch(e.target.value)}
                onClick={() => searchStore.open(true)}
                onKeyDown={handleKeyDown}
                placeholder={t("header.searchBarPlaceholder")}
                className={styles.searchInput}
                endIcon={<i className={clsx("icon-search1", styles.icon)} />}
            />
            {searchStore.isSearching && <SearchBarResults results={{ products, articles }} selectedIdx={selectedIdx} />}
        </div>
    );
});
