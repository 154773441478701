import React from "react";
import { Helmet } from "react-helmet-async";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { useTranslation } from "react-i18next";
import favicon from "../../assets/images/favicon.png";

type Props = {
    title?: string;
    description?: string;
    image?: string;
    url: string;
    alternateUrls?: { lang: TLang | "x-default"; url: string }[];
};

export function Meta(props: Props) {
    const { t } = useTranslation();
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="title" content={props.title ?? t("meta.title")} />
            <meta name="og:title" content={props.title ?? t("meta.title")} />
            <meta name="twitter:title" content={props.title ?? t("meta.title")} />

            <meta name="description" content={props.description ?? t("meta.description")} />
            <meta name="og:description" content={props.description ?? t("meta.description")} />
            <meta name="twitter:description" content={props.description ?? t("meta.description")} />

            <meta name="image" content={props.image ?? favicon} />
            <meta name="og:image" content={props.image ?? favicon} />
            <meta name="twitter:image" content={props.image ?? favicon} />

            <meta name="twitter:card" content="summary" />
            <meta name="og:url" content={props.url} />
            <link rel="canonical" href={props.url} />
            {props.alternateUrls?.map(({ lang, url }) => (
                <link
                    key={lang}
                    rel="alternate"
                    type="text/html"
                    hrefLang={lang}
                    href={url}
                    title={sharedConfig.languages?.[lang as keyof typeof sharedConfig.languages]?.title ?? undefined}
                />
            ))}
        </Helmet>
    );
}
