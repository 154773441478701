import React from "react";
import styles from "./_css/searchHeader.module.css";

type Props = {
    label: string;
};

export function SearchHeader(props: Props) {
    return <div className={styles.container}>{props.label}</div>;
}
