import React from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import i18next from "i18next";
import { Input } from "_common/ui/forms/Input";
import { UiMarkStars } from "_common/ui/UiMarkStars";
import { TextArea } from "_common/ui/forms/TextArea";
import { TReviewEditionMdl, TReviewMdl } from "products/_models/ProductMdl";
import { observer } from "mobx-react";

type Props = {
    review: TReviewEditionMdl | TReviewMdl;
    onReviewChange: (review: TReviewEditionMdl) => void;
};

export const ProductReviewForm = observer((props: Props) => {
    const review = props.review;
    const onReviewChange = props.onReviewChange;
    return (
        <div>
            <div className="flex_row pv_10">
                <InputBlock label={i18next.t("product.review.title")} required>
                    <Input
                        value={review.title}
                        onChange={(e) => onReviewChange({ ...review, title: e.target.value })}
                    />
                </InputBlock>
            </div>
            <UiMarkStars onPickMark={(mark) => onReviewChange({ ...review, mark: mark })} mark={review.mark} />
            <div className="pv_10">
                <InputBlock label={i18next.t("product.review.comment")}>
                    <TextArea
                        rows={4}
                        value={review.comment}
                        onChange={(e) => onReviewChange({ ...review, comment: e.target.value })}
                    />
                </InputBlock>
            </div>
        </div>
    );
});
