import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { GenericSlider } from "components/pages/slider/GenericSlider";

type Props = {
    style: CSSProperties;
    slideShowId?: string;
    loop?: boolean;
    autoplay?: boolean;
    navigation?: boolean;
    $component: TComponent;
};

export const GenericSliderComponent = (props: Props) => {
    if (!props.slideShowId) return <div />;
    return (
        <div style={{ ...props.style, maxWidth: "100%" }}>
            <GenericSlider
                navigation={!!props.navigation}
                loop={!!props.loop}
                slideShowId={props.slideShowId}
                autoplay={props.autoplay}
            />
        </div>
    );
};
