import React, { useState } from "react";
import clsx from "clsx";
import MobileMenuSearchModal from "main/menu/mobile/MobileMenuSearchModal";

const MobileMenuSearchButton = () => {
    const [searchModalOpen, setSearchModalOpen] = useState(false);

    return (
        <>
            <MobileMenuSearchModal opened={searchModalOpen} onClose={() => setSearchModalOpen(false)} />
            <div
                className={"p_5 flex_center_center"}
                onClick={() => {
                    setSearchModalOpen(true);
                }}
            >
                <i className={clsx("icon-search1")} />
            </div>
        </>
    );
};

export default MobileMenuSearchButton;
