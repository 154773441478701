import React from "react";
import styles from "./_css/homepageBenefitCard.module.css";
import { Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
    icon: string;
    titleKey: string;
    descriptionKey: string;
};

const HomepageBenefitCard = ({ icon, titleKey, descriptionKey }: Props) => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <Icon>{icon}</Icon>
            <h3>{t(titleKey).trim()}</h3>
            <p className={"paragraph"}>{t(descriptionKey)}</p>
        </div>
    );
};

export default HomepageBenefitCard;
