import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { CardContent, Container, Grid } from "@material-ui/core";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { SignInFormBlock } from "users/auth/blocks/SignInFormBlock";

export const SignInBlock = observer(() => {
    const { t } = useTranslation();

    return (
        <Container>
            <Grid container justify={"center"}>
                <Grid item xs={12} sm={8} md={5}>
                    <SignInFormBlock />
                    <hr />
                    <CardContent className="flex_center_center flex_column">
                        <div>{t("auth.signUp.notRegisteredYet")}</div>
                        <Link to={URLS.auth.signUp()} className="link">
                            {t("auth.signUp.title")}
                        </Link>
                    </CardContent>
                    <hr />
                    <CardContent className="flex_center_center flex_column">
                        <div>{t("auth.forgottenPassword.forgotPassword")}</div>
                        <Link to={URLS.auth.askResetPassword()} className="link">
                            {t("auth.forgottenPassword.title")}
                        </Link>
                    </CardContent>
                </Grid>
            </Grid>
        </Container>
    );
});
