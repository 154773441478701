import { TDiscount } from "products/_models/VariantMdl";
import { Dayjs } from "dayjs";

export enum GIFT_CARD_TYPE {
    GIFT_CARD = "GIFT_CARD",
    DISCOUNT = "DISCOUNT",
}

export type TGift = {
    sender: string;
    senderMail: string;
    recipient: string;
    recipientMail: string;
    message: string;
    sendNow: boolean;
};

export type TGiftCardMdl = TDiscount & {
    _id: string;
    code: string;
    type: GIFT_CARD_TYPE;
    updatedAt?: Dayjs;
    createdAt?: Dayjs;
    isGift?: TGift;
};
