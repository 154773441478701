import React from "react";
import { ordersStore } from "orders/_stores/ordersStore";
import { Order } from "orders/Order";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import styles from "./_css/ordersList.module.css";
import { Hidden } from "@material-ui/core";

export function OrdersList() {
    const { t } = useTranslation();
    return (
        <div>
            <Grid container className="fontWeight_bold mb_20">
                <Grid item xs={6} md={4}>
                    {t("order.number")}
                </Grid>
                <Hidden xsDown>
                    <Grid item md={4} className={styles.orderDate}>
                        {t("order.date")}
                    </Grid>
                </Hidden>
                <Grid item xs={6} md={4} className="textAlign_right">
                    {t("order.price")}
                </Grid>
            </Grid>
            {ordersStore.myOrders.map((order) => (
                <Order key={order._id} order={order} />
            ))}
        </div>
    );
}
