import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
    url?: string;
    className?: string;
    activeClassName?: string;
    onClick?: () => void;
    nofollow?: boolean;
    children: JSX.Element | string;
};

export function ExternalOrNavLink(props: Props) {
    const url = props.url ?? "/";
    if (url.startsWith("/")) {
        return (
            <NavLink
                className={props.className}
                activeClassName={props.activeClassName}
                to={url}
                exact
                rel={props.nofollow ? "nofollow" : ""}
                onClick={props.onClick}
            >
                {props.children}
            </NavLink>
        );
    }
    if (url.startsWith("mailto:") || url.startsWith("tel:")) {
        return (
            <a className={props.className} href={url} rel="nofollow" onClick={props.onClick}>
                {props.children}
            </a>
        );
    }

    return (
        <a
            className={props.className}
            href={url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={props.onClick}
        >
            {props.children}
        </a>
    );
}
