import React from "react";
import { cartStore } from "cart/_stores/cartStore";
import { CartLine } from "cart/CartLine";
import { observer } from "mobx-react";
import { CartHeader } from "cart/CartHeader";

type Props = {
    isNotEditable?: boolean;
};

export const CartLines = observer((props: Props) => {
    return (
        <div>
            <CartHeader />
            <div>
                {cartStore.cart.lines.map((cartLine, index) => (
                    <CartLine isNotEditable={props.isNotEditable} key={index} cartLine={cartLine} />
                ))}
            </div>
        </div>
    );
});
