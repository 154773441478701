import React, { useState } from "react";
import clsx from "clsx";
import { IconComponent } from "components/base/icon/IconComponent";
import styles from "../../products/reviews/_css/markStars.module.css";
import { useTranslation } from "react-i18next";

type Props = {
    mark?: number;
    onPickMark?: (mark: number) => void;
    numberReviews?: number;
    onClick?: () => void;
};

const MAX_MARK = 5;

export function UiMarkStars({ mark = 0, onPickMark, numberReviews, onClick }: Props) {
    const [newMark, setNewMark] = useState<number | undefined>();
    const { t } = useTranslation();

    const stars = [];

    for (let i = 0.5; i <= MAX_MARK; i = i + 0.5) {
        stars.push(
            <IconComponent
                key={i}
                className={clsx(styles.starDefault, {
                    [styles.starPicked]: mark >= i,
                    [styles.starPicking]: onPickMark && newMark && newMark >= i,
                    [styles.starRightToLeft]: i % 1 === 0,
                    [styles.starLeftToRight]: i % 1 !== 0,
                })}
                onMouseEnter={() => setNewMark(i)}
                onClick={() => (onPickMark ? onPickMark(i) : undefined)}
                icon={"icon-star-full"}
            />,
        );
    }
    if (!numberReviews && !mark) return null;
    return (
        <div
            className={clsx(styles.container, {
                cursor_pointer: onClick,
            })}
            onClick={onClick ?? undefined}
        >
            <div className={clsx(styles.stars, "mr_5")} onMouseLeave={() => setNewMark(undefined)}>
                {stars.map((star) => star)}
            </div>
            {numberReviews && (
                <div className={styles.numberReviews}>
                    {numberReviews > 1
                        ? t("product.review.plural", { value: numberReviews })
                        : t("product.review.single", { value: numberReviews })}
                </div>
            )}
        </div>
    );
}
