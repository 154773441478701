import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TGiftCardMdl } from "giftCards/_models/GiftCardMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";

class GiftCardsStore extends BaseResourceStore<TGiftCardMdl> {
    constructor() {
        super("giftCards");
    }

    getByCode(code?: string) {
        return fetchUtils.post<TGiftCardMdl>(`${this.apiPath}/code`, { code });
    }
}

const giftCardsStore = new GiftCardsStore();
export { giftCardsStore };
