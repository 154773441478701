import React from "react";
import { observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { contactsStore } from "components/misc/contactUs/_stores/contactsStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { useTranslation } from "react-i18next";

export const ContactFormBtn = observer(() => {
    const { handleSubmit } = useFormContext();
    const { t } = useTranslation();
    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";
    return (
        <div>
            {loading?.isSucceeded && <div>Message envoyé avec succès</div>}
            <ErrorBlock error={loading?.error} />
            <div className="flex_row mt_10">
                <div
                    className="btn btn_1"
                    onClick={handleSubmit((data) => {
                        if (!isLoading) {
                            setPromise(contactsStore.sendContact(data));
                        }
                    })}
                >
                    {isLoading ? t("contact.sending") : t("contact.send")}
                </div>
            </div>
        </div>
    );
});
