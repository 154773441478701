import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import styles from "./_css/imageComponent.module.css";
import { useHistory } from "react-router";
import clsx from "clsx";
import { UiButton } from "_common/ui/mui/buttons/UiButton";

export type ImageComponentProps = {
    style: CSSProperties;
    src: string;
    alt: string;
    $component: TComponent;
    url?: string;
    textHover?: string;
    isHiddenOnDesktop?: string;
    isHiddenOnPhone?: string;
};

export function ImageComponent({
                                   $component,
                                   textHover,
                                   isHiddenOnDesktop,
                                   isHiddenOnPhone,
                                   ...props
                               }: ImageComponentProps) {
    const history = useHistory();
    return (
        <div
            className={clsx(styles.container, {
                [styles.phoneDisplay]: isHiddenOnPhone,
                [styles.desktopDisplay]: isHiddenOnDesktop,
            })}
        >
            <img {...props} style={props.style} className={styles.image} alt={props.alt} />
            {props.url && (
                <div
                    className={clsx("flex_center_center", styles.content)}
                    onClick={() => {
                        props.url ? history.push(props.url) : null;
                    }}
                >
                    {textHover && (
                        <UiButton variant={"outlined"} className={styles.button}>
                            {textHover}
                        </UiButton>
                    )}
                </div>
            )}
        </div>
    );
}
