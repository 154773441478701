import React from "react";
import { Meta } from "_common/_utils/Meta";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { URLS } from "_configs/URLS";

type Props = {
    product: TProductLocalizedMdl;
};

export function ProductPageMeta({ product }: Props) {
    const title = product.localized.meta?.title ?? product.localized.name;
    const description = product.localized.meta?.description ?? product.localized.description;
    const image = product.localized.meta?.image ?? product?.photos?.[0]?.url;
    const url = URLS.product(product.localized.urlAlias);
    const alternateUrls: { url: string; lang: TLang | "x-default" }[] = [{ url, lang: "x-default" }];

    for (const lang of Object.keys(sharedConfig.languages)) {
        const localizedUrl = product.urlAlias?.[lang as keyof typeof product.urlAlias];
        if (!localizedUrl) continue;
        alternateUrls.push({
            url: URLS.product(localizedUrl, lang),
            lang: lang as TLang,
        });
    }

    return <Meta title={title} description={description} image={image} url={url} alternateUrls={alternateUrls} />;
}
