import React from "react";
import { useProductStore } from "products/ProductContext";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import { EucDescription } from "products/description/EucDescription";
import { AccessoryDescription } from "products/description/AccessoryDescription";
import { ScooterDescription } from "products/description/ScooterDescription";
import { SppDescription } from "products/description/SppDescription";
import { Card, Grid, NoSsr } from "@material-ui/core";
import styles from "./_css/productDescription.module.css";
import { ProductVariants } from "products/variants/ProductVariants";
import { ProductPrice } from "products/description/ProductPrice";
import { CartAddBtn } from "cart/CartAddBtn";
import { ProductTitle } from "products/description/ProductTitle";
import { ProductPreorder } from "products/description/ProductPreorder";
import { ReconditionedDescription } from "products/description/ReconditionedDescription";
import { ProductFunding } from "products/funding/ProductFunding";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { getAverageMark } from "_common/_utils/productUtils";
import { UiMarkStars } from "_common/ui/UiMarkStars";
import ProductKeyFeatures from "products/description/ProductKeyFeatures";
import ProductServicesAccordion from "products/description/ProductServicesAccordion";

type Props = {
    onScrollToReviews: () => void;
};

export function ProductDescription(props: Props) {
    const { product } = useProductStore();
    const { t } = useTranslation();

    let ComponentTag = undefined;

    switch (product.type) {
        case PRODUCT_TYPE.REFURBISHED:
            ComponentTag = ReconditionedDescription;
            break;
        case PRODUCT_TYPE.EUC:
            ComponentTag = EucDescription;
            break;
        case PRODUCT_TYPE.SCOOTER:
            ComponentTag = ScooterDescription;
            break;
        case PRODUCT_TYPE.SPP:
            ComponentTag = SppDescription;
            break;
        case PRODUCT_TYPE.ACCESSORY:
            ComponentTag = AccessoryDescription;
            break;
    }

    if (!ComponentTag) return null;

    return (
        <Grid className={styles.container} container>
            <Grid item xs={12}>
                <Card elevation={0} className={"flex_column p_20"}>
                    <ProductTitle />
                    <UiMarkStars
                        mark={getAverageMark(product)}
                        numberReviews={product.reviews.length}
                        onClick={props.onScrollToReviews}
                    />
                    <p className="paragraph mt_30">{product.localized.description}</p>
                    <ProductPrice />
                    <ProductFunding />
                    <ProductVariants />
                    <NoSsr>
                        <ComponentTag />
                    </NoSsr>
                    <div className="flex_row_center mv_30">
                        {!product.variants?.[0].sellingLink ? (
                            <CartAddBtn />
                        ) : (
                            <a href={product.variants?.[0].sellingLink} target={"_blank"} rel={"noreferrer noopener"}>
                                <UiButton variant={"contained"} color={"primary"}>
                                    {t("product.sellingSite")}
                                </UiButton>
                            </a>
                        )}
                    </div>
                    <ProductPreorder />
                    <ProductKeyFeatures />
                    <ProductServicesAccordion />
                </Card>
            </Grid>
        </Grid>
    );
}
