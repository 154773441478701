import React from "react";
import { TOrderListingMdl } from "orders/_models/OrderMdl";
import { Card, CardContent, Grid } from "@material-ui/core";
import { OrderDocumentLine } from "orders/document/OrderDocumentLine";
import { useTranslation } from "react-i18next";

type Props = {
    order: TOrderListingMdl;
};

export function OrderDocumentLines(props: Props) {
    const { t } = useTranslation();
    return (
        <Card elevation={0}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={3} className="flex_row_center">
                            <Grid item xs={3}>
                                <strong>{t("order.item")}</strong>
                            </Grid>
                            <Grid item xs={3} className="textAlign_right">
                                <strong>{t("order.quantity")}</strong>
                            </Grid>
                            <Grid item xs={3} className="textAlign_right">
                                <strong>{t("order.unitaryPrice")}</strong>
                            </Grid>
                            <Grid item xs={3} className="textAlign_right">
                                <strong>{t("order.price")}</strong>
                            </Grid>
                        </Grid>
                    </Grid>
                    {props.order.lines.map((line, index) => (
                        <Grid item key={index} xs={12}>
                            <OrderDocumentLine line={line} />
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
}
