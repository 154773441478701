import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import _ from "lodash";
import i18next from "i18next";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";

export function useSSR() {
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    return isFirstRender;
}

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useProductTypeFromParams() {
    const { productTypeLabel } = useParams();
    const productTypes = _.get(i18next.getDataByLanguage(i18next.language)?.translation, "routes.productTypes", "EUC");

    for (const pt of Object.entries(productTypes)) {
        const [key, value] = pt;
        if (value === productTypeLabel) return key as PRODUCT_TYPE;
    }
    return undefined;
}

export function useScrollPosition(pageRef: { current: any }) {
    const [scrollPosition, setScrollPosition] = useState(pageRef.current?.scrollTop);
    const handleScroll = () => {
        if (__BROWSER__) {
            const position = pageRef.current.scrollTop;
            setScrollPosition(position);
        }
    };

    useEffect(() => {
        if (__BROWSER__) {
            pageRef.current.addEventListener("scroll", handleScroll);
            return () => {
                pageRef.current ? pageRef.current.removeEventListener("scroll", handleScroll) : undefined;
            };
        }
    }, []);

    return scrollPosition;
}

export function useWindowSize(throttle?: number) {
    const [windowSize, setWindowSize] = useState({ width: -1, height: -1 });
    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        const listener = _.throttle(
            () =>
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                }),
            throttle ?? 100,
        );
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [throttle]);
    return windowSize;
}
