import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";
import clsx from "clsx";
import styles from "./_css/productHighlighted.module.css";

type Props = {
    style?: CSSProperties;
    theme: "light" | "dark" | "primary" | "secondary" | "none";
    children: TComponent[];
    $component: TComponent;
};

export function ProductHighlighted(props: Props) {
    return (
        <div
            style={props.style}
            className={clsx({
                [styles.light]: props.theme === "light",
                [styles.primary]: props.theme === "primary",
                [styles.secondary]: props.theme === "secondary",
                [styles.dark]: props.theme === "dark",
            })}
        >
            <ChildrenComponentRenderer propName={"twoCols"} parent={props.$component}>
                {props.children}
            </ChildrenComponentRenderer>
        </div>
    );
}
