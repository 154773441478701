import React, { useState } from "react";
import styles from "./_css/productComparisonAddAction.module.css";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { searchStore } from "main/menu/search/_stores/searchStore";
import { useProductStore } from "products/ProductContext";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Add } from "@material-ui/icons";

type Props = {
    onProductAddClick: (product: TProductLocalizedMdl) => void;
};

const ProductComparisonAddAction = observer(({ onProductAddClick }: Props) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const { t } = useTranslation();
    const { product } = useProductStore();
    const products = searchStore.products.filter(
        (_product) => _product.type === product.type && _product._id !== product._id,
    );
    const toggleSearch = () => setIsSearchOpen((prevState) => !prevState);

    return (
        <div className={styles.container}>
            {!isSearchOpen && (
                <div className={styles.addProduct} onClick={toggleSearch}>
                    <p className={styles.addText}>{t("productComparison.addProduct")}</p>
                    <i className={styles.addIcon}>
                        <Add fontSize={"large"} />
                    </i>
                </div>
            )}
            {isSearchOpen && (
                <div className={styles.products}>
                    {products.map((product) => {
                        const photo = product.photos?.[0];
                        return (
                            <div
                                key={product._id}
                                className={styles.product}
                                onClick={() => {
                                    onProductAddClick(product);
                                    toggleSearch();
                                }}
                            >
                                {photo && <img className={styles.image} src={photo.url} alt={product.localized.name} />}
                                <div className={styles.name}>{product.localized.name}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
});

export default ProductComparisonAddAction;
