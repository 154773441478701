import { IListProvider } from "shared/_common/list/IListProvider";
import { PRODUCT_TYPE, TProductLocalizedMdl } from "products/_models/ProductMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { productsStore } from "products/_stores/productsStore";

export class ProductTypeListStoreProvider implements IListProvider<TProductLocalizedMdl> {
    constructor(private productType: PRODUCT_TYPE) {}

    async list(
        _offset?: number,
        _limit?: number,
        _listId?: string,
        _sort?: { [p: string]: number },
        _filters?: TFilter[],
    ): Promise<{ count: number; items: TProductLocalizedMdl[] }> {
        const response = await productsStore.getListStore().load().promise;
        const items = response?.items.filter((product) => product.type === this.productType);
        return { items: items ?? [], count: items?.length ?? 0 };
    }

    putItemInCache(_item: TProductLocalizedMdl): void {}
}
