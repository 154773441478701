import { Dayjs } from "dayjs";
import { TUserSummaryMdl } from "users/_models/UserMdl";

export enum PAYMENT_STATUS {
    APPROVED = "APPROVED",
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
    FAILED = "FAILED",
}

export enum PAYMENT_PROVIDER {
    SQUARE = "SQUARE",
    STRIPE = "STRIPE",
    PAYPAL = "PAYPAL",
    NOT_WEBSITE = "NOT_WEBSITE",
}

export interface TPaymentBaseMdl {
    _id: string;

    amount: number;
    status: PAYMENT_STATUS;
    provider: PAYMENT_PROVIDER;
    providerPaymentId: string;

    order: string;
    user: TUserSummaryMdl;

    createdAt: Dayjs;
    updatedAt: Dayjs;
}

export interface TPaymentMdl extends TPaymentBaseMdl {
    payment_metadata?: {
        orderId?: string;
        referenceId?: string;
        customerId?: string;
        riskEvaluation?: {
            createdAt?: string;
            riskLevel?: string;
        };
        receiptNumber?: string;
        receiptUrl?: string;
    };
}
