import { TComponentsConfigs } from "components/ComponentRenderer";
import { SeeMoreComponent } from "components/misc/seeMore/SeeMoreComponent";
import { PartnerCardComponent } from "components/misc/partners/partnerCard/PartnerCardComponent";
import { NewsLetterComponent } from "components/misc/newsLetter/NewsLetterComponent";
import { StoreMap } from "components/misc/map/StoreMap";

export const MISC_COMPONENTS_CONFIGS: TComponentsConfigs = {
    seeMore: { component: SeeMoreComponent },
    partnerCard: { component: PartnerCardComponent },
    newsLetter: { component: NewsLetterComponent },
    storeMap: { component: StoreMap },
};
