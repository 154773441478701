import React from "react";
import Header from "main/menu/Header";
import { Footer } from "main/footer/Footer";
import styles from "_css/appContent.module.css";
import clsx from "clsx";
import { PanelObserver } from "main/menu/panel/PanelObserver";
import { NoSsr } from "@material-ui/core";
import { searchStore } from "main/menu/search/_stores/searchStore";
import { PhoneLogo } from "main/actionLogo/PhoneLogo";
import { settingsStore } from "settings/_stores/settingsStore";
import { NewsletterDialog } from "main/actionLogo/NewsletterDialog";
import { PopupBanner } from "main/popup/PopupBanner";

export const PAGE_REF: { current: HTMLDivElement | null } = { current: null };

export function PageRegular(props: any) {
    const contacts = settingsStore.getOne()?.contacts;

    return (
        <div ref={(ref) => (PAGE_REF.current = ref)} className={styles.container}>
            <NoSsr>
                <PanelObserver />
            </NoSsr>
            <PhoneLogo phone={contacts?.phone ?? "+1 (438) 308-7799"} />
            <NewsletterDialog openWithDelay />
            <div className={clsx("flex_column", styles.innerContainer)}>
                <Header />
                <div className="flex-1 position_relative" onClick={() => searchStore.open(false)}>
                    {props.children}
                </div>
                <Footer />
            </div>
            <PopupBanner />
        </div>
    );
}
