import React from "react";
import { TProductPhoto } from "products/_models/ProductMdl";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "products/gallery/_css/productGallery.module.css";

type Props = {
    photos: TProductPhoto[];
    onClick?: (index: number) => void;
    onSlideChange?: () => void;
};

const ProductGalleryPreviewSlider = ({ photos, onClick, onSlideChange }: Props) => {
    return (
        <Swiper slidesPerView={1} loop onSlideChange={onSlideChange}>
            {photos.map((photo, index) => {
                return (
                    <SwiperSlide key={index} onClick={onClick ? () => onClick(index) : undefined}>
                        <div className={styles.mainPhoto} style={{ backgroundImage: `url(${photo.url})` }} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default ProductGalleryPreviewSlider;
