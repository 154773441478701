import React from "react";
import { Container, NoSsr } from "@material-ui/core";
import { ProfileInfo } from "users/profile/info/ProfileInfo";

export function ProfilePage() {
    return (
        <NoSsr>
            <Container className="mt_20 mb_40">
                <ProfileInfo />
            </Container>
        </NoSsr>
    );
}
