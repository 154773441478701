import React from "react";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { EucCardsOptions } from "products/cards/EucCardsOptions";

type Props = {
    product: TProductLocalizedMdl;
};

export function ScooterCard(props: Props) {
    return (
        <>
            <EucCardsOptions product={props.product} />
        </>
    );
}
