import React from "react";
import styles from "./_css/homepageExternalArticlesSection.module.css";
import { externalArticlesStore } from "externalArticles/_stores/externalArticlesStore";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const HomepageExternalArticlesSection = () => {
    const { t } = useTranslation();
    const externalArticles = externalArticlesStore.getListStore("homepage")?.items;

    if (!externalArticles?.length) return null;
    return (
        <div className={clsx("textAlign_center", styles.container)}>
            <h2 className={"mb_40"}>{t("externalArticles.title")}</h2>
            <Swiper
                className={clsx(styles.container, "page-container")}
                spaceBetween={16}
                slidesPerView={"auto"}
                navigation
                freeMode
                pagination
            >
                {externalArticles?.map((externalArticle) => {
                    if (!externalArticle) return null;
                    return (
                        <SwiperSlide className={clsx(styles.card, "paper")} key={externalArticle._id}>
                            <a
                                className={styles.link}
                                href={externalArticle.url}
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                            >
                                <p className={styles.text}>{externalArticle.text}</p>
                                <img
                                    src={externalArticle.logo?.url}
                                    alt={externalArticle.text}
                                    className={styles.logo}
                                />
                            </a>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default HomepageExternalArticlesSection;
