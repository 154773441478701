import React from "react";
import { CheckBoxFilter } from "shop/filtersType/CheckBoxFilter";
import _ from "lodash";
import { TProductConfigDef } from "_configs/productConfig";
import { ListStore } from "_common/list/ListStore";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";

type Props = {
    listStore: ListStore<TProductLocalizedMdl>;
    optionConfig: TProductConfigDef;
};

export function CheckBoxFilters(props: Props) {
    const uniqueOptionsValues = _.uniq(
        props.listStore.items.map((item) => {
            const key = props.optionConfig.filter?.itemPath;
            return item && key ? _.get(item, key) : undefined;
        }),
    );
    const optionsValues = uniqueOptionsValues.sort((a, b) => (a && b && a > b ? 1 : -1));
    return (
        <div className={"pv_10"}>
            {optionsValues.map((option) => {
                if (!option) return null;
                return (
                    <CheckBoxFilter
                        key={option}
                        listStore={props.listStore}
                        optionConfig={props.optionConfig}
                        optionValue={option}
                    />
                );
            })}
        </div>
    );
}
