import React from "react";
import { useMenuUserItems } from "main/menu/_hooks/useMenuUserItems";
import { RootMenuUser } from "main/menu/RootMenuUser";
import styles from "./_css/header.module.css";

export function MenuUser() {
    const menuUserItems = useMenuUserItems();

    return (
        <div className={styles.menuUserContainer}>
            {menuUserItems.map((item, index) => (
                <RootMenuUser key={index} withLang={true} menu={item} />
            ))}
        </div>
    );
}
