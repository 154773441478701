import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import clsx from "clsx";
import styles from "./_css/partnerCardComponent.module.css";

type Props = {
    style?: CSSProperties;
    text?: string;
    author?: string;
    mark?: string;
    image: string;
    $component: TComponent;
};

export function PartnerCardComponent(props: Props) {
    return (
        <div className={styles.container}>
            <div className={clsx(styles.comment, "p_20")}>{props.text}</div>
            <div className={clsx(styles.commentBottom, "flex_center_center")}>
                <div className={styles.arrowBottom} />
            </div>
            <div className={clsx(styles.bottom, "flex_row_center mt_20")}>
                <div className={clsx(styles.photo, "mr_10")} style={{ backgroundImage: `url('${props.image}')` }} />
                <div className={styles.name}>
                    <div className={styles.fullName}>{props.author}</div>
                    <div className={styles.company}>{props.mark}</div>
                </div>
            </div>
        </div>
    );
}
