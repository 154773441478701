import React, { useState } from "react";
import dayjs from "dayjs";
import { TCommentBaseMdl } from "./_models/CommentMdl";
import { observer } from "mobx-react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useWindowSize } from "_common/_utils/hookUtils";
import { CommentInModal } from "comments/CommentInModal";
import { userStore } from "users/_stores/userStore";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";

type Props = {
    pageId: string;
};

export const CommentsAdd = observer((props: Props) => {
    const [isOpenedCommentModal, setIsOpenedCommentModal] = useState(false);
    const windowSize = useWindowSize();
    const [comment, setComment] = useState<TCommentBaseMdl>({
        user: userStore.user ?? { firstName: "", lastName: "" },
        title: "",
        description: "",
        lang: i18next.language as TLang,
        type: "ARTICLE",
        pageId: props.pageId,
        createdAt: dayjs(),
        updatedAt: dayjs(),
    });
    return (
        <div>
            <UiButton color="primary" onClick={() => setIsOpenedCommentModal(true)} variant={"contained"}>
                Ajouter un commentaire
            </UiButton>
            <Dialog
                fullScreen={windowSize.width < 750}
                closeCross={true}
                closeSize={"large"}
                open={isOpenedCommentModal}
                onClose={() => setIsOpenedCommentModal(false)}
            >
                <CommentInModal comment={comment} setIsOpening={setIsOpenedCommentModal} setComment={setComment} />
            </Dialog>
        </div>
    );
});
