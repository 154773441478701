import React from "react";
import styles from "./_css/header.module.css";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import { Menu } from "main/menu/Menu";
import { RootMenu } from "main/menu/RootMenu";
import { settingsStore } from "settings/_stores/settingsStore";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { useScrollPosition, useWindowSize } from "_common/_utils/hookUtils";
import { PAGE_REF } from "pages/PageRegular";
import { useTranslation } from "react-i18next";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { URLS } from "_configs/URLS";
import AnnouncementBar from "main/menu/AnnouncementBar";

function Header() {
    const settings = settingsStore.getOne();
    const lang = i18nextInstance.language;
    const localizedMenu = settings?.menu?.localized?.[lang as TLang]?.items ?? [];
    const scrollPosition = useScrollPosition(PAGE_REF);
    const windowSize = useWindowSize();
    const { t } = useTranslation();

    return (
        <header className={styles.header}>
            <div>
                <div
                    className={clsx("mui-fixed", styles.bottomWrapper)}
                    style={{
                        top: scrollPosition > 40 ? 0 : 40,
                        position: scrollPosition > 40 ? "fixed" : "unset",
                        zIndex: 950,
                    }}
                >
                    <Container>
                        <div className={clsx(styles.bottomContainer, "flex_row alignItems_center")}>
                            <Menu />
                        </div>
                    </Container>
                </div>
                <div
                    className={clsx("mui-fixed", styles.menuWrapper)}
                    style={{
                        top: scrollPosition > 40 ? 60 : 100,
                        position: scrollPosition > 40 ? "fixed" : "unset",
                    }}
                >
                    <Container>
                        <div className={clsx(styles.menuContainer, "flex_row alignItems_center")}>
                            {localizedMenu.map((rootMenu, index) => (
                                <RootMenu key={index} menu={rootMenu} />
                            ))}

                            <div className="flex-1" />
                            {/*<ExternalOrNavLink url={"/" + lang + "/" + i18nextInstance.t("routes.giftcard")}>*/}
                            {/*    <div className={clsx(styles.giftCardLink, "flex_row_center")}>*/}
                            {/*        <div className={styles.heart}>*/}
                            {/*            <i className={clsx("icon-gift1", styles.iconGift)} />*/}
                            {/*        </div>*/}
                            {/*        <strong className={styles.text}> {t("words.giftCard")}</strong>*/}
                            {/*    </div>*/}
                            {/*</ExternalOrNavLink>*/}
                            <ExternalOrNavLink url={URLS.initiation()} className={styles.initiation}>
                                {t("initiation.header")}
                            </ExternalOrNavLink>
                            <div className={"flex-1"} />
                            {settings?.contacts?.phone && (
                                <a href={`tel:${settings?.contacts.phone}`} className="flex_row_center ml_20">
                                    <i className={clsx("icon-phone1 mr_5", styles.icon)} />
                                    <span className={styles.phoneNumber}>{settings?.contacts.phone}</span>
                                </a>
                            )}
                        </div>
                    </Container>
                </div>
            </div>
            <AnnouncementBar />
            <div
                style={{
                    height: scrollPosition > 40 ? (windowSize.width > 1000 ? 150 : 90) : 30,
                }}
            />
        </header>
    );
}

export default Header;
