import React from "react";
import styles from "./_css/mobileMenu.module.css";
import clsx from "clsx";
import { settingsStore } from "settings/_stores/settingsStore";
import { RootMobileMenu } from "main/menu/mobile/RootMobileMenu";
import { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { MobileMenuUser } from "main/menu/mobile/MobileMenuUser";
import { Logo } from "_common/icons/Logo";
import { useWindowSize } from "_common/_utils/hookUtils";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";

type Props = {
    opened: boolean;
    onClose: () => void;
};

export function MobileMenu(props: Props) {
    const lang = i18nextInstance.language;
    const menu = settingsStore.getOne()?.menu;
    const localizedMenu = menu?.localized?.[lang as TLang]?.items;
    const windowsSize = useWindowSize();
    const { t } = useTranslation();
    if (!localizedMenu) return null;

    return (
        <div
            className={clsx(styles.container, {
                [styles.opened]: props.opened,
                [styles.fullWidthContainer]: windowsSize.width < 600,
            })}
        >
            <div className={styles.logo}>
                <Logo width={100} fill="#FFF" />
            </div>
            {localizedMenu.map((rootMenu, index) => (
                <RootMobileMenu key={index} menu={rootMenu} onClose={props.onClose} />
            ))}
            {/*<ExternalOrNavLink style={{ color: "red" }} url={URLS.giftCard()} onClick={props.onClose}>*/}
            {/*    <div className={styles.link_giftCard} style={{ color: "white" }}>*/}
            {/*        <div className={styles.heart}>*/}
            {/*            <i className={clsx("icon-gift1", styles.iconGift)} />*/}
            {/*        </div>*/}
            {/*        {t("words.giftCard")}*/}
            {/*    </div>*/}
            {/*</ExternalOrNavLink>*/}
            <div className={"mt_30"}>
                <ExternalOrNavLink url={URLS.initiation()} className={styles.initiation} onClick={props.onClose}>
                    {t("initiation.header")}
                </ExternalOrNavLink>
                <MobileMenuUser onClose={props.onClose} />
            </div>
        </div>
    );
}
