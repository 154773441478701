import React from "react";
import { TCommentBaseMdl } from "./_models/CommentMdl";
import i18next from "i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { commentsStore } from "comments/_stores/commentsStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { useFormContext } from "react-hook-form";

type Props = {
    comment: TCommentBaseMdl;
    onCommentChange: (comment: TCommentBaseMdl) => void;
    setIsOpening: (boolean: boolean) => void;
};

export function CommentAddBtn(props: Props) {
    const comment = props.comment;
    const form = useFormContext();
    return (
        <>
            <UiButton
                variant="contained"
                color="primary"
                onClick={form.handleSubmit((data) => {
                    commentsStore
                        .create({
                            ...comment,
                            ...data,
                        } as TCommentBaseMdl)
                        .then(() => {
                            toast.success(i18next.t("comment.successCommenting"));
                            props.onCommentChange({
                                ...comment,
                                description: "",
                                title: "",
                                user: { firstName: "", lastName: "" },
                            });
                            props.setIsOpening(false);
                        })
                        .catch((e) => toast.error(getErrorMessage(e)));
                })}
            >
                {i18next.t("words.send")}
            </UiButton>
        </>
    );
}
