import React from "react";
import styles from "./_css/homepageBanner.module.css";
import clsx from "clsx";
import { slideShowsStore } from "slideShows/_stores/slideShowsStore";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { Container } from "@material-ui/core";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { Helmet } from "react-helmet-async";

const HomepageBanner = () => {
    const _banner = slideShowsStore.getListStore("slideShows").items;
    const banner = _banner.find((item) => item?.isHomepageBanner);

    if (!banner) return <div className={clsx(styles.placeholder)} />;

    const homeSlide = banner.slides[0];
    const localizedHomeSlide = homeSlide[i18nextInstance.language as TLang];

    return (
        <>
            <Helmet>
                <link rel="preload" as="image" href={localizedHomeSlide.image} />
            </Helmet>
            <div className={styles.container} style={{ backgroundImage: `url('${localizedHomeSlide.image}')` }}>
                <Container className={styles.content}>
                    <div className={clsx("flex_row flex-1", styles.content)}>
                        <div className={clsx(styles.leftSide, "flex_column justifyContent_center")}>
                            <h3 className={styles.title}>{localizedHomeSlide.title}</h3>
                            <div>{localizedHomeSlide.text}</div>
                            <div>
                                <ExternalOrNavLink url={localizedHomeSlide.url}>
                                    <UiButton variant={"contained"} color={"primary"}>
                                        {localizedHomeSlide.cta}
                                    </UiButton>
                                </ExternalOrNavLink>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default HomepageBanner;
