import React, { useState } from "react";
import { CardContent, CardHeader, IconButton } from "@material-ui/core";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { HeardAboutUsInput } from "users/auth/blocks/HeardAboutUsInput";
import { UserInfoNotifications } from "users/profile/info/userInfo/UserInfoNotifications";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { useHistory } from "react-router";
import { authStore } from "users/auth/_stores/authStore";
import { toast } from "react-toastify";
import { URLS } from "_configs/URLS";
import { getErrorMessage } from "_common/errors/errorUtils";

type TFormData = {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    notification: boolean;
    heardAboutUs: string;
};

export function SignUpFormBlock() {
    const { t } = useTranslation();
    const form = useForm<TFormData>({
        mode: "onBlur",
    });
    const { loading, setPromise } = useLoadingFromPromise();
    const [isLoading, setIsLoading] = useState(!!loading?.isLoading);
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const onSubmit = (values: TFormData) => {
        setIsLoading(true);
        const promise = authStore.signUp(
            values.email,
            values.password,
            values.firstName,
            values.lastName,
            values.notification,
            values.heardAboutUs,
        );
        setPromise(promise);
        promise.then(
            () => {
                toast.success(t("auth.signUp.successfullyRegistered"), { position: toast.POSITION.TOP_CENTER });
                history.push(URLS.auth.signIn());
            },
            (e) => {
                toast.error(getErrorMessage(e));
                loading?.setError(e);
                setIsLoading(!!loading?.isLoading);
            },
        );
    };

    return (
        <>
            <CardHeader className="mt_10" style={{ textAlign: "center" }} title={t("auth.signUp.title")} />
            <CardContent>
                <ErrorBlock error={loading?.error} />
                <FormProvider {...form}>
                    <InputBlock label={t("words.email")}>
                        <Input
                            name="email"
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            })}
                            error={form.errors.email}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.firstName")}>
                        <Input
                            name="firstName"
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={form.errors.firstName}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.lastName")}>
                        <Input
                            name="lastName"
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                            })}
                            error={form.errors.lastName}
                            disabled={isLoading}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.password")}>
                        <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                                minLength: {
                                    value: 6,
                                    message: t("auth.signUp.passwordsLength"),
                                },
                            })}
                            error={form.errors.password}
                            disabled={isLoading}
                            endIcon={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    size="small"
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            }
                        />
                    </InputBlock>
                    <InputBlock label={t("auth.signUp.passwordConfirmation")}>
                        <Input
                            name="confirmPassword"
                            type={showPassword ? "text" : "password"}
                            ref={form.register({
                                required: t<string>("errors.forms.required"),
                                minLength: {
                                    value: 8,
                                    message: t<string>("auth.signUp.passwordsLength"),
                                },
                                validate: (value) =>
                                    value !== form.watch("password")
                                        ? t<string>("auth.signUp.passwordsMismatch")
                                        : undefined,
                            })}
                            error={form.errors.confirmPassword}
                            disabled={isLoading}
                            endIcon={
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    size="small"
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            }
                        />
                    </InputBlock>
                    <InputBlock label={t("auth.signUp.heardAboutUs")}>
                        <Controller
                            defaultValue={"socialMedia"}
                            as={HeardAboutUsInput}
                            name={"heardAboutUs"}
                            control={form.control}
                        />
                    </InputBlock>
                    <UserInfoNotifications isEditing={true} onStopEditing={() => null} />
                    <div className="flex_center_center mt_20">
                        <UiButton
                            onClick={form.handleSubmit(onSubmit)}
                            disabled={isLoading}
                            variant="contained"
                            color="primary"
                        >
                            {t(isLoading ? "loaders.message" : "auth.signUp.title")}
                        </UiButton>
                    </div>
                </FormProvider>
            </CardContent>
        </>
    );
}
