import React, { useEffect, useRef, useState } from "react";
import styles from "./_css/productPage.module.css";
import { Container, Tab, Tabs } from "@material-ui/core";
import { productsStore } from "products/_stores/productsStore";
import { ProductDescription } from "products/description/ProductDescription";
import { ProductReviews } from "products/reviews/ProductReviews";
import Grid from "@material-ui/core/Grid";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { ProductContext } from "products/ProductContext";
import { ProductStore } from "products/_stores/ProductStore";
import { ProductGallery } from "products/gallery/ProductGallery";
import i18next from "i18next";
import { ComponentRenderer } from "components/ComponentRenderer";
import { PageRendererContext } from "pages/PageContext";
import { ProductGalleryPage } from "products/gallery/ProductGalleryPage";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ProductPageMeta } from "products/ProductPageMeta";
import { NotFound } from "pages/NotFound";
import { ProductFeatures } from "products/ProductFeatures";
import { PRODUCT_TYPE, TProductLocalizedMdl } from "products/_models/ProductMdl";
import { NewsLetterComponent } from "components/misc/newsLetter/NewsLetterComponent";
import ProductComparison from "products/comparison/ProductComparison";
import { useParams } from "react-router";
import ProductRelatedProducts from "products/ProductRelatedProducts";
import { PAGE_REF } from "pages/PageRegular";
import useCrisp from "_common/hooks/useCrisp";

type Props = {
    product: TProductLocalizedMdl;
};

function _ProductPage(props: Props) {
    const reviewsRef = useRef(null);
    const translation = useTranslation();

    function onScrollToReviews() {
        if (reviewsRef && reviewsRef.current && PAGE_REF.current) {
            PAGE_REF.current.scrollTo({
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                top: reviewsRef.current.offsetTop,
                behavior: "smooth",
            });
        }
    }

    const product = props.product;
    const [tab, setTab] = useState<"INFO" | "FEATURES" | "COMPARISON" | "REVIEWS">(
        product?.localized.component ? "INFO" : "FEATURES",
    );
    const items = productsStore.getBreadcrumbItemsFromProduct(product, translation);
    return (
        <>
            <ProductPageMeta product={product} />
            <Container>
                <Breadcrumb currentItem={{ itemLabel: product.localized.name }} items={items} />
                <Grid container spacing={3} className="mt_20">
                    <Grid item xs={12} sm={12} md={5}>
                        <ProductGallery />
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <ProductDescription onScrollToReviews={onScrollToReviews} />
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <div className={styles.tab}>
                    <Tabs
                        className="mt_20 mb_20"
                        value={tab}
                        onChange={(_e, newValue) => setTab(newValue)}
                        textColor="primary"
                        indicatorColor="primary"
                        variant={"scrollable"}
                        scrollButtons={"auto"}
                    >
                        {product.localized.component && <Tab label={i18next.t("words.description")} value="INFO" />}
                        <Tab label={i18next.t("words.characteristics")} value="FEATURES" />
                        {(product.type === PRODUCT_TYPE.EUC || product.type === PRODUCT_TYPE.SCOOTER) && (
                            <Tab label={i18next.t("words.comparison")} value="COMPARISON" />
                        )}
                        <Tab label={i18next.t("words.reviews")} value="REVIEWS" />
                    </Tabs>
                </div>
            </Container>
            {product.localized.component && tab === "INFO" && (
                <PageRendererContext pageRendererStore={new PageRendererStore()}>
                    <ComponentRenderer component={product.localized.component} isRoot />
                </PageRendererContext>
            )}
            <ProductFeatures hidden={tab !== "FEATURES"} />
            {tab === "COMPARISON" && <ProductComparison />}
            <Container className={tab === "REVIEWS" ? undefined : "mt_40"} innerRef={reviewsRef}>
                <ProductReviews />
            </Container>
            <Container className={"mt_40"}>
                <ProductRelatedProducts />
            </Container>
            <div className={"mt_40"}>
                <NewsLetterComponent />
            </div>
            <ProductGalleryPage />
        </>
    );
}

export function ProductPage() {
    const { sendHelpMessage } = useCrisp();
    const { aliasUrl } = useParams();
    const product = productsStore.getByAliasUrl(aliasUrl ?? "");
    useEffect(() => {
        sendHelpMessage();
    }, []);
    if (!aliasUrl) return <NotFound />;
    if (!product) return <NotFound />;
    return (
        <ProductContext productStore={new ProductStore(product)}>
            <_ProductPage product={product} />
        </ProductContext>
    );
}
