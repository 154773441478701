import React from "react";
import { useProductStore } from "products/ProductContext";
import { UiMarkStars } from "_common/ui/UiMarkStars";
import { useTranslation } from "react-i18next";

const ProductReviewsGlobalMark = () => {
    const { t } = useTranslation();
    const { product } = useProductStore();
    const marks = product.reviews.map((review) => review.mark);
    const averageMark = marks.reduce((a, b) => a + b, 0) / marks.length;

    if (!product.reviews.length) return null;
    return (
        <div>
            <div className={"flex_row alignItems_center"}>
                <UiMarkStars mark={averageMark} />
                <p className={"text_discreet"}>{averageMark.toFixed(1)} / 5</p>
            </div>
            <p className={"text_discreet"}>
                {" "}
                {product.reviews.length > 1
                    ? t("product.review.plural", { value: product.reviews.length })
                    : t("product.review.single", { value: product.reviews.length })}
            </p>
        </div>
    );
};

export default ProductReviewsGlobalMark;
