import React, { useState } from "react";
import { observer } from "mobx-react";
import { useProductStore } from "products/ProductContext";
import { useTranslation } from "react-i18next";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { useWindowSize } from "_common/_utils/hookUtils";
import { FundingModal } from "products/funding/FundingModal";
import clsx from "clsx";
import styles from "./_css/productFunding.module.css";
import LendCareLogo from "../../assets/images/LendCareLogo.png";
import IfExpressLogo from "../../assets/images/IfExpressLogo.png";
import { formatPrice } from "_common/_utils/currencyUtils";
import { UiButton } from "../../_common/ui/mui/buttons/UiButton";

export const ProductFunding = observer(() => {
    const productStore = useProductStore();
    const [isOpenedProductFunding, setIsOpenedProductFunding] = useState(false);
    const [fundingSelected, setFundingSelected] = useState<"LENDCARE" | "IFEXPRESS">("LENDCARE");
    const windowSize = useWindowSize();
    const { t } = useTranslation();
    if (!productStore.product.funding) return null;
    return (
        <div>
            <div
                className={styles.link}
                onClick={() => {
                    setFundingSelected("LENDCARE");
                    setIsOpenedProductFunding(true);
                }}
            >
                <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{
                        __html: t("product.fundingText", {
                            minPrice: formatPrice(productStore.product.funding.minPrice),
                        }),
                    }}
                />
                <img src={LendCareLogo} alt={"lendCare"} className={styles.logo} />
            </div>
            <div
                onClick={() => {
                    setFundingSelected("IFEXPRESS");
                    setIsOpenedProductFunding(true);
                }}
                className={clsx("flex_row_center mt_10", styles.link)}
            >
                <div className={styles.text}>{t("product.fundingTextIfExpress")} </div>
                <img src={IfExpressLogo} alt={"lendCare"} className={styles.logo} />
            </div>
            <UiButton
                variant={"outlined"}
                color={"primary"}
                className={clsx("mt_10", styles.btnFunding)}
                onClick={() => setIsOpenedProductFunding(true)}
            >
                {t("funding.financing").toLocaleUpperCase()}
            </UiButton>
            <Dialog
                closeCross={true}
                closeSize={windowSize.width < 750 ? "default" : "small"}
                open={isOpenedProductFunding}
                onClose={() => setIsOpenedProductFunding(false)}
            >
                <FundingModal
                    setIsOpening={() => setIsOpenedProductFunding(true)}
                    funding={productStore.product.funding}
                    fundingType={fundingSelected}
                />
            </Dialog>
        </div>
    );
});
