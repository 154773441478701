import React from "react";
import { useProductStore } from "products/ProductContext";
import ProductCard from "products/ProductCard";
import { useTranslation } from "react-i18next";
import styles from "./_css/productRelatedProducts.module.css";
import clsx from "clsx";
import { fillRelatedProductsIds } from "_common/_utils/productUtils";

const MAX_RELATED_PRODUCTS = 4;

const ProductRelatedProducts = () => {
    const { t } = useTranslation();
    const { product } = useProductStore();
    const relatedProducts = fillRelatedProductsIds(product, MAX_RELATED_PRODUCTS);
    if (!relatedProducts || !relatedProducts.length) return null;
    return (
        <div className={clsx(styles.container, "p_20")}>
            <h3>{t("product.relatedProducts")}</h3>
            <div className={clsx(styles.productList, "mt_10")}>
                {relatedProducts.map((product) => {
                    return (
                        <div key={product} className={styles.product}>
                            <ProductCard productId={product} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProductRelatedProducts;
