import React from "react";
import { Grid } from "@material-ui/core";
import { ListStore } from "_common/list/ListStore";
import { TProductLocalizedMdl } from "products/_models/ProductMdl";
import { NoResults } from "shop/NoResults";
import { observer } from "mobx-react-lite";
import ProductCard from "products/ProductCard";
import styles from "./_css/shopList.module.css";

type Props = {
    listStore: ListStore<TProductLocalizedMdl>;
};

export const ShopList = observer((props: Props) => {
    if (props.listStore.filteredItems.length === 0) return <NoResults />;
    return (
        <div>
            <Grid container alignItems="flex-start" spacing={1}>
                {props.listStore.filteredItems.map((product) => {
                    if (!product) return null;
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
                            <div className={styles.container}>
                                <ProductCard productId={product._id} />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
});

ShopList.displayName = "ShopList";
