import React from "react";
import { TOverlayOption } from "main/overlay/_stores/overlayStore";
import { Spinner } from "_common/loaders/Spinner";
import styles from "./_css/overlay.module.css";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

type Props = {
    option?: TOverlayOption;
};

export function Overlay(props: Props) {
    if (!props.option) return null;

    return (
        <div
            className={clsx("flex_center_center flex_column", styles.container)}
            onClick={props.option.closeable ? props.option.onClose : undefined}
        >
            {props.option.withSpinner ? (
                <div className="mb_20">
                    <Spinner />
                </div>
            ) : null}
            <IconButton
                size={"small"}
                className={styles.closeIcon}
                onClick={props.option.onClose}
                aria-label="Close button"
            >
                <CloseIcon style={{ color: "#FFF" }} fontSize="large" />
            </IconButton>
            <div>{props.option.children}</div>
        </div>
    );
}
