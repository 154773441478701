import React from "react";
import { LocalizedSlideMdl } from "slideShows/_models/SlideMdl";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import styles from "./_css/singleSlide.module.css";
import clsx from "clsx";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";

type Props = {
    slide: LocalizedSlideMdl;
};

export function SingleSlide(props: Props) {
    if (!props.slide) return null;
    const language = i18next.language;
    const slide = props.slide[language as TLang];
    if (!slide?.image) return null;
    return (
        <div className={styles.container}>
            <a href={slide.url}>
                <img className={styles.image} src={slide.image} alt={slide.title} />

                <div className={clsx(styles.content, "flex_center_center")}>
                    <div className={"flex_column alignItems_center"}>
                        <h1 className={styles.title}>{slide.title}</h1>
                        <div className={clsx(styles.text, "mv_10")}>{slide.text}</div>
                        {slide.cta && slide.url && (
                            <div className={styles.button}>
                                <ExternalOrNavLink url={slide.url}>
                                    <UiButton color={"primary"} variant={"contained"}>
                                        {slide.cta}
                                    </UiButton>
                                </ExternalOrNavLink>
                            </div>
                        )}
                    </div>
                </div>
            </a>
        </div>
    );
}
