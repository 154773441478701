import { TProductLocalizedMdl, TReviewEditionMdl } from "products/_models/ProductMdl";
import { action, computed, observable } from "mobx";
import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { computeFinalePrice, isActiveDiscountVariant } from "_common/_utils/productUtils";
import { TVariantMdl } from "products/_models/VariantMdl";
import { productsStore } from "products/_stores/productsStore";

export class ProductStore {
    @observable product: TProductLocalizedMdl;
    @observable isGalleryOpened = -1;
    @observable selectedVariantId: string | undefined;
    @observable linkedVariants: TVariantMdl[] = [];
    @observable warranty = false;

    constructor(product: TProductLocalizedMdl) {
        this.product = product;
        if (this.product.variants) this.selectedVariantId = this.product.variants[0]._id;
    }

    @computed get selectedVariant(): TVariantMdl | undefined {
        if (this.product.variants && this.product.variants.length > 0 && this.selectedVariantId) {
            const variant = this.product.variants.find(
                (variant: TVariantMdl) => variant._id === this.selectedVariantId,
            );
            return variant ? (computeFinalePrice(variant) as TVariantMdl) : undefined;
        } else return undefined;
    }

    @computed get warrantyPrice() {
        const price = this.selectedVariant?.price;
        return price ? productsStore.getWarrantyPrice(price) : 0;
    }

    @computed get isDiscount() {
        return this.selectedVariant
            ? this.selectedVariant.discount
                ? isActiveDiscountVariant(this.selectedVariant.discount)
                : false
            : false;
    }

    @action selectVariant(selectedVariantId: string | undefined) {
        this.selectedVariantId = selectedVariantId;
    }

    @action addLinkedVariant(variant: TVariantMdl) {
        this.linkedVariants.push(variant);
    }

    @action removeLinkedVariant(variantToRemove: TVariantMdl) {
        const variantToRemoveIdx = this.linkedVariants.findIndex((variant) => variant._id === variantToRemove._id);
        this.linkedVariants.splice(variantToRemoveIdx, 1);
    }

    @action toggleWarranty() {
        this.warranty = !this.warranty;
    }

    openGallery(imageIdx = 0) {
        this.isGalleryOpened = imageIdx;
    }

    closeGallery() {
        this.isGalleryOpened = -1;
    }

    addReview(review: TReviewEditionMdl) {
        return fetchUtils.post<TReviewEditionMdl>(
            sharedConfig.apiUrl + "/products/" + this.product._id + "/reviews",
            review,
        );
    }
}
