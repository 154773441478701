import React from "react";
import styles from "./_css/footer.module.css";
import { NavLink } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { Container, Grid } from "@material-ui/core";
import clsx from "clsx";
import { FooterInformation } from "main/footer/FooterInformation";
import { FooterNavigation } from "main/footer/FooterNavigation";
import { settingsStore } from "settings/_stores/settingsStore";
import { useTranslation } from "react-i18next";
import { Logo } from "_common/icons/Logo";
import { ServicesDescription } from "products/description/ServicesDescription";
import { FooterLinks } from "main/footer/FooterLinks";

export function Footer() {
    const links = settingsStore.getOne()?.links;
    const contacts = settingsStore.getOne()?.contacts;
    const { t } = useTranslation();
    return (
        <footer>
            <div className={styles.servicesContainer}>
                <Container>
                    <ServicesDescription dark={true} />
                </Container>
            </div>
            <div className={styles.container}>
                <Container>
                    <div className="flex_row flexWrap_wrap">
                        <div className={clsx(styles.logos, "flexShrink_0")}>
                            <div className={"mb_10"}>
                                <NavLink to={URLS.home()}>
                                    <Logo width={120} fill="#FFF" />
                                </NavLink>
                            </div>
                            {(links?.fb || links?.tw || links?.ig || links?.yt || links?.li) && (
                                <FooterLinks links={links} />
                            )}
                            <div>
                                <i className="icon-mail1 mr_10" />
                                <a className={styles.link} href="mailto:contact@noaio.com" rel={"nofollow"}>
                                    contact@noaio.com
                                </a>
                            </div>
                            <div>
                                <i className="icon-phone mr_10" />
                                <a
                                    className={styles.link}
                                    href={"tel:" + (contacts?.phone ?? "+1 (438) 308-7799")}
                                    rel={"nofollow"}
                                >
                                    {contacts?.phone ?? "+1 (438) 308-7799"}
                                </a>
                            </div>
                        </div>
                        <Grid
                            container
                            spacing={3}
                            className={clsx(
                                styles.menuContainer,
                                "flex-1 justifyContent_center flex_row flexWrap_wrap pt_20",
                            )}
                        >
                            <FooterNavigation />
                            <FooterInformation />
                        </Grid>
                    </div>
                    <div className={clsx(styles.allRightsReserved, "pt_40 flex_center_center")}>
                        {t("footer.copyright")} - made by{" "}
                        <a
                            style={{ color: "white" }}
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                            href={"https://lesentrecodeurs.com"}
                        >
                            Les Entrecodeurs
                        </a>
                    </div>
                </Container>
            </div>
        </footer>
    );
}
