import React from "react";
import clsx from "clsx";
import styles from "./_css/homepageProductTypeBlock.module.css";
import { productsStore } from "products/_stores/productsStore";
import { PRODUCT_TYPE } from "products/_models/ProductMdl";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { URLS } from "_configs/URLS";
import SwiperCore, { EffectFade } from "swiper";
import { Link } from "react-router-dom";

type Props = {
    productType: PRODUCT_TYPE;
};

SwiperCore.use([EffectFade]);
const HomepageProductTypeBlock = ({ productType }: Props) => {
    const { t } = useTranslation();
    const images = productsStore
        .getListStore(productType)
        .items.filter((product) => product && product.photos && product.photos.length > 0)
        .slice(0, 5)
        .map((product) => {
            return product!.photos![0]?.url;
        });

    return (
        <div className={clsx(styles.container, "paper flex_center_center flex_column")}>
            <Swiper
                effect={"fade"}
                fadeEffect={{
                    crossFade: true,
                }}
                slidesPerView={1}
                spaceBetween={0}
                centeredSlides
                loop
                autoplay={{
                    delay: 2500,
                }}
                className={styles.imagesContainer}
                id={"swiperContainer"}
            >
                {images.map((image) => {
                    return (
                        <SwiperSlide key={image} className={styles.slide}>
                            {image && <img src={image} alt="" className={styles.productImg} />}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className={styles.content}>
                <h2>{t(`homepage.productTypes.${productType}.title`)}</h2>
                <p className={"paragraph"}>{t(`homepage.productTypes.${productType}.description`)}</p>
                <Link to={URLS.shop(t("routes.productTypes." + productType))}>
                    <UiButton variant={"contained"} color={"primary"}>
                        {t("homepage.productTypes.cta")}
                    </UiButton>
                </Link>
            </div>
        </div>
    );
};

export default HomepageProductTypeBlock;
