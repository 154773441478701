import React, { useEffect, useState } from "react";
import { appConfig } from "_configs/appConfig";
import sharedConfig from "_configs/sharedConfig";
import "./_css/checkoutPaymentBlock.css";
import { TPaymentMdl } from "admin/payments/_models/CrudPaymentMdl";
import { TOrderListingMdl, TOrderMdl } from "orders/_models/OrderMdl";
import { TGiftCardMdl } from "giftCards/_models/GiftCardMdl";
import { SquarePaymentForm } from "checkout/square/SquarePaymentForm";

type Props = {
    onSuccess: (payment: TPaymentMdl) => void;
    order: Partial<TOrderMdl> | TOrderListingMdl;
    amount: number;
    giftCards?: Partial<TGiftCardMdl>[];
};

function loadSquare() {
    return new Promise((resolve) => {
        (function () {
            const scriptElement = document.createElement("script");
            scriptElement.src = sharedConfig.isProd
                ? "https://web.squarecdn.com/v1/square.js"
                : "https://sandbox.web.squarecdn.com/v1/square.js";
            scriptElement.async = true;
            scriptElement.onload = () => resolve(!!(window as any).Square);
            document.getElementsByTagName("head")[0].appendChild(scriptElement);
        })();
    });
}

export function CheckoutPaymentBlock(props: Props) {
    // const [checkingOut, setCheckingOut] = useState(false);
    const [isSquareLoaded, setIsSquareLoaded] = useState(false);

    useEffect(() => {
        loadSquare().then(() => setIsSquareLoaded(true));
    }, []);

    return isSquareLoaded ? (
        <SquarePaymentForm
            order={props.order}
            onSuccess={props.onSuccess}
            sandbox={!sharedConfig.isProd}
            applicationId={appConfig.square.appId}
            locationId={appConfig.square.locationId}
        />
    ) : null;
}
