import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { TBreadcrumbItem } from "breadcrumb/Breadcrumb";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { useLocation } from "react-router";
import _ from "lodash";

export function getItemsForBreadCrumb(page: IPageMdl) {
    let item: TBreadcrumbItem[] = [];
    const url = useLocation().pathname;
    const urlItems = url.split("/").slice(2);
    urlItems.pop();
    urlItems.forEach(() =>
        item.push({
            itemLabelKey: "breadcrumb.ourServices",
            url: "/" + i18nextInstance.language + "/" + urlItems,
        }),
    );
    if (page.type === PAGE_TYPE.ARTICLE) {
        item = [
            {
                itemLabelKey: "breadCrumb.blog",
                url: "/" + i18nextInstance.language + "/blog",
            },
        ];
    }
    return item;
}

export function getI18nExpByLang(lang: string, key: string) {
    return _.get(i18nextInstance.getDataByLanguage(lang)?.translation, key);
}
