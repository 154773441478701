import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Loader } from "_common/loaders/Loader";
import { PAYMENT_PROVIDER, TPaymentMdl } from "admin/payments/_models/CrudPaymentMdl";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { ordersStore } from "orders/_stores/ordersStore";
import { cartStore } from "cart/_stores/cartStore";
import { toast } from "react-toastify";
import { TOrderListingMdl, TOrderMdl } from "orders/_models/OrderMdl";
import { appConfig } from "_configs/appConfig";
import { useTranslation } from "react-i18next";
import { affiliationsUtils } from "_common/_utils/affiliationsUtils";

type Props = {
    order: Partial<TOrderMdl> | TOrderListingMdl;
    sandbox: boolean;
    applicationId: string;
    locationId: string;
    onSuccess: (payment: TPaymentMdl) => void;
};

export function SquarePaymentForm(props: Props) {
    // const [payments, setPayments] = useState<{
    //     card: () => Promise<any>;
    //     verifyBuyer: (token: any, verificationDetails: any) => Promise<{ token: string }>;
    // }>();
    const [card, setCard] = useState();
    const [isPaying, setIsPaying] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const payments = (window as any).Square.payments(props.applicationId, props.locationId);
        payments.card()?.then((_card: any) => {
            setCard(_card);
            void _card.attach("#card-container");
        });
    }, []);

    // async function verifyUser() {
    //     const verificationDetails = {
    //         amount: cartStore.total,
    //         billingContact: {
    //             addressLines: [form.getValues("address.street")],
    //             familyName: form.getValues("lastName"),
    //             givenName: form.getValues("firstName"),
    //             email: form.getValues("email"),
    //             country: form.getValues("address.country"),
    //             phone: form.getValues("phone"),
    //             region: form.getValues("address.province"),
    //             city: form.getValues("address.city"),
    //         },
    //         currencyCode: "CAD",
    //         intent: "CHARGE",
    //     };
    //
    //     console.log(verificationDetails);
    //
    //     if (payments) {
    //         const verificationResults = await payments.verifyBuyer(token, verificationDetails);
    //         return verificationResults.token;
    //     }
    //     throw "Not verified";
    // }

    async function tokenize(card: any) {
        const tokenResult = await card.tokenize();
        if (tokenResult.status === "OK") {
            return tokenResult.token;
        } else throw new Error(`Tokenization errors: ${JSON.stringify(tokenResult.errors)}`);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {card ? <div id={"card-container"} /> : <Loader />}
            </Grid>
            {isPaying && (
                <Grid item xs={12}>
                    <Loader text={t("checkout.steps.isPaying")} />
                </Grid>
            )}
            <Grid xs={12} alignItems={"center"} className={"flex_center_center"}>
                {!!card && (
                    <UiButton
                        variant={"contained"}
                        color={"primary"}
                        id="card-button"
                        disabled={isPaying}
                        onClick={async () => {
                            try {
                                if (!isPaying) {
                                    setIsPaying(true);
                                    const token = await tokenize(card);
                                    // const verifiedToken = await verifyUser();
                                    const affiliate = affiliationsUtils.checkAffiliation();
                                    const payload = {
                                        locationId: appConfig.square.locationId,
                                        sourceId: token,
                                        order: props.order,
                                        giftCards: cartStore.giftCard,
                                        amount: cartStore.total,
                                        affiliateName: affiliate || undefined,
                                    };
                                    const { data: payment } = await ordersStore.checkout(
                                        PAYMENT_PROVIDER.SQUARE,
                                        payload,
                                    );
                                    if (payment) props.onSuccess(payment);
                                    else toast.error("A problem occurs please try again");
                                    setIsPaying(false);
                                }
                            } catch (e) {
                                console.error(e);
                                toast.error("A problem occurs please try again");
                                setIsPaying(false);
                            }
                        }}
                    >
                        Payer
                    </UiButton>
                )}
            </Grid>
        </Grid>
    );
}
