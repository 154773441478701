import React from "react";
import { productsStore } from "products/_stores/productsStore";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { URLS } from "_configs/URLS";
import { panelStore } from "main/menu/panel/_stores/panelStore";
import CartAddPanelContentProductCard from "cart/CartAddPanelContentProductCard";

type Props = {
    productId: string;
};

const CartProductCard = ({ productId }: Props) => {
    const product = productsStore.getSync(productId);
    if (!product) return null;
    return (
        <ExternalOrNavLink
            key={productId}
            url={URLS.product(product.localized.urlAlias)}
            onClick={() => panelStore.close()}
        >
            <CartAddPanelContentProductCard product={product} />
        </ExternalOrNavLink>
    );
};

export default CartProductCard;
