import React from "react";
import { PRODUCT_TYPE, TProductLocalizedMdl } from "products/_models/ProductMdl";
import styles from "./_css/productComparisonBlock.module.css";
import { useTranslation } from "react-i18next";
import { chargerSuffix, PRODUCT_OPTIONS_CONFIG } from "_configs/productConfig";
import i18next from "i18next";
import { isNull } from "lodash";
import { URLS } from "_configs/URLS";
import clsx from "clsx";
import ProductComparisonBlockImage from "products/comparison/ProductComparisonBlockImage";

const COMPARE_PRODUCT_OPTIONS_EXCLUDED: {
    ALL: string[];
    [PRODUCT_TYPE.EUC]?: string[];
    [PRODUCT_TYPE.SCOOTER]?: string[];
} = {
    ALL: [
        "accessoryType",
        "sppType",
        "foldedWidth",
        "foldedHeight",
        "foldedLength",
        "pedalWidth",
        "pedalHeight",
        "pedalLength",
        "color",
        "minRange",
        "suspensionType",
        "warranty",
        "voltage",
        "ampere",
    ],
    [PRODUCT_TYPE.EUC]: ["nbMotor"],
};

const formatOptionValue = (key: string, value: any) => {
    if (typeof value === "boolean") {
        return value ? i18next.t("words.yes") : i18next.t("words.no");
    }
    if (typeof value === "undefined" || isNull(value) || value.length === 0) {
        return "/";
    }
    if (key === "chargingTimeMinutes") {
        return value / 60;
    }
    if (key === "charger") {
        return value + chargerSuffix;
    }
    return value;
};

type Props = {
    product: TProductLocalizedMdl;
    baseProduct?: boolean;
    removeProduct?: (product: TProductLocalizedMdl) => void;
};

const ProductComparisonBlock = ({ product, baseProduct, removeProduct }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            {baseProduct ? (
                <div className={styles.imageContainer}>
                    <ProductComparisonBlockImage product={product} />
                </div>
            ) : (
                <a
                    href={URLS.product(product.localized.urlAlias)}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    className={clsx(styles.imageContainer, { [styles.containerWithLink]: !baseProduct })}
                >
                    <ProductComparisonBlockImage product={product} />
                </a>
            )}
            <div className={"flex_row justifyContent_spaceBetween alignItems_baseline"}>
                <h3>{product.localized.name}</h3>
                {removeProduct && (
                    <p className={styles.removeProduct} onClick={() => removeProduct(product)}>
                        {t("words.remove")}
                    </p>
                )}
            </div>
            <div className={styles.compareList}>
                {PRODUCT_OPTIONS_CONFIG.map((option) => {
                    if (
                        COMPARE_PRODUCT_OPTIONS_EXCLUDED.ALL.includes(option.key) ||
                        COMPARE_PRODUCT_OPTIONS_EXCLUDED[
                            product.type as keyof typeof COMPARE_PRODUCT_OPTIONS_EXCLUDED
                        ]?.includes(option.key)
                    ) {
                        return null;
                    }
                    const translationKey =
                        option.type === "enum" ? `product.options.enum.${option.key}` : `product.options.${option.key}`;
                    return (
                        <div key={option.key} className={styles.compareLine}>
                            <span className={"fontWeight_bold"}>{t(translationKey)}</span>
                            <p>{formatOptionValue(option.key, product.options[option.key])}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProductComparisonBlock;
